import { NetworkARObjectDict } from "../../ExperienceObjects/ExperienceObject";
import * as THREE from 'three';

interface Point2D {
    x: number;
    y: number;
}

export class IndoorDesignConfig {
    objects: IndoorDesignObject[];
    tiles: IndoorDesignTile[];
    settings: IndoorDesignSettings

    networkConfig: IndoorDesignNetworkConfig;

    constructor(
        networkConfig: IndoorDesignNetworkConfig,
        objects: IndoorDesignObject[],
        tiles: IndoorDesignTile[]
    ) {
        this.objects = objects
        this.tiles = tiles
        this.settings = networkConfig.settings
        this.networkConfig = networkConfig
    }
}

export class IndoorDesignNetworkConfig {
    objects: IndoorDesignNetworkObject[];
    tiles: IndoorDesignNetworkTile[];
    settings: IndoorDesignSettings

    constructor(data: Record<string, any>) {
        this.objects = data.objects.map((object: Record<string, any>) => {
            return new IndoorDesignNetworkObject(object);
        });

        this.tiles = data.tiles.map((tile: Record<string, any>) => {
            return new IndoorDesignNetworkTile(tile);
        });

        this.settings = new IndoorDesignSettings(data.settings)
    }

    // constructor(
    //     objects: NetworkARObjectDict[],
    //     objectImages: NetworkARObjectDict[],
    //     tileImage: NetworkARObjectDict,
    //     tileSize: Point2D,
    //     gridSize: Point2D
    // ) {
    //     this.objects = objects
    //     this.objectImages = objectImages
    //     this.tileImage = tileImage
    //     this.tileSize = tileSize
    //     this.gridSize = gridSize
    // }
}

export class IndoorDesignSettings {
    gridSize: Point2D

    constructor(data: Record<string, any>) {
        this.gridSize = {x: data.gridSize[0], y: data.gridSize[1]} as Point2D
    }
}

export class IndoorDesignTile {
    tileImage: THREE.Texture
    tileNetworkImage: NetworkARObjectDict
    tileSize: Point2D
    name: string
    description: string

    constructor(data: IndoorDesignNetworkTile, tileImage: THREE.Texture) {
        this.tileImage = tileImage;
        this.tileNetworkImage = data.tileImage
        this.tileSize = data.tileSize;
        this.name = data.name;
        this.description = data.description;
    }
}

export class IndoorDesignNetworkTile {
    tileImage: NetworkARObjectDict
    tileSize: Point2D
    name: string
    description: string

    constructor(data: Record<string, any>) {
        this.tileImage = data.tileImage;
        this.tileSize = {x: data.tileSize[0], y: data.tileSize[1]} as Point2D;
        this.name = data.name;
        this.description = data.description;
    }
}

export class IndoorDesignObject {
    object: THREE.Object3D
    image: NetworkARObjectDict
    name: string
    description: string

    constructor(
        object: THREE.Object3D,
        networkObject: IndoorDesignNetworkObject,
    ) {
        this.object = object;
        this.image = networkObject.image;
        this.name = networkObject.name;
        this.description = networkObject.description;
    }
}

export class IndoorDesignNetworkObject {
    object: NetworkARObjectDict
    image: NetworkARObjectDict
    name: string
    description: string

    constructor(data: Record<string, any>) {
        this.object = data.object;
        this.image = data.image;
        this.name = data.name;
        this.description = data.description;
    }
}