import axios from "axios";
import urlJoin from "url-join";

export const customApi = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const customAxios = async (
  baseUrl: string,
  endpoint: string,
  method: string = "get",
  data: any = null,
  headers = {}
) => {
  const url = urlJoin(baseUrl, endpoint);
  try {
    const response = await customApi({
      method: method,
      url,
      data: data,
      headers: {
        ...headers,
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error from axios", error.response);
    throw error.response;
  }
};
