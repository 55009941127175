import { FC, ReactNode } from "react";

export enum PageNames {
  introPage = "intro",
  welcomePage = "welcome",
  homePage = "home",
  gamePage = "game",
  postGamePage = "postGame",
  registrationPage = "registration",
  summaryPage = "summary",
  errorPage = "error",
}

export interface Pages {
  pageName: string;
  component: ReactNode | FC;
  params: any;
}

export enum DefaultPages {
  homePage = "homepage",
  introScreen = "introscreen",
}
