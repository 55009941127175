import * as THREE from 'three';

// Particle system resources:
// https://jsfiddle.net/9Lvrnpwc/
// https://github.com/stemkoski/stemkoski.github.com/blob/master/Three.js/js/ParticleEngineExamples.js
// http://127.0.0.1:5500/Three.js/index.html#particle-engine

export class Tween {
    times: number[] = [];
	values: any[] = [];

    constructor(timeArray: number[] = [], valueArray: any[] = []) {
        this.times = timeArray
        this.values = valueArray
    }

    lerp(t: number) {
        var i = 0;
        var n = this.times.length;
        while (i < n && t > this.times[i])  
            i++;
        if (i == 0) return this.values[0];
        if (i == n)	return this.values[n-1];
        var p = (t - this.times[i-1]) / (this.times[i] - this.times[i-1]);
        if (this.values[0] instanceof THREE.Vector3)
            return this.values[i-1].clone().lerp( this.values[i], p );
        else // its a float
            return this.values[i-1] + p * (this.values[i] - this.values[i-1]);
    }
}

export class Particle {
    position: THREE.Vector3     = new THREE.Vector3();
	velocity: THREE.Vector3     = new THREE.Vector3(); // units per second
	acceleration: THREE.Vector3 = new THREE.Vector3();

	angle: number             = 0;
	angleVelocity: number     = 0; // degrees per second
	angleAcceleration: number = 0; // degrees per second, per second
	
	size: number = 0.5;

	color: THREE.Color   = new THREE.Color();
	opacity: number = 1.0;
			
	age: number   = 0;
	alive: number = 0; // use float instead of boolean for shader purposes

    sizeTween: Tween = new Tween([], [])
    colorTween: Tween = new Tween([], [])
    opacityTween: Tween = new Tween([], [])
    
    update(dt: number) {
        this.position.add( this.velocity.clone().multiplyScalar(dt) );
        this.velocity.add( this.acceleration.clone().multiplyScalar(dt) );
        
        // convert from degrees to radians: 0.01745329251 = Math.PI/180
        this.angle         += this.angleVelocity     * 0.01745329251 * dt;
        this.angleVelocity += this.angleAcceleration * 0.01745329251 * dt;

        this.age += dt;
        
        // if the tween for a given attribute is nonempty,
        //  then use it to update the attribute's value

        if ( this.sizeTween.times.length > 0 )
            this.size = this.sizeTween.lerp( this.age );
                    
        if ( this.colorTween.times.length > 0 )
        {
            var colorHSL = this.colorTween.lerp( this.age );
            this.color = new THREE.Color().setHSL( colorHSL.x, colorHSL.y, colorHSL.z );
        }
        
        if ( this.opacityTween.times.length > 0 )
            this.opacity = this.opacityTween.lerp( this.age );
    }
}

export enum PType {
    CUBE = 1,
    SPHERE = 2
  }


export class ParticleEngine {

    isInitialized: Boolean = false
    /////////////////////////
	// PARTICLE PROPERTIES //
	/////////////////////////
	
	positionStyle = PType.CUBE;		
	positionBase   = new THREE.Vector3();
	// cube shape data
	positionSpread = new THREE.Vector3();
	// sphere shape data
	positionRadius = 0; // distance from base at which particles start
	
	velocityStyle = PType.CUBE;	
	// cube movement data
	velocityBase       = new THREE.Vector3();
	velocitySpread     = new THREE.Vector3(); 
	// sphere movement data
	//   direction vector calculated using initial position
	speedBase   = 0;
	speedSpread = 0;
	
	accelerationBase   = new THREE.Vector3();
	accelerationSpread = new THREE.Vector3();	
	
	angleBase               = 0;
	angleSpread             = 0;
	angleVelocityBase       = 0;
	angleVelocitySpread     = 0;
	angleAccelerationBase   = 0;
	angleAccelerationSpread = 0;
	
	sizeBase   = 0.0;
	sizeSpread = 0.0;
	sizeTween  = new Tween();
			
	// store colors in HSL format in a THREE.Vector3 object
	// http://en.wikipedia.org/wiki/HSL_and_HSV
	colorBase   = new THREE.Vector3(0.0, 1.0, 0.5); 
	colorSpread = new THREE.Vector3(0.0, 0.0, 0.0);
	colorTween  = new Tween();
	
	opacityBase   = 1.0;
	opacitySpread = 0.0;
	opacityTween  = new Tween();

	blendStyle = THREE.NormalBlending; // false;

	particleArray = [];
	particlesPerSecond = 100;
	particleDeathAge = 1.0;
	
	////////////////////////
	// EMITTER PROPERTIES //
	////////////////////////
	
	emitterAge      = 0.0;
	emitterAlive    = true;
	emitterDeathAge = 60; // time (seconds) at which to stop creating particles.
	
	// How many particles could be active at any time?
	particleCount = this.particlesPerSecond * Math.min( this.particleDeathAge, this.emitterDeathAge );

	//////////////
	// THREE.JS //
	//////////////
	
	particleGeometry = new THREE.BufferGeometry()
	particleTexture  = null;
	// particleMaterial = new THREE.ShaderMaterial( 
	// {
	// 	uniforms: 
	// 	{
	// 		texture:   { value: this.particleTexture },
	// 	},
	// 	vertexShader:   particleVertexShader,
	// 	fragmentShader: particleFragmentShader,
	// 	transparent: true, 
    //     alphaTest: 0.5,  // if having transparency issues, try including: alphaTest: 0.5, 
	// 	blending: THREE.NormalBlending, depthTest: true,
		
	// });
    particleMaterial = new THREE.PointsMaterial({ size: 0.1, color: 0xff0000 });
	particleMesh = new THREE.Points();

    setValues(parameters: any) {
        if ( parameters === undefined ) return;
        
        // clear any previous tweens that might exist
        this.sizeTween    = new Tween();
        this.colorTween   = new Tween();
        this.opacityTween = new Tween();
        
        for ( var key in parameters ) 
            this[ key ] = parameters[ key ];
        
        // // attach tweens to particles
        // Particle.prototype.sizeTween    = this.sizeTween;
        // Particle.prototype.colorTween   = this.colorTween;
        // Particle.prototype.opacityTween = this.opacityTween;	
        
        // calculate/set derived particle engine values
        this.particleArray = [];
        this.emitterAge      = 0.0;
        this.emitterAlive    = true;
        this.particleCount = this.particlesPerSecond * Math.min( this.particleDeathAge, this.emitterDeathAge );
        
        this.particleGeometry = new THREE.BufferGeometry();
        // this.particleMaterial = new THREE.ShaderMaterial( 
        // {
        //     uniforms: 
        //     {
        //         texture:   { value: this.particleTexture },
        //     },
        //     vertexShader:   particleVertexShader,
        //     fragmentShader: particleFragmentShader,
        //     transparent: true,  alphaTest: 0.5, // if having transparency issues, try including: alphaTest: 0.5, 
        //     blending: THREE.NormalBlending, depthTest: true
        // });
        this.particleMaterial = new THREE.PointsMaterial({ size: 0.1, color: 0xff0000 });
        this.particleMesh = new THREE.Points();
    }

    initialize()
    {
        console.log("this.particleCount", this.particleCount)
        console.log("this.particleArray", this.particleArray)
        // link particle data with geometry/material data
        const positions = []
        const colors = []
        for (var i = 0; i < this.particleCount; i++)
        {
            // remove duplicate code somehow, here and in update function below.
            this.particleArray[i] = this.createParticle();
            positions.push(this.particleArray[i].position)
            colors.push(Math.random(), Math.random(), Math.random());
            // this.particleGeometry.vertices[i] = this.particleArray[i].position;
            // this.particleMaterial.attributes.customVisible.value[i] = this.particleArray[i].alive;
            // this.particleMaterial.attributes.customColor.value[i]   = this.particleArray[i].color;
            // this.particleMaterial.attributes.customOpacity.value[i] = this.particleArray[i].opacity;
            // this.particleMaterial.attributes.customSize.value[i]    = this.particleArray[i].size;
            // this.particleMaterial.attributes.customAngle.value[i]   = this.particleArray[i].angle;
        }
        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        this.particleGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        // this.particleMaterial.blending = this.blendStyle;
        // if ( this.blendStyle != THREE.NormalBlending) 
        //     this.particleMaterial.depthTest = false;
        
        this.particleMesh = new THREE.Points( this.particleGeometry, this.particleMaterial );
        this.isInitialized = true
        // this.particleMesh.dynamic = true;
        // this.particleMesh.sortParticles = true;
        // scene.add( this.particleMesh );
        return this.particleMesh
    }

    randomValue(base: number, spread: number)
    {
        return base + spread * (Math.random() - 0.5);
    }

    randomVector3(base: THREE.Vector3, spread: THREE.Vector3)
    {
        var rand3 = new THREE.Vector3( Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5 );
        return new THREE.Vector3().addVectors( base, new THREE.Vector3().multiplyVectors( spread, rand3 ) );
    }   
    
    createParticle()
    {
        // console.log("CREATING PARTICLEEE", this.positionStyle, this.positionBase, this.positionSpread)
        var particle = new Particle();
    
        if (this.positionStyle == PType.CUBE)
        {
            particle.position = this.randomVector3( this.positionBase, this.positionSpread ); 
        }
        if (this.positionStyle == PType.SPHERE)
        {
            var z = 2 * Math.random() - 1;
            var t = 6.2832 * Math.random();
            var r = Math.sqrt( 1 - z*z );
            var vec3 = new THREE.Vector3( r * Math.cos(t), r * Math.sin(t), z );
            particle.position = new THREE.Vector3().addVectors( this.positionBase, vec3.multiplyScalar( this.positionRadius ) );
        }
            
        if ( this.velocityStyle == PType.CUBE )
        {
            particle.velocity = this.randomVector3( this.velocityBase, this.velocitySpread ); 
        }
        if ( this.velocityStyle == PType.SPHERE )
        {
            var direction = new THREE.Vector3().subVectors( particle.position, this.positionBase );
            var speed     = this.randomValue( this.speedBase, this.speedSpread );
            particle.velocity  = direction.normalize().multiplyScalar( speed );
        }
        
        particle.acceleration = this.randomVector3( this.accelerationBase, this.accelerationSpread ); 
    
        particle.angle             = this.randomValue( this.angleBase,             this.angleSpread );
        particle.angleVelocity     = this.randomValue( this.angleVelocityBase,     this.angleVelocitySpread );
        particle.angleAcceleration = this.randomValue( this.angleAccelerationBase, this.angleAccelerationSpread );
    
        particle.size = 0.05// this.randomValue( this.sizeBase, this.sizeSpread );
    
        var color = this.randomVector3( this.colorBase, this.colorSpread );
        particle.color = new THREE.Color().setHSL( color.x, color.y, color.z );
        
        particle.opacity = 1// this.randomValue( this.opacityBase, this.opacitySpread );
    
        particle.age   = 0;
        particle.alive = 0; // particles initialize as inactive
        
        return particle;
    }

    update(dt: number)
    {
        if (!this.isInitialized) return 

        var recycleIndices = [];
        
        // update particle data
        const colors = []
        for (var i = 0; i < this.particleCount; i++)
        {
            if ( this.particleArray[i].alive )
            {
                this.particleArray[i].update(dt);
    
                // check if particle should expire
                // could also use: death by size<0 or alpha<0.
                if ( this.particleArray[i].age > this.particleDeathAge ) 
                {
                    this.particleArray[i].alive = 0.0;
                    recycleIndices.push(i);
                }
                // update particle properties in shader
                // this.particleMaterial.attributes.customVisible.value[i] = this.particleArray[i].alive;
                // this.particleMaterial.attributes.customColor.value[i]   = this.particleArray[i].color;
                // this.particleMaterial.attributes.customOpacity.value[i] = this.particleArray[i].opacity;
                // this.particleMaterial.attributes.customSize.value[i]    = this.particleArray[i].size;
                // this.particleMaterial.attributes.customAngle.value[i]   = this.particleArray[i].angle;
                colors.push(Math.random(), Math.random(), Math.random());
            }		
        }
        this.particleGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    
        // check if particle emitter is still running
        if ( !this.emitterAlive ) return;
    
        // if no particles have died yet, then there are still particles to activate
        if ( this.emitterAge < this.particleDeathAge )
        {
            // determine indices of particles to activate
            var startIndex = Math.round( this.particlesPerSecond * (this.emitterAge +  0) );
            var   endIndex = Math.round( this.particlesPerSecond * (this.emitterAge + dt) );
            if  ( endIndex > this.particleCount ) 
                  endIndex = this.particleCount; 
                  
            for (var i = startIndex; i < endIndex; i++)
                this.particleArray[i].alive = 1.0;		
        }
    
        // if any particles have died while the emitter is still running, we imediately recycle them
        const positions = [];
        for (var j = 0; j < recycleIndices.length; j++)
        {
            var i: number = recycleIndices[j];
            this.particleArray[i] = this.createParticle();
            this.particleArray[i].alive = 1.0; // activate right away
            positions.push(this.particleArray[i].position);
        }
        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    
        // stop emitter?
        this.emitterAge += dt;
        if ( this.emitterAge > this.emitterDeathAge )  this.emitterAlive = false;
    }
}

const loadTexture = async (texturePath: string): Promise<THREE.Texture> => {
	const textureLoader = new THREE.TextureLoader();
  
	try {
	  // Use await to wait for the texture to be loaded
	  const texture = await textureLoader.loadAsync(texturePath);
	  
	  return texture;
	} catch (error) {
	  // Handle any errors during loading
	  console.error('Error loading texture:', error);
	  throw error; // Rethrow the error to propagate it
	}
};

// var particleVertexShader = 
// [
// "attribute vec3  customColor;",
// "attribute float customOpacity;",
// "attribute float customSize;",
// "attribute float customAngle;",
// "attribute float customVisible;",  // float used as boolean (0 = false, 1 = true)
// "varying vec4  vColor;",
// "varying float vAngle;",
// "void main()",
// "{",
// 	"if ( customVisible > 0.5 )", 				// true
// 		"vColor = vec4( customColor, customOpacity );", //     set color associated to vertex; use later in fragment shader.
// 	"else",							// false
// 		"vColor = vec4(0.0, 0.0, 0.0, 0.0);", 		//     make particle invisible.
		
// 	"vAngle = customAngle;",

// 	"vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
// 	"gl_PointSize = customSize * ( 300.0 / length( mvPosition.xyz ) );",     // scale particles as objects in 3D space
// 	"gl_Position = projectionMatrix * mvPosition;",
// "}"
// ].join("\n");

// var particleFragmentShader = [
//     "uniform sampler2D particleTexture;",
//     "varying vec4 vColor;", 
//     "varying float vAngle;",   
//     "void main()",
//     "{",
//         "gl_FragColor = vColor;",
        
//         "float c = cos(vAngle);",
//         "float s = sin(vAngle);",
//         "vec2 rotatedUV = vec2(c * (gl_PointCoord.x - 0.5) + s * (gl_PointCoord.y - 0.5) + 0.5,", 
//                             "c * (gl_PointCoord.y - 0.5) - s * (gl_PointCoord.x - 0.5) + 0.5);",  // rotate UV coordinates to rotate texture
//         "vec4 rotatedTexture = texture2D(particleTexture, rotatedUV);",
//         "gl_FragColor = gl_FragColor * rotatedTexture;",    // sets an otherwise white particle texture to desired color
//     "}"
// ].join("\n");

var particleVertexShader = [
    "attribute float alpha;",
    "varying float vAlpha;",
    "void main() {",
        "vAlpha = alpha;",
        "vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
        "gl_PointSize = 8.0;",
        "gl_Position = projectionMatrix * mvPosition;",
    "}"
].join("\n");

var particleFragmentShader = [
    "uniform vec3 color;",
    "varying float vAlpha;",
    "void main() {",
        "gl_FragColor = vec4( color, vAlpha );",
    "}"
].join("\n");

///////////////////////////////

export class CustomParticle {
    textureSource: string | null = null
    texture: THREE.Texture | null = null
    position: THREE.Vector3     = new THREE.Vector3();
	velocity: THREE.Vector3     = new THREE.Vector3(); // units per second
	acceleration: THREE.Vector3 = new THREE.Vector3();

	angle: number             = 0;
	angleVelocity: number     = 0; // degrees per second
	angleAcceleration: number = 0; // degrees per second, per second
	
	size: number = 0.01;

	color: THREE.Color   = new THREE.Color();
	opacity: number = 1.0;
			
	age: number   = 0;
	alive: number = 0; // use float instead of boolean for shader purposes

    sizeTween: Tween = new Tween([], [])
    colorTween: Tween = new Tween([], [])
    opacityTween: Tween = new Tween([], [])
    
    update(dt: number) {
        this.position.add( this.velocity.clone().multiplyScalar(dt) );
        this.velocity.add( this.acceleration.clone().multiplyScalar(dt) );
        
        // convert from degrees to radians: 0.01745329251 = Math.PI/180
        this.angle         += this.angleVelocity     * 0.01745329251 * dt;
        this.angleVelocity += this.angleAcceleration * 0.01745329251 * dt;

        this.age += dt;
        
        // if the tween for a given attribute is nonempty,
        //  then use it to update the attribute's value

        if ( this.sizeTween.times.length > 0 )
            this.size = this.sizeTween.lerp( this.age );
    
        if ( this.colorTween.times.length > 0 )
        {
            var colorHSL = this.colorTween.lerp( this.age );
            this.color = new THREE.Color().setHSL( colorHSL.x, colorHSL.y, colorHSL.z );
        }
        
        if ( this.opacityTween.times.length > 0 )
            this.opacity = this.opacityTween.lerp( this.age );
    }
}

export class ParticleSystemManager {
    positionBase   = new THREE.Vector3();
	positionSpread = new THREE.Vector3();
	
	velocityBase       = new THREE.Vector3();
	velocitySpread     = new THREE.Vector3(); 
	
	accelerationBase   = new THREE.Vector3();
	accelerationSpread = new THREE.Vector3();	
	
	angleBase               = 0;
	angleSpread             = 0;
	angleVelocityBase       = 0;
	angleVelocitySpread     = 0;
	angleAccelerationBase   = 0;
	angleAccelerationSpread = 0;
	
	sizeBase   = 0.0;
	sizeSpread = 0.0;
	sizeTween  = new Tween();
			
	// store colors in HSL format in a THREE.Vector3 object
	// http://en.wikipedia.org/wiki/HSL_and_HSV
    particleTexture: THREE.Texture | null = null
	colorBase   = new THREE.Vector3(0.0, 1.0, 0.5); 
	colorSpread = new THREE.Vector3(0.0, 0.0, 0.0);
	colorTween  = new Tween();
	
	opacityBase   = 1.0;
	opacitySpread = 0.0;
	opacityTween  = new Tween();

	particleArray = [];
	particlesPerSecond = 100;
	particleDeathAge = 1.0;
	
	////////////////////////
	// EMITTER PROPERTIES //
	////////////////////////
	
	emitterAge      = 0.0;
	emitterAlive    = true;
	emitterDeathAge = 60; // time (seconds) at which to stop creating particles.
	
	// How many particles could be active at any time?
	particleCount = this.particlesPerSecond * Math.min( this.particleDeathAge, this.emitterDeathAge );


    particleGeometry: THREE.BufferGeometry | null = null
    particleMaterial: THREE.PointsMaterial | null = null
    particles: THREE.Points | null = null

    isInitialized: Boolean = false

    constructor() {}

    setParameters(parameters: Record<string, any>) {
        // clear any previous tweens that might exist
        this.sizeTween    = new Tween();
        this.colorTween   = new Tween();
        this.opacityTween = new Tween();
        
        for ( var key in parameters ) {
            this[ key ] = parameters[ key ];
        }
    }

    tick(dt: number) {
        this.update(dt)
        // this.updateSmokeEffect()
    }

    createParticle()
    {
        var particle = new CustomParticle();
        particle.texture = this.particleTexture
        particle.position = this.randomVector3( this.positionBase, this.positionSpread ); 
        particle.velocity = this.randomVector3( this.velocityBase, this.velocitySpread ); 
        particle.acceleration = this.randomVector3( this.accelerationBase, this.accelerationSpread ); 
        particle.angle             = this.randomValue( this.angleBase,             this.angleSpread );
        particle.angleVelocity     = this.randomValue( this.angleVelocityBase,     this.angleVelocitySpread );
        particle.angleAcceleration = this.randomValue( this.angleAccelerationBase, this.angleAccelerationSpread );
        particle.size = this.randomValue( this.sizeBase, this.sizeSpread );
        particle.sizeTween = this.sizeTween
    
        var color = this.randomVector3( this.colorBase, this.colorSpread );
        particle.color = new THREE.Color().setHSL( color.x, color.y, color.z );
        particle.opacity = this.randomValue( this.opacityBase, this.opacitySpread );
    
        particle.age   = 0;
        particle.alive = 0; // particles initialize as inactive
        
        return particle;
    }

    async createParticlesSystem() {
        const particleTexture = await loadTexture('https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FExperiences%2Fbaselburgermenugame%2Fimages%2Fsmokeparticle.png?alt=media&token=220fd741-4e8e-4fec-93f3-91a79e7a7715');
        this.particleGeometry = new THREE.BufferGeometry();
        this.particleMaterial = new THREE.PointsMaterial({
            // color: 0xffffff,
            size: 0.03,
            map: particleTexture,  // Texture with transparency
            transparent: true,
            alphaTest: 0.1,  // Adjust as needed to control transparency threshold
            opacity: 0.2
        });

        this.particles = new THREE.Points(this.particleGeometry, this.particleMaterial);

        const positions = []
        const colors = []
        const sizes = []
        for (var i = 0; i < this.particleCount; i++)
        {
            this.particleArray[i] = this.createParticle();
            positions.push(this.particleArray[i].position.x, this.particleArray[i].position.y, this.particleArray[i].position.z, 0.1)
            colors.push(this.particleArray[i].color.x, this.particleArray[i].color.y, this.particleArray[i].color.z);
            sizes.push(this.particleArray[i].size)
        }
        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 4));
        // this.particleGeometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));
        // this.particleGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        this.isInitialized = true

        return this.particles
    }

    update(dt: number)
    {
        if (!this.isInitialized) return 

        var recycleIndices = [];
        
        // update particle data
        for (var i = 0; i < this.particleCount; i++)
        {
            if ( this.particleArray[i].alive == 1 )
            {
                this.particleArray[i].update(dt);
    
                // check if particle should expire
                // could also use: death by size<0 or alpha<0.
                if ( this.particleArray[i].age > this.particleDeathAge ) 
                {
                    this.particleArray[i].alive = 0.0;
                    recycleIndices.push(i);
                }
            }		
        }

        // check if particle emitter is still running
        if ( !this.emitterAlive ) return;
    
        // if no particles have died yet, then there are still particles to activate
        if ( this.emitterAge < this.particleDeathAge )
        {
            // determine indices of particles to activate
            var startIndex = Math.round( this.particlesPerSecond * (this.emitterAge +  0) );
            var   endIndex = Math.round( this.particlesPerSecond * (this.emitterAge + dt) );
            if  ( endIndex > this.particleCount ) 
                  endIndex = this.particleCount; 
                  
            for (var i = startIndex; i < endIndex; i++)
                this.particleArray[i].alive = 1.0;		
        }
    
        // if any particles have died while the emitter is still running, we imediately recycle them
        const positions = [];
        for (var i = 0; i < this.particleCount; i++)
        {
            this.particleArray[i].update(dt);
            positions.push(this.particleArray[i].position.x, this.particleArray[i].position.y, this.particleArray[i].position.z, 0);//this.particleArray[i].opacity)
        }
        for (var j = 0; j < recycleIndices.length; j++)
        {
            var i: number = recycleIndices[j];
            this.particleArray[i] = this.createParticle();
            this.particleArray[i].alive = 1.0; // activate right away
            // positions.push(this.particleArray[i].position);
            positions[i] = [this.particleArray[i].position.x, this.particleArray[i].position.y, this.particleArray[i].position.z, 0];//this.particleArray[i].opacity];
        }
        this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 4));
        this.particleGeometry.attributes.position.needsUpdate = true

        // stop emitter?
        this.emitterAge += dt;
        if ( this.emitterAge > this.emitterDeathAge ) {
            this.emitterAlive = false;
        }
    }

    // async createSmokeEffect(): Promise<THREE.Points> {
    //     const particleCount = 5; // Adjust the number of particles as desired
    //     const particleSize = 0.03; // Adjust the size of the particles
    //     const particleColor = 0xff0000; // Adjust the color of the particles

    //     const particleTexture = await loadTexture('https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FExperiences%2Fbaselburgermenugame%2Fimages%2Fsmokeparticle.png?alt=media&token=220fd741-4e8e-4fec-93f3-91a79e7a7715');

    //     // Create the particle system
    //     this.particleGeometry = new THREE.BufferGeometry();
    //     this.particleMaterial = new THREE.PointsMaterial({
    //         // size: particleSize,
    //         // color: 0xffffff,  // Set to fully transparent white
    //         map: particleTexture,  // Texture with transparency
    //         vertexColors: true,
    //         transparent: true,
    //         alphaTest: 0.3,  // Adjust as needed to control transparency threshold
    //     });
    //     this.particles = new THREE.Points(this.particleGeometry, this.particleMaterial);

    //     // Generate random particle positions and colors
    //     const positions = [];
    //     const colors = [];

    //     for (let i = 0; i < particleCount; i++) {
    //         const pos = this.randomVector3( new THREE.Vector3(0,0,0), new THREE.Vector3( 0.1, 0, 0.1 ) )
    //         positions.push(pos.x, pos.y, pos.z)

    //         const r = 1// Math.random();
    //         const g = 1 //Math.random();
    //         const b = 1 //Math.random();
    //         colors.push(r, g, b, Math.max(Math.random(), 0.2));
    //     }
    //     this.particleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    //     this.particleGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 4));

    //     return this.particles
    // }

    // updateSmokeEffect() {
    //     if (this.particles === null || this.particleGeometry === null || this.particleMaterial === null) {
    //         return 
    //     }

    //     const positions = this.particleGeometry.getAttribute('position').array;
    //     // for (let i = 0; i < positions.length; i += 3) {
    //     //     positions[i + 1] += Math.random() * 0.01; // Move particles upward
    //     //     if (positions[i + 1] > 5) {
    //     //     positions[i + 1] = -5; // Reset particles' Y position when they go beyond the screen
    //     //     }
    //     // }
    //     // this.particleGeometry.getAttribute('position').needsUpdate = true;
    // }

    randomValue(base: number, spread: number)
    {
        return base + spread * (Math.random() - 0.5);
    }

    randomVector3(base: THREE.Vector3, spread: THREE.Vector3)
    {
        var rand3 = new THREE.Vector3( Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5 );
        return new THREE.Vector3().addVectors( base, new THREE.Vector3().multiplyVectors( spread, rand3 ) );
    } 
}