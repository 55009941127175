import { createRoot } from 'react-dom/client';
// import { UIElementPropsKeys, ORBButtonUIKeys, ORBImageUIKeys, ORBStatsUIKeys, ORBLottieUIKeys } from '../TriggerAction/Config'
import { TriggerManager } from '../TriggerAction/TriggerManager';
import { createElement } from 'react';
import { CloseOutlined } from '@ant-design/icons'
// import { createRoot } from 'react-dom/client';
// import * as React from 'react';
// import * as ReactDOM from 'react-dom';

interface ISetStatsParams {
    status: boolean,
    initTime: number,
    callBackFun: () => void // used for time-end
    scoreChangeCallback: (newScore: number) => void
}

export class UIManager {
    triggerManager: TriggerManager
    UIElements: Record<string, any>;

    statsUI: ORBStatsUI | null = null
    totalTimerDelta: number = 0

    constructor(triggerManager: TriggerManager,
        private setStatsParams?: (param: ISetStatsParams) => void,
        private setScore?: (score: any) => any,
        private setTimeDelta?: (delta: any) => any,
        private showLottie?: (lottieName: string) => void) {

        this.triggerManager = triggerManager
        this.UIElements = {};

    }

    cleanup() {
        this.#removeAllElementsFromDocument()
    }

    #addElementToDocument(element: any, tag: string, elementType: UIElementType) {
        const container = document.querySelector("#overlay-container")
        if (container) {
            container.appendChild(element);
        }
        this.UIElements[tag] = element

        if (elementType == UIElementType.button) {
            this.addEventToUIElement(tag, "click", () => {
                // this.UIElementTapped(tag)
                this.UIElementInteraction(tag, elementType, UIElementInteractionType.click)
            })
            // this.addEventToUIElement(tag, "mousedown", () => {
            //     this.UIElementInteraction(tag, elementType, UIElementInteractionType.holdDown)
            // });

            this.addEventToUIElement(tag, "mouseup", () => {
                this.UIElementInteraction(tag, elementType, UIElementInteractionType.release)
            });
        }

        element.addEventListener("contextmenu", (event: any) => {
            event.preventDefault();
            this.UIElementInteraction(tag, elementType, UIElementInteractionType.holdDown)
        });
    }

    #removeElementFromDocument(tag: string) {
        delete this.UIElements[tag];
        const container = document.querySelector("#overlay-container")
        if (container) {
            const element = document.getElementById(tag);
            element?.remove();
        }
    }

    #removeAllElementsFromDocument() {
        // Remove all UI elements (not stats)
        for (const [tag, element] of Object.entries(this.UIElements)) {
            this.#removeElementFromDocument(tag)
        }

        // Remove StatsUI
        this.setStatsParams && this.setStatsParams({
            status: false,
            initTime: 0,
            callBackFun: () => undefined,
            scoreChangeCallback: () => undefined
        })
    }

    placeUIElement(elementProps: UIElementProps) {
        if (elementProps.type == UIElementType.stats) {
            this.placeStatsUI(elementProps as ORBStatsUI)
        } else if (elementProps.type == UIElementType.lottie) {
            const lottieUI = elementProps as ORBLottieUI
            if (lottieUI) {
                const lottieName = lottieUI.lottieUrl == "" ? null : lottieUI.lottieUrl
                this.placeLottie(lottieName)
            }
        } else {
            const element = elementProps.getElement()
            if (element) {
                this.#addElementToDocument(element, elementProps.tag, elementProps.type)
            }
        }
    }

    placeStatsUI(statsUI: ORBStatsUI | null) {
        this.statsUI = statsUI
        this.setStatsParams && this.setStatsParams({
            status: true,
            initTime: statsUI.maxTime,
            callBackFun: this.handleTimeEnd.bind(this),
            scoreChangeCallback: (score: number) => { this.handleScoreChange(statsUI.tag, score) }
        })
    }

    updateCounter(delta: number) {
        this.setScore && this.setScore((prev: any) => {
            return prev + delta
        })

    }

    updateTimer(delta: number) {
        this.setTimeDelta((prev: number) => {
            return prev - delta
        })
    }

    // This function so that it will be easier to use lottie from outside the uimanager
    placeLottie(lottieName: string | null) {
        this.showLottie?.(lottieName)
    }

    handleTimeEnd() {
        this.triggerManager.timeIsUp()
    };

    handleScoreChange(counterID: string, newScore: number) {
        this.triggerManager.scoreChange(counterID, newScore)
    }

    removeUIElement(tag: string) {
        this.#removeElementFromDocument(tag)
    }

    addEventToUIElement(tag: string, eventName: string, callback: () => void) {
        if (tag in this.UIElements) {
            this.UIElements[tag].addEventListener(eventName, callback)
        }
    }

    // UIElementTapped(tag: string) {
    //     this.triggerManager.UIElementTapped(tag)
    // }

    UIElementInteraction(tag: string, elementType: UIElementType, interactionType: UIElementInteractionType) {
        this.triggerManager.UIElementInteraction(tag, elementType, interactionType)
    }

    hideUIElement(tag: string) {
        if (tag in this.UIElements) {
            this.UIElements[tag].style.display = "none";
        }
    }

    unhideUIElement(tag: string) {
        if (tag in this.UIElements) {
            this.UIElements[tag].style.display = "inline";
        }
    }

    placeScopeImage() {
        const container = document.querySelector("#overlay-container");
        if (container) {
            const elementDiv = document.createElement('div');
            elementDiv.style.position = 'absolute'; // or 'fixed' based on your needs
            elementDiv.style.width = '50px';
            elementDiv.style.height = '50px';
            elementDiv.style.top = `${(window.screen.height - 50) / 2}px`; // adjust as needed
            elementDiv.style.left = `${(window.screen.width - 50) / 2}px`;
            const root = createRoot(elementDiv);
            root.render(
                createElement(CloseOutlined)
            );
            container.appendChild(elementDiv);
        }
    }

    removeScoreImage() {

    }
}

export enum UIElementInteractionType {
    click = "click",
    holdDown = "holdDown",
    release = "release"
}

export enum UIElementType {
    button = "button",
    image = "image",
    stats = "stats",
    lottie = "lottie",
    joystick = "joystick",
    undefined = "undefined"
}

export enum JoystickTransformation {
    linear = "linear",
    exponential = "exponential",
    undefined = "undefined"
}

export interface IUIElementPropsDict {
    tag: string;
    locX: number;
    locY: number;
    height: number;
    width: number;
    type: string;
}

export class UIElementProps {
    element: any | null;
    tag: string;
    locX: number;
    locY: number;
    height: number;
    width: number;
    type: UIElementType = UIElementType.undefined

    constructor(UIElementPropsDict: IUIElementPropsDict) {
        const screenHeight = window.screen.height
        const screenWidth = window.screen.width

        var tag = UIElementPropsDict.tag
        var locX = UIElementPropsDict.locX
        var locY = UIElementPropsDict.locY
        var height = UIElementPropsDict.height
        var width = UIElementPropsDict.width
        if (width <= 1) {
            width = width * screenWidth
        }
        if (height <= 1) {
            height = height * screenHeight
        }
        locX = screenWidth * locX - width / 2
        locX = Math.max(locX, 0)
        locX = Math.min(locX, screenWidth - width)

        locY = screenHeight * locY - height / 2
        locY = Math.max(locY, 0)
        locY = Math.min(locY, screenHeight - height)

        this.tag = tag;
        this.locX = locX;
        this.locY = locY;
        this.height = height;
        this.width = width;
        this.element = null;
    }

    assignBasePropertiesToUIElement(element: any) {
        element.style.position = 'absolute';
        element.style.left = `${this.locX}px`;
        element.style.top = `${this.locY}px`;
        element.style.width = `${this.width}px`;
        element.style.height = `${this.height}px`;
        element.id = this.tag;
        element.zIndex = 1;
    }

    loadData(callback: (success: boolean) => void) {
        throw new Error("Subclasses must implement loadData method");
    }

    getElement() {
        return this.element;
    }
}

export interface IORBButtonUI extends IUIElementPropsDict {
    imageUrl: string;
}

export class ORBButtonUI extends UIElementProps {
    imageUrl: string | null = null;

    constructor(buttonPropsDict: IORBButtonUI) {
        super(buttonPropsDict)
        if (buttonPropsDict.imageUrl) {
            this.imageUrl = buttonPropsDict.imageUrl;
        }
        this.type = UIElementType.button
    }

    loadData(callback: (success: boolean) => void) {
        if (this.imageUrl) {
            var clickableImgElement = document.createElement('img');
            var loadTime = performance.now()
            clickableImgElement.src = this.imageUrl;
            clickableImgElement.onload = () => {
                loadTime = (performance.now() - loadTime) / 1000
                callback(true);
            }
            clickableImgElement.onerror = () => {
                callback(false);
            }
            this.assignBasePropertiesToUIElement(clickableImgElement)
            this.element = clickableImgElement
        } else {
            var buttonElement = document.createElement('button');
            this.assignBasePropertiesToUIElement(buttonElement)
            this.element = buttonElement
            callback(true);
        }
    }
}

export interface IORBImageUI extends IUIElementPropsDict {
    imageUrl: string;
}

export class ORBImageUI extends UIElementProps {
    imageUrl: string | null = null;

    constructor(imagePropsDict: IORBImageUI) {
        super(imagePropsDict)
        this.imageUrl = imagePropsDict.imageUrl;
        this.type = UIElementType.image
    }

    loadData(callback: (success: boolean) => void) {
        var imgElement = document.createElement('img');
        this.assignBasePropertiesToUIElement(imgElement)
        if (this.imageUrl) {
            var loadTime = performance.now()
            imgElement.src = this.imageUrl;
            imgElement.onload = () => {
                loadTime = (performance.now() - loadTime) / 1000
                callback(true);
            }
            imgElement.onerror = () => {
                callback(false);
            }
        }
        this.element = imgElement
    }
}

export interface IORBStatsUI extends IUIElementPropsDict {
    useTimer: boolean;
    useScore: boolean;
    useLevels: boolean;
    maxTime: number;
    maxScore: number;
    imageUrl: string;
}

export class ORBStatsUI extends UIElementProps {
    imageUrl: string | null = null;
    useTimer: boolean
    useScore: boolean
    useLevels: boolean
    maxTime: number
    maxScore: number

    constructor(statsPropsDict: IORBStatsUI) {
        super(statsPropsDict)

        this.useTimer = statsPropsDict.useTimer;
        this.useScore = statsPropsDict.useScore;
        this.useLevels = statsPropsDict.useLevels;
        this.maxTime = statsPropsDict.maxTime;
        this.maxScore = statsPropsDict.maxScore;
        this.imageUrl = statsPropsDict.imageUrl;
        this.type = UIElementType.stats
    }

    loadData(callback: (success: boolean) => void) {
        this.element = null;
        callback(true)
    }
}

export interface IORBLottieUI extends IUIElementPropsDict {
    lottieUrl: string;
}

export class ORBLottieUI extends UIElementProps {
    lottieUrl: string | null = null;

    constructor(lottiePropsDict: IORBLottieUI) {
        super(lottiePropsDict)
        this.lottieUrl = lottiePropsDict.lottieUrl;
        this.type = UIElementType.lottie
    }

    loadData(callback: (success: boolean) => void) {
        callback(true)
        // if (this.lottieUrl) {
        //     const response = await fetch(this.lottieUrl);
        //     const lottieData = await response.json();
        //     const options = {
        //         loop: true,
        //         autoplay: true,
        //         animationData: lottieData,
        //         rendererSettings: {
        //             preserveAspectRatio: 'xMidYMid slice'
        //         }
        //     };
        //     const lottieElement = React.createElement(Lottie, options);
        //     this.element = lottieElement
        //     callback(true)
        // }
    }
}

// export interface IJoystickUI extends IUIElementPropsDict {
//     minValue: number;
//     maxValue: number;
//     minImageUrl?: string;
//     maxImageUrl?: string;
//     joystickTransformation: string
// }

// export class ORBJoystickUI extends UIElementProps {
//     minValue: number;
//     maxValue: number;
//     minImageUrl?: string;
//     maxImageUrl?: string;
//     joystickTransformation: JoystickTransformation;

//     constructor(propsDict: IJoystickUI) {
//         super(propsDict)

//         this.minValue = propsDict.minValue
//         this.maxValue = propsDict.maxValue
//         this.minImageUrl = propsDict.minImageUrl
//         this.maxImageUrl = propsDict.maxImageUrl
//         this.joystickTransformation = propsDict.joystickTransformation == "exponential" ? JoystickTransformation.exponential : JoystickTransformation.linear
//         this.type = UIElementType.joystick
//     }

//     loadData(callback: (success: boolean) => void) {
//         if (this.minImageUrl && this.maxImageUrl) {
//             var clickableImgElement = document.createElement('img');
//             var loadTime = performance.now()
//             clickableImgElement.src = this.imageUrl;
//             clickableImgElement.onload = () => {
//                 loadTime = (performance.now() - loadTime) / 1000
//                 callback(true);
//             }
//             clickableImgElement.onerror = () => {
//                 callback(false);
//             }
//             this.assignBasePropertiesToUIElement(clickableImgElement)
//             this.element = clickableImgElement
//         } else {

//             var buttonElement = document.createElement('button');
//             this.assignBasePropertiesToUIElement(buttonElement)
//             this.element = buttonElement
//             callback(true);
//         }
//     }
// }