export const BaselBurgerDemoExperience = `
{
   "metadata":{
      "experienceID": "baselburgershootersgame",
      "experienceType": "BaselBurgerShooters"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf",
      "primaryColor":[
         100,
         200,
         35
      ],
      "secondaryColor":[
         0,
         0,
         0
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"welcome",
         "pageParamsName":"welcomeScreenInput"
      },
      {
         "pageName":"game",
         "pageParamsName":"experience"
      },
      {
         "pageName":"postGame"
      }
   ],
   "welcomeScreenInput":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf",
      "title":"Basel Burger",
      "description":"Welcome to Basel Burger's burger shooting game! play for a chance to win a free meal!",
      "notes":[
         
      ]
   },
   "experience":{
      "input":{
         "shootersListViewTheme":{
            "backgroundImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBaselBurger%2FBaselBurgerLogo.png?alt=media&token=7e75af27-daff-41a7-b080-8fef5d298117",
            "logo":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBaselBurger%2FBaselBurgerLogo.png?alt=media&token=7e75af27-daff-41a7-b080-8fef5d298117",
            "title":"What's your favorite meal?"
         },
         "shootersItems":[
            {
               "objectImage":{
                  "fileName":"1-ClassicBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F1-ClassicBurger.png?alt=media&token=7a32806a-78ea-46ca-a521-ae85e1c4cfd8"
               },
               "objectName":"Classic Burger"
            },
            {
               "objectImage":{
                  "fileName":"2-CrispyBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F2-CrispyBurger.png?alt=media&token=a0ab99b7-15b6-44f6-8221-83e4f660c85c"
               },
               "objectName":"Crispy Burger"
            },
            {
               "objectImage":{
                  "fileName":"3-BigBoyBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F3-BigBoyBurger.png?alt=media&token=7eb98e56-5db1-4b63-b8cc-4b82487dae6e"
               },
               "objectName":"Big Boy Burger"
            },
            {
               "objectImage":{
                  "fileName":"4-CrispySalad.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F4-CrispySalad.png?alt=media&token=6019c347-f8e9-44b2-9426-b2e6a2925f46"
               },
               "objectName":"Crispy Salad"
            },
            {
               "objectImage":{
                  "fileName":"5-KBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F5-KBurger.png?alt=media&token=2676cd1a-40b9-4c0d-b04d-93039552a535"
               },
               "objectName":"K Burger"
            },
            {
               "objectImage":{
                  "fileName":"6-KidsMeal.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F6-KidsMeal.png?alt=media&token=90d9f477-ff21-4c85-9213-12d4c640d63f"
               },
               "objectName":"Kids Meal"
            },
            {
               "objectImage":{
                  "fileName":"7-MexicanBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F7-MexicanBurger.png?alt=media&token=1f60c1a0-c291-46fe-abc2-5d1821729807"
               },
               "objectName":"Mexican Burger"
            },
            {
               "objectImage":{
                  "fileName":"8-Popcorn.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F8-Popcorn.png?alt=media&token=401ab3e9-ba17-4754-ab7d-fd8bc77acef8"
               },
               "objectName":"Chicken Popcorn"
            },
            {
               "objectImage":{
                  "fileName":"9-TangoSalad.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F9-TangoSalad.png?alt=media&token=9bc718c8-e2da-4fad-b153-c09307929faa"
               },
               "objectName":"Tango Salad"
            },
            {
               "objectImage":{
                  "fileName":"10-FetaSalad.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F10-FetaSalad.png?alt=media&token=b0689042-5728-4572-9973-3d9dc11c18de"
               },
               "objectName":"Feta Salad"
            },
            {
               "objectImage":{
                  "fileName":"11-TexasBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F11-TexasBurger.png?alt=media&token=9d6c7d81-ae09-4c12-829a-f9b7d1656e13"
               },
               "objectName":"Texas Burger"
            },
            {
               "objectImage":{
                  "fileName":"12-Tokito.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F12-Tokito.png?alt=media&token=a1f0181d-5351-453e-be9a-f47fe18a97ad"
               },
               "objectName":"Tokito"
            }
         ]
      },
      "auxData":{
         "backgroundExperienceJSON":{
            "objects":[
               {
                  "id":"SelectedObject",
                  "name":"Selected Object",
                  "object":{
                     "fileName":"ORBAqua.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
                     "settings": {
                        "allowUserGestures": true,
                        "size": 0.08,
                        "scaleAxis": 0,
                        "addPhysicsBody": true,
                        "physicsShapeType": 2,
                        "isAffectedByGravity": false
                    }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onRandDelta",
                           "minDelta":1,
                           "maxDelta":1
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"infront"
                           },
                           {
                              "action":"moveRandom",
                              "duration": 20,
                              "durationPerMove": 2,
                              "speedFactor": 1
                           },
                           {
                              "action":"kill",
                              "delay":5
                           }
                        ]
                     },
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           },
                           {
                              "action":"updateCounter",
                              "counterID":"stats123",
                              "delta":1
                           },
                           {
                              "action":"playSound",
                              "soundtrackName":"BubblePop.mp3",
                              "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                           },
                           {
                              "action":"vibrate"
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"BulletObject",
                  "name":"bulletObject",
                  "object":{
                     "fileName":"ORBAqua.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onButtonTap",
                           "id":"button123"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"userPos"
                           },
                           {
                              "action":"shoot"
                           },
                           {
                              "action":"kill",
                              "delay":5
                           }
                        ]
                     },
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id2":"Selected Object"
                        },
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           }
                        ]
                     }
                  ]
               }
            ],
            "generalRules":[
               {
                  "activity":{
                     "trigger":{
                        "trigger":"onTimeEnd"
                     },
                     "actions":[
                        {
                           "action":"experienceFailed"
                        }
                     ]
                  }
               },
               {
                  "activity":{
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingLost"
                     },
                     "actions":[
                        {
                           "action":"pausePublishing"
                        }
                     ]
                  }
               },
               {
                  "activity":{
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingEstablished"
                     },
                     "actions":[
                        {
                           "action":"resumePublishing"
                        }
                     ]
                  }
               }
            ],
            "UIObjects":[
               {
                  "tag":"button123",
                  "type":"button",
                  "locX":0.5,
                  "locY":0.9,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf"
               },
               {
                  "tag":"stats123",
                  "type":"stats",
                  "locX":0.5,
                  "locY":0.1,
                  "height":100,
                  "width":100,
                  "useTimer":true,
                  "useScore":true,
                  "useLevels":false,
                  "maxTime":30,
                  "maxScore":100
               },
               {
                  "tag":"image1",
                  "type":"image",
                  "locX":0.5,
                  "locY":0.5,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerShootingButton.png?alt=media&token=374f0585-c543-43af-a692-6b7da84ff3c0"
               }
            ]
         }
      }
   }
}
`