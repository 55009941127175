import { ARObjectSettings } from "../../ExperienceObjects/ExperienceObject";
import { pubsub } from "../../TriggerAction/TriggerManager";
import { Trigger, TriggerType } from "../../TriggerAction/Triggers";
import { createFadeInAndMoveUpAnimation, createSpinAnimation, loadTexture } from "../../utils";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import { ITemplatesBasics } from "../TemplatesInterface";
import { IPhotoAlbumConfig, PhotoAlbumConfig, PhotoAlbumImage, PhotoAlbumType } from "./PhotoAlbumInput";
import * as THREE from 'three';

interface IPhotoAlbumTemplate extends ITemplatesBasics { }

export class PhotoAlbum
    extends PlatformManager
    implements IPhotoAlbumTemplate {

    config: PhotoAlbumConfig;

    arObjects: THREE.Mesh[] = []
    currentObjectIndex: number = -1
    currentObjectOrigHeight?: number
    elevationDelta: number = 1
    minOpacity: number = 0.3

    intervalId: any = null
    ///////////// Parse input json //////////////////
    static async parseInput(
        json: Record<string, any>
    ): Promise<PhotoAlbumConfig> {
        const networkConfig: IPhotoAlbumConfig = json as IPhotoAlbumConfig;
        const images: PhotoAlbumImage[] = [];

        for (const networkItem of networkConfig.images) {
            const textureImage = await loadTexture(networkItem.image.fileUrl);
            images.push(new PhotoAlbumImage(networkItem, textureImage))
        }

        const config = new PhotoAlbumConfig(images, networkConfig);
        return config;
    }

    startExperience(): void {
        super.startExperience();

        this.setup()
    }

    cleanup() {
        super.cleanup()
        clearInterval(this.intervalId)
    }

    setup() {
        this.placeAlbum()
        this.addSubscribers()
    }

    placeAlbum(delta: number = 1) {
        switch (this.config.photoAlbumType) {
            case PhotoAlbumType.Spherical:
                this.placeSphericalAlbum()
                break;
            case PhotoAlbumType.Tour:
                this.placeTourAlbum()
                break;
        }
    }

    placeSphericalAlbum() {
        const imagesRing = this.createCircularStripWithStitchedImage(this.config.images)
        this.ARManager?.addARObjectToScene(imagesRing)
        createFadeInAndMoveUpAnimation(imagesRing, 0.5, 3)
        createSpinAnimation(imagesRing, 0.003)
    }

    placeTourAlbum() {
        const objects = this.imagesToObjects(this.config.images)
        this.arObjects = objects

        if (objects.length > 0) {
            const firstObject = objects[0]
            this.placeObjectInfrontOfTheUser(firstObject)
            this.currentObjectIndex = 0
        }
    }

    imagesToObjects(imageObjects: PhotoAlbumImage[]): THREE.Mesh[] {
        const objects: THREE.Mesh[] = []

        for (const imageObject of imageObjects) {
            const object = this.imageToObject(imageObject)
            objects.push(object)
        }

        return objects
    }

    imageToObject(imageObject: PhotoAlbumImage): THREE.Mesh {
        const texture = imageObject.texture
        const aspectRatio = texture.image.width / texture.image.height;
        const width = aspectRatio * imageObject.height

        const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide, // Make the plane double-sided
            transparent: true, // Enable transparency
        });
        const planeGeometry = new THREE.PlaneGeometry(
            width,
            imageObject.height
        );
        const planeMesh = new THREE.Mesh(planeGeometry, material);
        const settings = new ARObjectSettings()
        settings.allowUserGestures = true
        planeMesh.userData.settings = settings

        return planeMesh
    }

    placeObjectInfrontOfTheUser(object: THREE.Mesh) {
        const userPosition = this.ARManager.getUserPosition()
        const userDirection = this.ARManager.getUserDirectionVector()

        const forwardDirection = userDirection
        forwardDirection.y = userPosition.y
        const nextPosition = userPosition.add(forwardDirection.normalize().multiplyScalar(2))

        if (this.currentObjectOrigHeight) {
            nextPosition.y = this.currentObjectOrigHeight
        } else {
            this.currentObjectOrigHeight = nextPosition.y
        }

        object.position.set(nextPosition.x, nextPosition.y, nextPosition.z)
        object.position.y -= this.elevationDelta
        const material = object.material as THREE.MeshBasicMaterial
        material.opacity = this.minOpacity

        this.ARManager.addARObjectToScene(object)
    }

    onEveryFrame() {
        if (this.currentObjectIndex < 0 || this.currentObjectIndex >= this.arObjects.length) return

        const userPosition = this.ARManager.getUserPosition()
        const currentObject = this.arObjects[this.currentObjectIndex]
        const material = currentObject.material as THREE.MeshBasicMaterial
        const distance = userPosition.distanceTo(currentObject.position)

        let maxDistance: number = 3
        let minDistance: number = this.config.images[this.currentObjectIndex].height

        if (distance > maxDistance) {

        } else if (distance > minDistance) {
            const constant = Math.max(1 - Math.max((distance - minDistance) / (maxDistance - minDistance), 0), 0)
            const newY = (this.currentObjectOrigHeight ?? userPosition.y) + ((constant - 1) * this.elevationDelta)
            material.opacity = Math.max(constant, this.minOpacity)
            currentObject.position.y = newY
        } else if (distance < minDistance) {
            material.opacity = 1
            this.currentObjectIndex += 1
            const nextObject = this.arObjects[this.currentObjectIndex]
            this.placeObjectInfrontOfTheUser(nextObject)
        }
    }

    stitchTexturesHorizontally(textures: THREE.Texture[]): THREE.Texture {
        if (textures.length === 0) {
            throw new Error("No textures provided.");
        }

        // Create a canvas and get the context
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) throw new Error("Failed to get canvas context.");

        // Calculate maximum height and total width after resizing
        const maxHeight = Math.max(...textures.map(texture => texture.image.height));
        const totalWidth = textures.reduce((acc, texture) => {
            const aspectRatio = texture.image.width / texture.image.height;
            const newWidth = aspectRatio * maxHeight; // Calculate new width based on aspect ratio
            return acc + newWidth; // Accumulate new widths
        }, 0);

        // Set canvas size
        canvas.width = totalWidth;
        canvas.height = maxHeight;

        // Draw each texture onto the canvas with new dimensions
        let x = 0;
        textures.forEach(texture => {
            const aspectRatio = texture.image.width / texture.image.height;
            const newWidth = aspectRatio * maxHeight; // Calculate new width based on aspect ratio

            // Save the current context state
            context.save();

            // Flip the context vertically
            context.translate(x + newWidth / 2, maxHeight / 2); // Move origin to the center of the image
            context.scale(-1, 1); // Flip the context vertically
            context.translate(-(x + newWidth / 2), -(maxHeight / 2)); // Move origin back to the top left corner

            // Draw the flipped image
            context.drawImage(texture.image, x, 0, newWidth, maxHeight); // Draw with new dimensions

            // Restore the context to its original state
            context.restore();

            x += newWidth; // Update x position for the next image
        });

        // Create a new texture from the canvas
        const stitchedTexture = new THREE.Texture(canvas);
        stitchedTexture.needsUpdate = true; // Mark the texture for update

        return stitchedTexture;
    }


    createCircularStripWithStitchedImage(imageObjects: PhotoAlbumImage[]): THREE.Mesh | null {
        if (imageObjects.length === 0) return null;

        const ringHeight: number = Math.max(...imageObjects.map(imageObject => imageObject.height));
        const textures: THREE.Texture[] = imageObjects.map(imageObject => imageObject.texture)

        // Create a texture from stitched images
        const stitchedTexture = this.stitchTexturesHorizontally(textures);
        stitchedTexture.needsUpdate = true; // Mark the texture for update

        const aspectRatio = stitchedTexture.image.width / stitchedTexture.image.height;
        const ringCircumference = ringHeight * aspectRatio
        const ringRadius = ringCircumference / (2 * Math.PI)

        // Create a cylinder geometry with open ends
        const cylinderGeometry = new THREE.CylinderGeometry(ringRadius, ringRadius, ringHeight, 32, 1, true);

        // Create a material for the cylinder and apply the stitched image
        const cylinderMaterial = new THREE.MeshBasicMaterial({
            map: stitchedTexture,
            side: THREE.DoubleSide, // Make it visible from both sides
        });

        // Create the mesh for the cylinder
        const cylinderMesh = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
        const settings = new ARObjectSettings()
        settings.allowUserGestures = true
        cylinderMesh.userData.settings = settings

        return cylinderMesh;
    }

    addSubscribers() {
        this.subscribeToARFrames()
    }

    subscribeToARFrames() {
        this.intervalId = setInterval(this.onEveryFrame.bind(this), 50);

        // const buttonTapTriggerDict = { "trigger": TriggerType.onUpdateARFrame }
        // const buttonTapTrigger = new Trigger(buttonTapTriggerDict)

        // pubsub.subscribe(buttonTapTrigger.description, buttonTapTrigger.description, this.onEveryFrame.bind(this))
    }
}