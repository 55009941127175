import * as THREE from 'three';
import { ARObjectSettings } from '../../ExperienceObjects/ExperienceObject';

export class GridIdx {
    row: number;
    col: number;

    constructor(row: number, col: number) {
        this.row = row;
        this.col = col;
    }

    toString(): string {
        return `Tile_${this.row}_${this.col}`;
    }

    static fromString(string: string): GridIdx | null {
        const components = string.split('_');
        if (components.length === 3 && components[0] === "Tile") {
            const row = parseInt(components[1]);
            const col = parseInt(components[2]);
            if (!isNaN(row) && !isNaN(col)) {
                return new GridIdx(row, col);
            }
        }
        return null;
    }
}

export class TilesGrid {
    rows: number;
    columns: number;
    cellWidth: number;
    cellHeight: number;

    private centerX: number;
    private centerZ: number;

    private tileImage: THREE.Texture
    private tiles: Map<string, THREE.Mesh> = new Map();
    private tilesOn: GridIdx[] = [];
    private dividers: Map<GridIdx, THREE.Object3D> = new Map();

    private tilesParent: THREE.Object3D;
    private dividersParent: THREE.Object3D;
    private parentNode: THREE.Object3D;

    private lastHighlightedTileName: string | null = null;

    constructor(cellWidth: number, cellHeight: number, rows: number, columns: number, transform: THREE.Matrix4, tileImage: THREE.Texture) {
        this.cellWidth = cellWidth;
        this.cellHeight = cellHeight;
        this.rows = rows;
        this.columns = columns;
        this.tileImage = tileImage;

        this.centerX = (this.columns * this.cellWidth) / 2.0 - this.cellWidth / 2;
        this.centerZ = (this.rows * this.cellHeight) / 2.0 - this.cellHeight / 2;

        this.buildParentNodes()
        this.createGridNodes();
        this.centerGridAtTransform(transform);
    }

    private buildParentNodes() {
        this.tilesParent = new THREE.Object3D();
        this.dividersParent = new THREE.Object3D();
        this.parentNode = new THREE.Object3D();
        this.tilesParent.userData.settings = new ARObjectSettings()
        this.dividersParent.userData.settings = new ARObjectSettings()
        this.parentNode.userData.settings = new ARObjectSettings()
        // this.parentNode.userData.settings = new ARObjectSettings({ allowUserGestures: true })

        this.parentNode.add(this.tilesParent);
        this.parentNode.add(this.dividersParent);
    }

    private centerGridAtTransform(transform: THREE.Matrix4) {
        this.parentNode.applyMatrix4(transform);
    }

    getParentNode(): THREE.Object3D {
        return this.parentNode;
    }

    getTilesParentNode(): THREE.Object3D {
        return this.tilesParent;
    }

    rotateAroundCenter(degrees: number) {
        const radians = THREE.MathUtils.degToRad(degrees);
        this.parentNode.rotateY(radians);
    }

    private createGridNodes() {
        for (let row = 0; row < this.rows; row++) {
            for (let col = 0; col < this.columns; col++) {
                const gridIdx = new GridIdx(row, col);
                const tile = this.createGridTile(gridIdx);
                this.addGridTile(tile, gridIdx);
            }
        }

        const midGridTileName = new GridIdx(Math.floor(this.rows / 2), Math.floor(this.columns / 2)).toString()
        this.placeTileImage(midGridTileName);
    }

    private createGridTile(gridIdx: GridIdx): THREE.Mesh {
        const x = gridIdx.col * this.cellWidth - this.centerX;
        const z = gridIdx.row * this.cellHeight - this.centerZ;
        const position = new THREE.Vector3(x, 0, z);

        const tile = this.createTileMesh()
        tile.name = gridIdx.toString();
        tile.position.copy(position);
        tile.rotation.x = -Math.PI / 2;

        return tile
    }

    private createTileMesh(): THREE.Mesh {
        const geometry = new THREE.PlaneGeometry(this.cellWidth - 0.005, this.cellHeight - 0.005);
        const tile = new THREE.Mesh(geometry);

        return tile
    }

    private addGridTile(tile: THREE.Mesh, gridIdx: GridIdx) {
        this.tiles.set(gridIdx.toString(), tile);
        this.unhighlightTile(gridIdx.toString());
        this.tilesParent.add(tile);
    }

    private doesTileExist(tileName: string): Boolean {
        return this.tiles.has(tileName);
    }

    private getTile(tileName: string): THREE.Mesh | null {
        return this.tiles.get(tileName) || null;
    }

    // Extend the grid with a new 3x3 grid around the given tileName
    private extendGridAroundTile(tileName: string): void {
        const gridIdx = GridIdx.fromString(tileName);
        if (!gridIdx) return;

        for (let row = gridIdx.row - 1; row <= gridIdx.row + 1; row++) {
            for (let col = gridIdx.col - 1; col <= gridIdx.col + 1; col++) {
                const newGridIdx = new GridIdx(row, col);
                if (!this.doesTileExist(newGridIdx.toString())) {
                    const tile = this.createGridTile(newGridIdx);
                    this.addGridTile(tile, newGridIdx);
                }
            }
        }
    }

    userPointingAtTile(currentTileName: string) {
        if (this.isNextToTileWithImage(currentTileName)) {
            this.highlightTile(currentTileName);
            if (this.lastHighlightedTileName && this.lastHighlightedTileName !== currentTileName) {
                this.unhighlightTile(this.lastHighlightedTileName);
            }
            this.lastHighlightedTileName = currentTileName;
        }
    }

    private highlightTile(tileName: string) {
        const tile = this.getTile(tileName);
        if (tile && !this.isTileWithImage(tileName)) {
            const highlightedMaterial = new THREE.MeshStandardMaterial({ 
                color: 0x00ff00, 
                side: THREE.DoubleSide, 
                transparent: true, 
                opacity: 0.8 });
            tile.material = highlightedMaterial
        }
    }

    private unhighlightTile(tileName: string) {
        const tile = this.getTile(tileName);
        if (tile && !this.isTileWithImage(tileName)) {
            const unhighlightedMaterial = new THREE.MeshStandardMaterial({ 
                color: 0x00ff00, 
                side: THREE.DoubleSide, 
                transparent: true, 
                opacity: 0.0 });
            tile.material = unhighlightedMaterial
        }
    }

    private isNextToTileWithImage(tileName: string): boolean {
        const gridIdx = GridIdx.fromString(tileName);
        if (!gridIdx) return false;

        if (this.tilesOn.length === 0) return true;

        const directions = [
            new GridIdx(gridIdx.row - 1, gridIdx.col),
            new GridIdx(gridIdx.row, gridIdx.col - 1),
            new GridIdx(gridIdx.row, gridIdx.col + 1),
            new GridIdx(gridIdx.row + 1, gridIdx.col),
            new GridIdx(gridIdx.row - 1, gridIdx.col - 1),
            new GridIdx(gridIdx.row - 1, gridIdx.col + 1),
            new GridIdx(gridIdx.row + 1, gridIdx.col - 1),
            new GridIdx(gridIdx.row + 1, gridIdx.col + 1)
        ];

        return directions.some(dir => this.isTileWithImage(dir.toString()));
    }

    placeTileImage(name: string | null = null) {
        const tileName = name ?? this.lastHighlightedTileName;

        if (!tileName) return;

        const tile = this.getTile(tileName);
        if (tile) {
            if (this.isTileWithImage(tileName)) {
                tile.material = new THREE.MeshStandardMaterial({ 
                    color: 0xffffff, 
                    side: THREE.DoubleSide, 
                    transparent: true, 
                    opacity: 0.0 });
                this.markTileWithoutImage(tileName);
            } else {
                if (this.isNextToTileWithImage(tileName)) {
                    this.tileImage.needsUpdate = true;
                    const material = new THREE.MeshStandardMaterial({
                        map: this.tileImage,
                        side: THREE.DoubleSide,
                        transparent: true
                    });
                    tile.material = material;
                    this.markTileWithImage(tileName);
                    this.addTicTacToeGridAroundTile(tileName);
                    this.extendGridAroundTile(tileName);
                }
            }
        }
    }

    private markTileWithImage(tileName: string) {
        const tileIdx = GridIdx.fromString(tileName);
        if (tileIdx) {
            this.tilesOn.push(tileIdx);
        }
    }

    private markTileWithoutImage(tileName: string) {
        const tileIdx = GridIdx.fromString(tileName);
        if (tileIdx) {
            const index = this.tilesOn.findIndex(t => t.row === tileIdx.row && t.col === tileIdx.col);
            if (index !== -1) {
                this.tilesOn.splice(index, 1);
            }
        }
    }

    private isTileWithImage(tileName: string): boolean {
        const tileIdx = GridIdx.fromString(tileName);
        if (!tileIdx) return false;
        return this.tilesOn.some(t => t.row === tileIdx.row && t.col === tileIdx.col);
    }

    changeGridTileImage(tileImage: THREE.Texture) {
        this.tileImage = tileImage

        for (const tileIdx of this.tilesOn) {
            const tile = this.getTile(tileIdx.toString())
            if (tile) {
                this.tileImage.needsUpdate = true;
                const material = new THREE.MeshStandardMaterial({
                    map: this.tileImage,
                    side: THREE.DoubleSide,
                    transparent: true
                });
                tile.material = material;
            }
        }
    }

    ///////////////////////////// DIVIDERS

    private addTicTacToeGridAroundTile(tileName: string): void {
        const tileIdx = GridIdx.fromString(tileName);
        if (!tileIdx) return;
        const tile = this.tiles.get(tileIdx.toString());
        if (!tile) return;
        if (this.dividers.has(tileIdx)) return;

        const ticTacToeGrid = this.createTicTacToeDividers(this.cellHeight, this.cellWidth, tile.position);
        this.dividersParent.add(ticTacToeGrid);
        this.dividers.set(tileIdx, ticTacToeGrid);
    }

    private createTicTacToeDividers(cellHeight: number, cellWidth: number, position: THREE.Vector3): THREE.Object3D {
        const parent = new THREE.Object3D();
        const rows = 3;
        const cols = 3;

        // Create vertical dividers
        const startZ = 0;
        const endZ = rows * cellHeight;
        for (let col = 1; col < cols; col++) {
            const x = col * cellWidth;
            const line = this.createDivider(new THREE.Vector3(x, 0, startZ), new THREE.Vector3(x, 0, endZ));
            line.rotation.x = Math.PI / 2;
            line.position.x -= cols / 2 * cellWidth;
            line.position.z -= rows / 2 * cellHeight;
            parent.add(line);
        }

        // Create horizontal dividers
        const startX = 0;
        const endX = cols * cellWidth;
        for (let row = 1; row < rows; row++) {
            const z = row * cellHeight;
            const line = this.createDivider(new THREE.Vector3(startX, 0, z), new THREE.Vector3(endX, 0, z));
            line.rotation.z = Math.PI / 2;
            line.position.x -= cols / 2 * cellWidth;
            line.position.z -= rows / 2 * cellHeight;
            parent.add(line);
        }

        parent.position.copy(position);

        return parent;
    }

    private createDivider(startPosition: THREE.Vector3, endPosition: THREE.Vector3): THREE.Object3D {
        const height = startPosition.distanceTo(endPosition);
        const geometry = new THREE.CylinderGeometry(0.002, 0.002, height, 32);
        const material = new THREE.MeshStandardMaterial({ color: 0x00ffff });
        const lineNode = new THREE.Mesh(geometry, material);
        lineNode.position.set(
            (startPosition.x + endPosition.x) / 2,
            (startPosition.y + endPosition.y) / 2,
            (startPosition.z + endPosition.z) / 2
        );

        return lineNode;
    }
}
