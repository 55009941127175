import * as THREE from 'three';
import { NetworkARObjectDict } from '../../ExperienceObjects/ExperienceObject';
import { v4 as uuidv4 } from 'uuid';
import { MenuListViewTheme } from './menu-list-component/MenuListView';

export class MenuConfig {
    menuItems: MenuItem[]
    menuListViewTheme: MenuListViewTheme
    smokeEffect: number
    placeViaFS: number

    constructor(
        menuItems: MenuItem[],
        menuNetworkConfig: MenuNetworkConfig
        ) {
        this.menuItems = menuItems
        this.menuListViewTheme = menuNetworkConfig.menuListViewTheme
        this.smokeEffect = menuNetworkConfig.smokeEffect
        this.placeViaFS = menuNetworkConfig.placeViaFS
    }
}

export class MenuNetworkConfig {
    menuItems: MenuNetworkItem[]
    menuListViewTheme: MenuListViewTheme
    smokeEffect: number
    placeViaFS: number

    constructor(data: Record<string, any>) {
        this.menuItems = data.menuItems.map((menuItemData: Record<string, any>) => {
            return new MenuNetworkItem(
                menuItemData.object,
                menuItemData.objectImage,
                menuItemData.objectDescription,
                menuItemData.objectName
            );
        });
        this.menuListViewTheme = new MenuListViewTheme(data.menuListViewTheme)
        this.smokeEffect = data.smokeEffect ?? 0
        this.placeViaFS = data.placeViaFS ?? 3
    }
}

export class MenuItem {
    object: THREE.Object3D;
    objectID: string;
    objectImage: NetworkARObjectDict;
    objectDescription: string;
    objectName: string;

    constructor(
        object: THREE.Object3D,
        menuNetworkItem: MenuNetworkItem
    ) {
        this.object = object;
        this.objectID = menuNetworkItem.objectID
        this.objectImage = menuNetworkItem.objectImage
        this.objectDescription = menuNetworkItem.objectDescription;
        this.objectName = menuNetworkItem.objectName;
    }
}

export class MenuNetworkItem {
    object: NetworkARObjectDict;
    objectID: string
    objectImage: NetworkARObjectDict;
    objectDescription: string;
    objectName: string;

    constructor(
        object: NetworkARObjectDict,
        objectImage: NetworkARObjectDict,
        objectDescription: string,
        objectName: string,
    ) {
        this.object = object;
        this.objectID = uuidv4()
        this.objectImage = objectImage;
        this.objectDescription = objectDescription;
        this.objectName = objectName;
    }
}