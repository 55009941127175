import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { ObjectTorus, Reticle } from '../utils';
import { ObjectActionManager, ObjectStraightMovement, ObjectAction, ObjectAxisRotation, ObjectAnimation, SpinParams } from './ObjectActionManager'
import { WebXRGestureManager } from './WebXRGestureManager'
import { ARAnalyticsManager, ExperienceARAnalytics } from './ARAnalyticsManager';
import { TriggerManager } from '../TriggerAction/TriggerManager';
import { BrandThemeManager } from './BrandThemeManager';
import { PhysicsManager } from './PhysicsManager'
import { ARObjectSettings, BrandTheme } from '../ExperienceObjects/ExperienceObject'
import { LottieNames, SessionStatus } from '../components/pages';
import { instance } from 'three/examples/jsm/nodes/Nodes.js';
import { ParticleEngine, ParticleSystemManager } from './ParticleSystemManager';
import { ParticleEngineExamples } from './ParticleEngine/Examples';
import { child } from 'firebase/database';
import { PlaneDirection } from '../Templates/PortalTemplate/PortalTemplate';

'use strict';

export class ARManager {
    xrAnimate: number = 0;
    xrSession: XRSession | null;
    xrSessionCleaned: boolean = false;
    xrSessionShouldClean: boolean = false
    renderer: THREE.WebGLRenderer | null;
    scene: THREE.Scene | null;
    camera: THREE.PerspectiveCamera | null;
    ARObjects: THREE.Object3D[];
    reticle: Reticle | null;
    reticleDup: THREE.Object3D | null = null;
    reticlePostTap: (() => void) | null;
    loader: GLTFLoader;

    tappedObject: THREE.Object3D | null;
    tappedObjectInitScale: THREE.Vector3;
    tappedObjectRing: ObjectTorus | null;
    // tappedObjectInitScaleFlag: boolean;

    physicsManager: PhysicsManager;
    triggerManager: TriggerManager;
    objectActionManager: ObjectActionManager;
    ARAnalyticsManager: ARAnalyticsManager | null;
    gestureManager: WebXRGestureManager | null;
    brandThemeManager: BrandThemeManager | null;
    particleSystemManager: ParticleSystemManager | null;
    particleEngine: ParticleEngine | null = null

    // Used for detecting when the session is stabilized
    hitTestSource: XRHitTestSource | null | undefined;
    viewerSpace: XRReferenceSpace | null;
    localSpace: XRReferenceSpace | null;
    stabilized: boolean;

    ARSessionStatusChange: ((status: SessionStatus) => void) | null = null
    showInstructionsLottie: ((lottieName: string | null) => void) | null = null
    reticleFirstHitCallback?: (transform: THREE.Matrix4) => void
    firstReticleUpdate: boolean = true
    // reticleInstructionsPresented: boolean = false

    trackingLost: boolean

    clock = new THREE.Clock();

    private mixers: Map<THREE.Object3D, THREE.AnimationMixer>;
    private lastXRFrameTime = 0;
    // // Used for FPS computation
    // frameCount = 0;
    // fps = 0;
    // lastTime = performance.now();

    constructor(triggerManager: TriggerManager, brandThemeManager: BrandThemeManager | null,
        // stabilizationCallback: (() => void) | null = null,
        ARSessionStatusChange: ((status: SessionStatus) => void) | null = null,
        showInstructionsLottie: ((lottieName: string | null) => void) | null = null,
        private setShowJoystickButton?: (show: boolean) => void
    ) {
        this.xrSession = null;
        this.renderer = null;
        this.scene = null;
        this.camera = null;
        this.ARObjects = [];
        this.reticle = null;
        this.reticlePostTap = null
        this.loader = new GLTFLoader();

        this.tappedObject = null;
        this.tappedObjectInitScale = new THREE.Vector3(1, 1, 1);
        this.tappedObjectRing = null
        // this.tappedObjectInitScaleFlag = true;

        this.triggerManager = triggerManager
        this.brandThemeManager = brandThemeManager;
        this.physicsManager = new PhysicsManager(triggerManager);
        this.objectActionManager = new ObjectActionManager(this.physicsManager);
        this.ARAnalyticsManager = null;
        this.gestureManager = null;
        // this.particleSystemManager = new ParticleSystemManager()
        // this.particleEngine = new ParticleEngine()

        this.stabilized = false;
        // this.stabilizedCallback = stabilizationCallback
        this.ARSessionStatusChange = ARSessionStatusChange
        this.showInstructionsLottie = showInstructionsLottie

        this.trackingLost = false
        this.mixers = new Map();
    }

    async initXR(requiredFeatures = ["local-floor", "hit-test"]) {
        console.log("ARManager: initializing XR session with required features: ", requiredFeatures)
        try {
            // Initialize a WebXR session using "immersive-ar".
            if (navigator.xr) {
                this.xrSession = await navigator.xr.requestSession("immersive-ar", {
                    requiredFeatures: requiredFeatures,
                    optionalFeatures: ["dom-overlay"],
                    domOverlay: { root: document.querySelector("#overlay-container") ?? document.body }
                });
                this.xrSessionCleaned = false
                this.ARSessionStatusChange?.(SessionStatus.ARSessionRequested)
            }

            if (this.xrSession) {
                // With everything set up, start the app.
                await this.setupARScene();

                this.ARSessionStatusChange?.(SessionStatus.ARSessionInitialized)

                this.setupGestureManager(this.xrSession, this.renderer!, this.scene!, this.camera!)
                this.setupARAnalyticsManager()

                this.xrSession.addEventListener('end', this.sessionEnded.bind(this));
            }
        } catch (e) {
            console.log(e);
        }
    };

    sessionEnded() {
        console.log("WebXR Session Ended! Cleaning up...")
        this.xrSessionShouldClean = true
        // this.sessionEndedCallback?.()
        this.ARSessionStatusChange?.(SessionStatus.ARSessionEnded)
    }

    cleanup() {
        this.xrSessionShouldClean = true

        if (this.xrSessionCleaned) {
            return
        }

        //     this.xrSession.end()
        console.log("ARManager cleanup", this.xrSession)
        this.stopAR()
        this.gestureManager?.cleanup()
        this.physicsManager.cleanup()
        this.objectActionManager.cleanup()
        this.ARAnalyticsManager?.cleanup()

        this.renderer?.renderLists?.dispose()
        this.xrSession?.removeEventListener("selectstart", this.onSelectStart.bind(this))
        this.xrSession = null
        this.xrSessionCleaned = true
    }

    createLitScene() {
        const scene = new THREE.Scene();
        const light = new THREE.AmbientLight(0xffffff, 1.0);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
        directionalLight.position.set(5, 10, 5);
        // const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff);
        // hemiLight.position.set(0, 30, 0);

        // scene.add(hemiLight);
        scene.add(light)
        scene.add(directionalLight);

        return scene;
    }

    async setupARScene() {
        console.log("ARManager: Setting up AR scene...")
        // document.body.classList.add('ar');

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        // this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setSize(window.screen.width, window.screen.height);
        this.renderer.xr.enabled = true;

        this.renderer.xr.setReferenceSpaceType("local");
        await this.renderer.xr.setSession(this.xrSession);

        this.camera = new THREE.PerspectiveCamera(
            85,
            // window.innerWidth / window.innerHeight,
            window.screen.width / window.screen.height,
            0.1,
            100
        );
        this.camera.matrixAutoUpdate = false;

        this.scene = this.createLitScene();
        // this.scene = this.createPhysicsScene();

        this.scene.add(this.camera);


        // Used for detecting when the session is stabilized
        this.viewerSpace = await this.xrSession.requestReferenceSpace('viewer');
        this.localSpace = await this.xrSession.requestReferenceSpace('local');

        // Perform hit testing using the viewer as origin.
        this.hitTestSource = await this.xrSession.requestHitTestSource({ space: this.viewerSpace });
    }

    setupARAnalyticsManager() {
        this.ARAnalyticsManager = new ARAnalyticsManager(this.camera)
        this.ARAnalyticsManager.userPosition(() => {
            return this.getUserPosition()
        })
        this.ARAnalyticsManager.userAngles(() => {
            const euler = this.getUserOrientation()
            return new THREE.Vector3(euler.x, euler.y, euler.z)
        })
    }

    getARAnalytics(): ExperienceARAnalytics | null {
        if (this.ARAnalyticsManager) {
            return this.ARAnalyticsManager.getAnalytics()
        }

        return null
    }

    async setupGestureManager(session: XRSession,
        renderer: THREE.WebGLRenderer,
        scene: THREE.Scene,
        camera: THREE.PerspectiveCamera) {

        console.log("Setting up gesture manager...")
        this.gestureManager = new WebXRGestureManager(session, renderer, scene, camera)
        await this.gestureManager.init()

        // Hit test without tapping
        this.gestureManager.onHitTest((hitPose: XRPose) => {
            this.reticle?.updateReticleObject(hitPose)
            this.triggerManager.sceneHit(hitPose)

            if (this.reticle && this.firstReticleUpdate) {
                this.firstReticleUpdate = false
                this.reticleFirstHitCallback?.(this.XRPoseToMatrix4(hitPose))
                if (this.showInstructionsLottie) {
                    this.showInstructionsLottie(LottieNames.tapScreen)
                }
            }
        })

        this.gestureManager.onTap((tap) => {
            if (this.reticle) {
                if (this.reticle.lastHitPose && this.reticlePostTap) {
                    this.reticlePostTap()
                    this.reticlePostTap = null
                    this.firstReticleUpdate = true
                    // this.reticleInstructionsPresented = false
                    return
                }
            }

            this.triggerManager.screenTap(tap)
        })

        this.gestureManager.onPinch((delta) => {
            if (!this.areGesturesAllowed(this.tappedObject)) return

            this.tappedObject?.scale.set(delta * this.tappedObjectInitScale.x, delta * this.tappedObjectInitScale.y, delta * this.tappedObjectInitScale.z)
        })

        this.gestureManager.onPinchEnd((delta) => {
            if (!this.areGesturesAllowed(this.tappedObject)) return

            if (this.tappedObject !== null) {
                this.ARAnalyticsManager?.objectScaled(this.tappedObject, delta)
            }
        })

        this.gestureManager.onRotate((delta) => {
            if (!this.areGesturesAllowed(this.tappedObject)) return

            this.tappedObject?.rotateY(delta)
        })

        this.gestureManager.onRotateEnd((delta) => {
            if (!this.areGesturesAllowed(this.tappedObject)) return

            if (this.tappedObject !== null) {
                this.ARAnalyticsManager?.objectRotated(this.tappedObject, delta)
            }
        })

        // General hit
        this.gestureManager.onTapHitTest((hitPose, tap) => {
            this.triggerManager.sceneTapHit(hitPose, tap)
        })

        // Object (mesh/point) hit
        this.gestureManager.onObjectTap((intersections) => {
            const handledObjects: Record<string, boolean> = {}
            for (const intersect of intersections) {
                const tappedObject = intersect.object
                const rootObject = this.getARObjectRoot(tappedObject)
                this.ARAnalyticsManager?.objectTapped(rootObject)
                this.handleNewSelectedObject(rootObject, true)

                // The intersections sometimes contain all the child nodes of an object. So we make sure to trigger an object tap only on distinct root objects
                if (!(rootObject.uuid in handledObjects)) {
                    this.triggerManager.sceneObjectTapped(rootObject)
                    handledObjects[rootObject.uuid] = true
                }

            }
            // this.triggerManager.sceneObjectTapped(intersections)
        })

        this.gestureManager.onPlaneDetection((pose) => {
            this.triggerManager.scenePlaneDetected(pose)
        })

        // Plane (surface) hit
        this.gestureManager.onPlaneTap((pose) => {
            this.triggerManager.scenePlaneTapped(pose)
            this.ARAnalyticsManager?.planeTapped(pose)
        })

        session.addEventListener("selectstart", this.onSelectStart.bind(this));
    }

    areGesturesAllowed(object?: THREE.Object3D): boolean {
        if (!object)
            return false

        const settings: ARObjectSettings = object.userData.settings

        if (settings && settings.allowUserGestures) {
            return true
        }

        return false
    }

    handleNewSelectedObject(object: THREE.Object3D, addRing: boolean = false) {
        if (this.tappedObject) {
            this.removeRingUnderneath(this.tappedObject)
        }

        const rootObject = this.getARObjectRoot(object)
        this.tappedObject = rootObject

        if (addRing && this.areGesturesAllowed(rootObject)) {
            // this.addRingUnderneath(this.tappedObject)
            this.animateObjectFlash(this.tappedObject)

            // if (!this.tappedObject || rootObject.id != this.tappedObject.id) {
            //     this.tappedObject = rootObject
            //     this.animateObjectFlash(this.tappedObject)
            // }
        }
        // this.enableJoystickButton()
    }

    addRingUnderneath(object: THREE.Object3D) {
        this.removeRingUnderneath(object)

        const ring = new ObjectTorus(object, this.brandThemeManager.brandTheme)
        ring.name = "underneathRingNode"
        // const desiredPosition = ring.position.clone()
        // ring.createAnimation()
        // ring.position.copy(desiredPosition);

        object.add(ring)
        // this.addARObjectToScene(ring)
        // this.tappedObjectRing = ring
    }

    removeRingUnderneath(object: THREE.Object3D) {
        this.removeChildByName(object, "underneathRingNode")
    }

    getScreenCenterIntersections(candidates: THREE.Object3D[] | null = null): THREE.Object3D[] {
        var ray = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3());
        // console.log("THIS.CAMERA", this.camera)
        ray.setFromCamera(new THREE.Vector2(0, 0), this.camera)
        var rayIntersects = ray.intersectObjects(candidates ?? this.scene.children, true);

        var rootObjects: THREE.Object3D[] = []
        for (const intersect of rayIntersects) {
            const tappedObject = intersect.object
            // const rootObject = this.getARObjectRoot(tappedObject)
            rootObjects.push(tappedObject)
        }

        return rootObjects
    }

    // getMemoryUsage() {
    //     const memoryInfo = performance.memory;
    //     if (memoryInfo) {
    //         // Total heap size
    //         const totalHeapSize = memoryInfo.totalJSHeapSize;
    //         // console.log('Total Heap Size:', totalHeapSize/1000000);

    //         // Used heap size
    //         const usedHeapSize = memoryInfo.usedJSHeapSize;
    //         console.log('Used Heap Size:', usedHeapSize/1000000);

    //         // Remaining heap size available to the app
    //         const availableHeapSize = memoryInfo.jsHeapSizeLimit - usedHeapSize;
    //         console.log('Available Heap Size:', availableHeapSize/1000000);
    //     }
    // }

    // computeFPS() {
    //     this.frameCount++;

    //     // Calculate time elapsed since last frame
    //     const currentTime = performance.now();
    //     const deltaTime = currentTime - this.lastTime;

    //     // Update FPS every second
    //     if (deltaTime >= 1000) {
    //         this.fps = this.frameCount * 1000 / deltaTime;
    //         console.log("FPS:", this.fps.toFixed(2));
    //         this.frameCount = 0;
    //         this.lastTime = currentTime;
    //     }
    // }

    onXRFrame = (time: number, frame: XRFrame) => {
        const session = this.renderer!.xr.getSession()!;
        session.requestAnimationFrame(this.onXRFrame);

        // console.log("RENDERER INFO", this.renderer.info.memory)
        // this.getMemoryUsage()

        let viewerPose = frame.getViewerPose(this.localSpace);
        if (viewerPose === null) {
            // If this is the first frame where the tracking is lost
            if (!this.trackingLost) {
                this.triggerManager.trackingLost(frame)
            }
            this.trackingLost = true
        } else {
            // If this is the first frame where the tracking is not lost
            if (this.trackingLost) {
                this.triggerManager.trackingEstablished(frame)
            }
            this.trackingLost = false
            if (!this.stabilized) {
                console.log("ARManager - AR STABILIZED")
                this.stabilized = true;

                this.ARSessionStatusChange?.(SessionStatus.ARSessionStabilized)
            }
        }

        if (this.stabilized && !this.xrSessionShouldClean) {
            this.physicsManager.tick();
            this.ARAnalyticsManager?.tick(frame, time)
            this.gestureManager?.tick(frame)
            this.triggerManager.newXRFrame(frame)
            this.objectActionManager.tick()
            var dt = this.clock.getDelta();
            // this.particleSystemManager.tick( dt * 0.5 )
            // this.particleEngine.update( dt * 0.5 );	
        }

        // Progress the animations by updating the mixers
        const deltaTime = (time - this.lastXRFrameTime) / 1000;
        this.lastXRFrameTime = time;
        for (const mixer of this.mixers.values()) {
            mixer.update(deltaTime);
        }

        this.renderer?.render(this.scene!, this.camera!);
    }

    onSelectStart() {
        if (this.tappedObject) {
            this.tappedObjectInitScale.copy(this.tappedObject.scale)
        }
    }

    startAR() {
        // Start a rendering loop using this.onXRFrame.        
        this.xrAnimate = this.xrSession!.requestAnimationFrame(this.onXRFrame);
        this.triggerManager.startedAR()
    }

    stopAR() {
        this.xrSession?.cancelAnimationFrame(this.xrAnimate);
    }

    addARObjectToCamera(object: THREE.Object3D) {
        this.camera!.add(object)
        this.ARObjects.push(object)

        this.handleNewSelectedObject(object)
    }

    ////////////////// Object placement
    addARObjectToScene(object: THREE.Object3D) {
        var objectSettings: ARObjectSettings
        if (object.userData.settings instanceof ARObjectSettings) {
            objectSettings = object.userData.settings
        } else {
            var objectSettings: ARObjectSettings = new ARObjectSettings(object.userData.settings)
        }

        this.scene?.add(object)
        this.startAnimationIfPresent(object)
        this.ARObjects.push(object)
        this.ARAnalyticsManager?.objectAddedToScene(object)

        if (objectSettings.addPhysicsBody)
            this.physicsManager.addARObjectToPhysicsWorld(object, 1)

        if (objectSettings.timeOut > 0) {
            setTimeout(() => {
                this.removeARObjectFromScene(object)
            }, objectSettings.timeOut * 1000);
        }

        if (objectSettings.offsetVec)
            object.position.add(objectSettings.offsetVec);

        this.handleNewSelectedObject(object)
        // const rootObject = this.getARObjectRoot(object)
        // const settings: ARObjectSettings = rootObject.userData.settings
        // if (settings && settings.allowUserGestures) {
        //     this.tappedObject = rootObject
        // }
    }

    addARObjectToSceneViaFS(object: THREE.Object3D, onPlacementCallback?: (transform: THREE.Matrix4) => void, reticleFirstHitCallback?: (transform: THREE.Matrix4) => void) {
        this.reticleFirstHitCallback = reticleFirstHitCallback
        this.reticlePostTap = function () {
            if (this.reticle && this.reticle.lastHitPose) {
                this.changeARObjectPose(object, this.reticle.lastHitPose)
                let poseMat = this.XRPoseToMatrix4(this.reticle.lastHitPose)
                this.addARObjectToScene(object)
                this.removeReticleObject()
                if (onPlacementCallback) {
                    onPlacementCallback(poseMat);
                }
            }
        }
        this.addBrandedReticleObject()
    }

    startAnimationIfPresent(object: THREE.Object3D) {
        if (object instanceof THREE.Object3D && (object as any).animations && (object as any).animations.length > 0) {
            const mixer = new THREE.AnimationMixer(object);
            const animationAction = mixer.clipAction((object as any).animations[0]);
            animationAction.play();
            this.mixers.set(object, mixer);
        }
    }

    removeAnimation(object: THREE.Object3D) {
        const mixer = this.mixers.get(object);
        if (mixer) {
            mixer.stopAllAction();
            this.mixers.delete(object); // Clean up the mixer
        }
    }

    getTransformViaFS(handler: (transform: THREE.Matrix4) => void, reticleFirstHitCallback?: (transform: THREE.Matrix4) => void) {
        this.reticleFirstHitCallback = reticleFirstHitCallback
        this.reticlePostTap = function () {
            if (this.reticle && this.reticle.lastHitPose) {
                let poseMat = this.XRPoseToMatrix4(this.reticle.lastHitPose)
                this.removeReticleObject()
                handler(poseMat)
            }
        }
        this.addBrandedReticleObject()
    }

    changeARObjectPose(object: THREE.Object3D, pose: XRPose) {
        let poseMat = this.XRPoseToMatrix4(pose)
        this.changeARObjectTransform(object, poseMat)
        // object.applyMatrix4(poseMat)
    }

    changeARObjectPosition(object: THREE.Object3D, position: THREE.Vector3) {
        object.position.set(position.x, position.y, position.z)
        this.physicsManager.ARObjectMovedManually(object)
    }

    changeARObjectOrientation(object: THREE.Object3D, orientation: THREE.Quaternion) {
        object.applyQuaternion(orientation)
        this.physicsManager.ARObjectRotatedManually(object)
    }

    changeARObjectTransform(object: THREE.Object3D, transform: THREE.Matrix4) {
        let position = new THREE.Vector3();
        let quaternion = new THREE.Quaternion();
        let scale = new THREE.Vector3();
        transform.decompose(position, quaternion, scale);

        object.position.copy(position);
        object.quaternion.copy(quaternion);
        // object.scale.copy(scale);

        // object.applyMatrix4(transform)
        this.physicsManager.ARObjectMovedManually(object)
        this.physicsManager.ARObjectRotatedManually(object)
    }

    spinARObject(object: THREE.Object3D, spinParams?: SpinParams) {
        let params = spinParams
        if (!params) {
            params = {
                action: "animate",
                keyPath: "rotation",
                duration: 0.5,
                repeatCount: 1,
                fromValue: [0, 1, 0, 0],
                toValue: [0, 1, 0, 6.2831]
            }
        }
        const animation = new ObjectAnimation(params);
        if (animation) {
            this.addAnimationToObject(object, animation)
        }
    }

    XRPoseToMatrix4(pose: XRPose): THREE.Matrix4 {
        let M = pose.transform.matrix
        return new THREE.Matrix4(M[0], M[1], M[2], M[3], M[4], M[5], M[6], M[7], M[8], M[9], M[10], M[11], M[12], M[13], M[14], M[15]).transpose()
    }

    scaleARObject(object: THREE.Object3D, scale: number) {
        const bbox = new THREE.Box3().setFromObject(object);
        const origHeight = bbox.max.y - bbox.min.y;
        const scaleFactor = scale / origHeight;
        object.scale.set(scaleFactor, scaleFactor, scaleFactor);
    }

    rotateObject(object: THREE.Object3D, angle: number) {
        object.rotateY(angle)
    }

    removeARObjectFromScene(object: THREE.Object3D) {
        this.physicsManager.removeARObjectFromPhysicsWorld(object)
        this.removeAnimation(object)
        this.scene!.remove(object)
        object.removeFromParent()
        this.removeARObjectFromList(object)
    }

    // Important thread on clearing an object:
    // https://discourse.threejs.org/t/understanding-relationship-with-texture-dispose-and-webglrenderer-info-textures/51459/2
    ARObjectCleanup(object: THREE.Object3D) {
        for (const childNode of object.children) {
            this.ARObjectCleanup(childNode)
        }

        if (object instanceof THREE.Mesh) {
            object.geometry.dispose()   // Seems to clear the geometries
            const possibleMaps = ["map", "alphaMap", "aoMap", "bumpMap", "displacementMap", "emissiveMap", "envMap", "lightMap", "metalnessMap", "normalMap", "roughnessMap"]
            possibleMaps.forEach((mapName) => {
                if (object.material[mapName] && typeof object.material[mapName].dispose === 'function') {
                    object.material[mapName].dispose();
                }
            });
            object.material.dispose()
        }
    }

    removeChildByName(object: THREE.Object3D, name: string): void {
        const childToRemove = object.children.find(child => child.name === name);
        if (childToRemove) {
            object.remove(childToRemove);
            childToRemove.removeFromParent()
            this.ARObjectCleanup(childToRemove)
        }
    }

    removeARObjectFromCamera(object: THREE.Object3D) {
        this.camera!.remove(object)
        this.removeARObjectFromList(object)
    }

    removeARObjectFromList(object: THREE.Object3D) {
        const index = this.isObjectPlaced(object);
        if (index >= 0) {
            this.ARObjects.splice(index, 1);
        }
    }

    isObjectPlaced(object: THREE.Object3D) {
        const index = this.ARObjects.indexOf(object);
        return index
    }

    getARObjectRoot(object: THREE.Object3D): THREE.Object3D {
        if (!object.parent || object.parent instanceof THREE.Scene) {
            return object
        } else {
            return this.getARObjectRoot(object.parent)
        }
    }

    /////////////// Helper funcs
    getUserDirection() {
        const direction = new THREE.Vector3();
        this.camera!.getWorldDirection(direction)

        return direction.normalize()
    }

    getTransformInfronOfUser(dists: THREE.Vector3) {
        // Create a Matrix4 that represents both position and orientation.
        const matrix = new THREE.Matrix4();
        matrix.compose(
            this.camera.position.clone(),//.add(pointInFront),
            this.camera.quaternion.clone(),
            new THREE.Vector3(1, 1, 1)
        );

        matrix.multiplyMatrices(matrix, new THREE.Matrix4().makeTranslation(dists.x, dists.y, dists.z));

        return matrix
    }

    getPointInFrontOfUser(distance: number = 1): THREE.Vector3 {
        // Create a direction vector that points forward
        const direction = this.getUserDirection()

        // Scale the direction vector by the specified distance
        direction.multiplyScalar(distance);

        const userPos = this.getUserPosition()

        // Calculate the point in front of the user by adding the camera position
        const pointInFront = userPos.add(direction);

        return pointInFront; // Return the point as a Vector3
    }

    getUserDirectionVector(): THREE.Vector3 {
        // Create a direction vector that points forward
        const direction = new THREE.Vector3(0, 0, -1); // Assuming the camera looks along the negative Z-axis

        // Apply the camera's rotation to the direction vector
        direction.applyQuaternion(this.camera.quaternion);

        return direction;
    }

    getTranslatedTransform(transform: THREE.Matrix4 | null, relativeTranslation: THREE.Vector3) {
        // Create a Matrix4 that represents both position and orientation.
        var matrix: THREE.Matrix4 = transform

        if (matrix === null) {
            matrix = new THREE.Matrix4();
            matrix.compose(
                this.camera.position.clone(),
                this.camera.quaternion.clone(),
                new THREE.Vector3(1, 1, 1)
            );
        }

        matrix.multiplyMatrices(matrix, new THREE.Matrix4().makeTranslation(relativeTranslation.x, relativeTranslation.y, relativeTranslation.z));

        return matrix
    }

    getUserPosition(): THREE.Vector3 {
        const position = new THREE.Vector3();
        position.copy(this.camera!.position)

        return position
    }

    getUserOrientation(): THREE.Euler {
        const cameraRotation = this.camera.rotation.clone();
        return cameraRotation
    }

    getUserTransform(): THREE.Matrix4 {
        return this.camera.matrixWorld.clone();
    }

    /////////////// Object Actions
    addActionToObject(object: THREE.Object3D, action: ObjectAction) {
        this.objectActionManager.addActionToObject(object, action);
    }

    addAnimationToObject(object: THREE.Object3D, animation: ObjectAnimation) {
        this.objectActionManager.addAnimationToObject(object, animation);
    }

    // The time parameter == how much time for moving 1m.
    shootObjectForward(object: THREE.Object3D, distance: number, time: number) {
        const direction = this.getUserDirection();
        const cameraRotation = this.getUserOrientation()
        const userPosition = this.getUserPosition()
        object.rotation.copy(cameraRotation);
        object.position.copy(userPosition)
        // const cameraTransform = this.getUserTransform()
        // object.applyMatrix4(cameraTransform)
        const action = new ObjectStraightMovement(direction, distance, time)
        this.addActionToObject(object, action);

        // const scalar = 1
        // const velocity = new THREE.Vector3(scalar*direction.x, scalar*direction.y, scalar*direction.z);
        // this.physicsManager.applyVelocityToObject(object, velocity)
        // const forceVec = new THREE.Vector3(scalar*direction.x, scalar*direction.y, scalar*direction.z) 
        // const gravityForce = new THREE.Vector3(0, -9.81, 0)
        // this.physicsManager.applyForceToObject(object, gravityForce)

    }

    animateObjectFlash(object: THREE.Object3D, duration: number = 600, interval: number = 150) {
        let isVisible = false;
        const flashInterval = setInterval(() => {
            object.visible = isVisible;
            isVisible = !isVisible;
        }, interval);

        setTimeout(() => {
            clearInterval(flashInterval);
            object.visible = true;
        }, duration);
    }

    replaceARObjectWithAnotherARObject(object: THREE.Object3D, newObject: THREE.Object3D) {
        object.add(newObject)
    }

    /////////////// Reticle object
    addReticleObject(imagePath: string | null = null, planeWidth: number = 0.1, planeHeight: number = 0.1, reticle3DModelPath: string | null = null) {
        if (this.reticle == null) {
            const ret = new Reticle(imagePath, planeWidth, planeHeight, reticle3DModelPath);
            this.reticle = ret
            this.addARObjectToScene(this.reticle)
            this.showInstructionsLottie?.(LottieNames.scanArea)
        }
    }

    addBrandedReticleObject() {
        if (this.brandThemeManager) {
            const imageUrl = this.brandThemeManager.getBrandLogoUrl()
            this.addReticleObject(imageUrl, 0.1, 0.1)
        } else {
            this.addReticleObject()
        }
        console.log("RETICLE ADDED...")
    }

    removeReticleObject() {
        if (this.reticle) {
            this.ARObjectCleanup(this.reticle)
            this.removeARObjectFromScene(this.reticle)
            this.reticle = null
            this.showInstructionsLottie?.(null)
        }
    }

    async getSmokeEffect(): Promise<THREE.Points> {
        this.particleSystemManager.setParameters(ParticleEngineExamples.smoke)
        // const particles = await this.particleSystemManager.createSmokeEffect()
        const particles = await this.particleSystemManager.createParticlesSystem()
        return particles
    }

    /////// Joystick
    enableJoystickButton() {
        this.setShowJoystickButton?.(true)
    }

    removeJoystickButton() {
        this.setShowJoystickButton?.(false)
    }

    createTexturedPortalSphere(texture: THREE.Texture, radius = 1): THREE.Object3D {
        // Create the inner sphere with the desired texture
        const innerMaterial = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide
        });
        const innerSphere = new THREE.Mesh(new THREE.SphereGeometry(radius - 0.01, 32, 32), innerMaterial);
        innerSphere.name = "InnerSphere";

        // Create the outer sphere with an occluding material
        const occludingMaterial = new THREE.MeshStandardMaterial({
            colorWrite: false
        });
        const outerSphere = new THREE.Mesh(new THREE.SphereGeometry(radius, 32, 32), occludingMaterial);
        outerSphere.name = "OuterSphere";
        outerSphere.renderOrder = -1

        // Create a group to hold both spheres
        const group = new THREE.Object3D();
        group.name = "PortalSphereGroup";
        group.add(outerSphere);
        group.add(innerSphere);

        return group;
    }

    createInvisiblePlane(width: number, height: number, direction: PlaneDirection): THREE.Mesh {
        // Create the plane geometry
        const geometry = new THREE.PlaneGeometry(width, height);

        // Create a basic material with transparent properties
        const occlusionMaterial = new THREE.MeshStandardMaterial({
            // color: 0x0000ff,
            colorWrite: false
        });

        // Create the mesh using the geometry and material
        const plane = new THREE.Mesh(geometry, occlusionMaterial);
        plane.renderOrder = -1
        // Rotate the plane based on the specified direction
        switch (direction) {
            case PlaneDirection.XPositive:
                plane.rotation.y = Math.PI / 2; // Rotate 90 degrees around Y
                break;
            case PlaneDirection.XNegative:
                plane.rotation.y = -Math.PI / 2; // Rotate -90 degrees around Y
                break;
            case PlaneDirection.YPositive:
                plane.rotation.x = -Math.PI / 2; // Rotate 90 degrees around X
                break;
            case PlaneDirection.YNegative:
                plane.rotation.x = Math.PI / 2; // Rotate -90 degrees around X
                break;
            case PlaneDirection.ZPositive:
                plane.rotation.y = Math.PI; // No rotation needed for Z positive
                break;
            case PlaneDirection.ZNegative:
                plane.rotation.z = Math.PI; // Rotate 180 degrees around Z
                break;
        }

        return plane; // Return the invisible occluding plane
    }

    createInvisibleRectangularWall(
        width: number,
        height: number,
        depth: number,
        addEntrance: boolean = false
    ): THREE.Object3D {
        const wallNode = new THREE.Object3D();

        let frontPlane: THREE.Object3D;

        if (addEntrance) {
            // Define the door dimensions
            const doorWidthPercentage = 1 / 3;
            const doorHeightPercentage = 0.5;
            const doorWidth = width * doorWidthPercentage; // Door width (30% of wall width)
            const doorHeight = height * doorHeightPercentage; // Door height (50% of wall height)

            // Create the left and right parts of the door
            const frontPlaneLeft = this.createInvisiblePlane(
                (width - doorWidth) / 2,
                height,
                PlaneDirection.ZNegative
            );
            frontPlaneLeft.position.set(-width * doorWidthPercentage, 0, depth / 2);

            const frontPlaneRight = this.createInvisiblePlane(
                (width - doorWidth) / 2,
                height,
                PlaneDirection.ZNegative
            );
            frontPlaneRight.position.set(width * doorWidthPercentage, 0, depth / 2);

            // Create the top part of the door
            const frontPlaneTop = this.createInvisiblePlane(
                doorWidth,
                height - doorHeight,
                PlaneDirection.ZNegative
            );
            frontPlaneTop.position.set(0, -height / 2 + doorHeight + (1 - doorHeightPercentage) * height / 2, depth / 2);

            // Create the door outline
            const frontPlaneDoor = this.createRectangleOutline(doorWidth - 0.02, doorHeight - 0.02);
            frontPlaneDoor.position.set(0, -height / 2 + doorHeight / 2, depth / 2);

            // Create the front plane and add the door components
            frontPlane = new THREE.Object3D();
            frontPlane.add(frontPlaneLeft);
            frontPlane.add(frontPlaneRight);
            frontPlane.add(frontPlaneTop);
            frontPlane.add(frontPlaneDoor);
        } else {
            frontPlane = this.createInvisiblePlane(width, height, PlaneDirection.ZNegative);
            frontPlane.position.set(0, 0, depth / 2);
        }

        // Create the back plane
        const backPlane = this.createInvisiblePlane(width, height, PlaneDirection.ZPositive);
        backPlane.position.set(0, 0, -depth / 2);

        // Create the left, right, top, and bottom planes
        const leftPlane = this.createInvisiblePlane(depth, height, PlaneDirection.XNegative);
        leftPlane.position.set(-width / 2, 0, 0);

        const rightPlane = this.createInvisiblePlane(depth, height, PlaneDirection.XPositive);
        rightPlane.position.set(width / 2, 0, 0);

        const topPlane = this.createInvisiblePlane(width, depth, PlaneDirection.YPositive);
        topPlane.position.set(0, height / 2, 0);

        const bottomPlane = this.createInvisiblePlane(width, depth, PlaneDirection.YNegative);
        bottomPlane.position.set(0, -height / 2, 0);
        bottomPlane.name = "floor";

        // Add planes to the wall node
        wallNode.add(frontPlane);
        wallNode.add(backPlane);
        wallNode.add(leftPlane);
        wallNode.add(rightPlane);
        wallNode.add(topPlane);
        wallNode.add(bottomPlane);

        return wallNode; // Return the wall node
    }

    createRectangleOutline(width: number, height: number): THREE.Object3D {
        // Create a basic plane geometry for the rectangle
        const geometry = new THREE.PlaneGeometry(width, height);

        // Create edges geometry from the plane geometry
        const edges = new THREE.EdgesGeometry(geometry);

        // Create a line segments from the edges with the specified color
        const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: '0xff00ff' }));

        // Create an Object3D to hold the rectangle outline
        const outline = new THREE.Object3D();
        outline.add(line);

        return outline;
    }
}
