import React from 'react';
import { Slider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

export interface IJoystickSlider {
    minValue: number;
    maxValue: number;
    length: number;
}

export enum JoystickSliderOptions {
    moveX = "moveX",
    moveY = "moveY",
    moveZ = "moveZ",
    rotateX = "rotateX",
    rotateY = "rotateY",
    rotateZ = "rotateZ"
}

export interface IJoystickController {
    rotateX: IJoystickSlider;
    rotateY: IJoystickSlider;
    rotateZ: IJoystickSlider;
    moveX: IJoystickSlider;
    moveY: IJoystickSlider;
    moveZ: IJoystickSlider;
    onChange: (joystickIdx: JoystickSliderOptions) => (value: number) => void;
}

// Combined Joystick Component
export const JoystickController: React.FC<IJoystickController> = ({ rotateX, rotateY, rotateZ, moveX, moveY, moveZ, onChange }) => {

    return (
        <div style={{ width: '100vw', zIndex: 99999, display: 'flex', position: 'relative', height: 320, transform: 'translateY(100%)', top: '10vh' }}>
            {/* MoveX slider at the bottom left, horizontal */}
            <div style={{ padding: 5, width: '50%' }}>
                <div style={{ display: 'flex', position: 'absolute', left: 0, bottom: '-20%', width: 25, flexDirection: 'column', justifyContent: 'space-between', height: 240, transform: 'rotate(45deg) translate(120%,-45%)' }}>
                    <ArrowUpOutlined style={{ marginLeft: 2 }} />
                    <Slider
                        min={moveZ.minValue}
                        max={moveZ.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.moveZ)}
                        style={{ height: 230, marginLeft: 4 }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                        vertical
                    />
                </div>
                <div style={{ display: 'flex', position: 'absolute', left: 0, bottom: 10, width: 25, flexDirection: 'column', justifyContent: 'space-between', height: 260 }}>
                    <ArrowUpOutlined style={{ marginLeft: 2 }} />
                    <Slider
                        min={moveY.minValue}
                        max={moveY.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.moveY)}
                        style={{ height: 230, marginLeft: 4 }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                        vertical
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0, width: '50%', left: 2, height: 20 }}>
                    {/* <ArrowLeftOutlined /> */}
                    <Slider
                        min={moveX.minValue}
                        max={moveX.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.moveX)}
                        style={{ width: '80%' }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                    />
                    <ArrowRightOutlined />
                </div>
            </div>
            {/* MoveX slider at the bottom left, horizontal */}
            <div style={{ padding: 5, width: '50%', position: 'relative', float: 'right' }}>
                {/* Vertical Slider for moveY */}
                <div style={{ display: 'flex', position: 'absolute', right: 0, bottom: '-20%', width: 25, flexDirection: 'column', justifyContent: 'space-between', height: 240, transform: 'rotate(-45deg) translate(-120%,-45%)' }}>
                    <ArrowUpOutlined style={{ marginLeft: 2 }} />
                    <Slider
                        min={rotateZ.minValue}
                        max={rotateZ.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.rotateZ)}
                        style={{ height: 230, marginLeft: 4 }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                        vertical
                    />
                </div>

                {/* Vertical Slider for moveX */}
                <div style={{ display: 'flex', position: 'absolute', right: 0, bottom: 10, width: 25, flexDirection: 'column', justifyContent: 'space-between', height: 260 }}>
                    <ArrowUpOutlined style={{ marginLeft: 2 }} />
                    <Slider
                        min={rotateY.minValue}
                        max={rotateY.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.rotateY)}
                        style={{ height: 230, marginLeft: 4 }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                        vertical
                    />
                </div>

                {/* Horizontal Slider for moveZ */}
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 2, bottom: 0, width: '100%', height: 20 }}>
                    <ArrowLeftOutlined />
                    <Slider
                        min={rotateX.minValue}
                        max={rotateX.maxValue}
                        defaultValue={0}
                        onChange={onChange(JoystickSliderOptions.rotateX)}
                        style={{ width: '80%' }}
                        styles={{ track: { backgroundColor: 'transparent' } }}
                    />
                </div>
            </div>
        </div>
    );
};


// import React from 'react';
// import { Slider } from 'antd';
// import { IUIElementPropsDict, UIElementType } from '../Managers/UIManager';
// import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

// export interface IJoystickUI extends IUIElementPropsDict {
//     minValue: number;
//     maxValue: number;
//     joystickTransformation: string;
// }

// // SliderView component
// const SliderView: React.FC<{ uiObject: IJoystickUI; minIcon?: React.ReactNode; maxIcon?: React.ReactNode; orientation?: 'horizontal' | 'vertical' }> = ({ uiObject, minIcon, maxIcon, orientation = 'horizontal' }) => {
//     return (
//         <div style={{ display: 'flex', alignItems: 'center', padding: '10px', flexDirection: orientation === 'vertical' ? 'column' : 'row' }}>
//             {minIcon && (
//                 <div style={{ marginRight: '10px' }}>{minIcon}</div>
//             )}
//             <Slider
//                 min={uiObject.minValue}
//                 max={uiObject.maxValue}
//                 defaultValue={0}
//                 style={{ width: orientation === 'vertical' ? '30px' : '200px', height: orientation === 'vertical' ? '200px' : 'auto' }}
//                 vertical={orientation === 'vertical'}
//             />
//             {maxIcon && (
//                 <div style={{ marginLeft: '10px' }}>{maxIcon}</div>
//             )}
//         </div>
//     );
// };

// // JoystickUI component
// const JoystickUI: React.FC<{
//     rotateY: IJoystickUI;
//     moveX: IJoystickUI;
//     moveY: IJoystickUI;
//     moveZ: IJoystickUI;
// }> = ({ rotateY, moveX, moveY, moveZ }) => {
//     return (
//         <div style={{ position: 'relative', height: '100vh', width: '100vw', padding: '20px' }}>
//             {/* RotateY slider on the right side, vertical */}
//             <div style={{ position: 'absolute', right: '100px', top: '50%', transform: 'translateY(-50%)' }}>
//                 <SliderView uiObject={rotateY} minIcon={<ArrowUpOutlined />} maxIcon={<ArrowDownOutlined />} orientation="vertical" />
//             </div>

//             {/* MoveX slider at the bottom left, horizontal */}
//             <div style={{ position: 'absolute', bottom: '70px', left: '20px' }}>
//                 <SliderView uiObject={moveX} minIcon={<ArrowLeftOutlined />} maxIcon={<ArrowRightOutlined />} orientation="horizontal" />
//             </div>

//             {/* MoveY slider at the bottom left, vertical */}
//             <div style={{ position: 'absolute', bottom: '140px', left: '30px', height: '200px' }}>
//                 <SliderView uiObject={moveY} minIcon={<ArrowDownOutlined />} maxIcon={<ArrowUpOutlined />} orientation="vertical" />
//             </div>

//             {/* MoveZ slider at a 45-degree angle */}
//             <div style={{ position: 'absolute', bottom: '150px', left: '0px', transform: 'rotate(-45deg)' }}>
//                 <SliderView uiObject={moveZ} minIcon={<ArrowLeftOutlined />} maxIcon={<ArrowRightOutlined />} orientation="horizontal" />
//             </div>
//         </div>
//     );
// };

// // ORB6DOFController component
// const ORB6DOFController: React.FC = () => {
//     const imageSize = 30;
//     const locX = 100;
//     const locYStart = 100;
//     const locYDelta = 30;

//     const rotateY: IJoystickUI = {
//         type: UIElementType.joystick,
//         tag: "rotateY",
//         locX: locX,
//         locY: locYStart,
//         height: imageSize,
//         width: 0.8,
//         minValue: -90,
//         maxValue: 90,
//         joystickTransformation: "exponential"
//     };

//     const moveX: IJoystickUI = {
//         type: UIElementType.joystick,
//         tag: "moveX",
//         locX: locX,
//         locY: locYStart + locYDelta,
//         height: imageSize,
//         width: 0.8,
//         minValue: -100,
//         maxValue: 100,
//         joystickTransformation: "linear"
//     };

//     const moveY: IJoystickUI = {
//         type: UIElementType.joystick,
//         tag: "moveY",
//         locX: locX,
//         locY: locYStart + 2 * locYDelta,
//         height: imageSize,
//         width: 0.8,
//         minValue: -100,
//         maxValue: 100,
//         joystickTransformation: "linear"
//     };

//     const moveZ: IJoystickUI = {
//         type: UIElementType.joystick,
//         tag: "moveZ",
//         locX: locX,
//         locY: locYStart + 3 * locYDelta,
//         height: imageSize,
//         width: 0.6,
//         minValue: -100,
//         maxValue: 100,
//         joystickTransformation: "linear"
//     };

//     return (
//         <JoystickUI
//             rotateY={rotateY}
//             moveX={moveX}
//             moveY={moveY}
//             moveZ={moveZ}
//         />
//     );
// };

// export default ORB6DOFController;
