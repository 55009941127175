// styled.ts
import styled from "styled-components";

export const FormContainer = styled.div`
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  padding-bottom: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;
