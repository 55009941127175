import React, { useEffect, useMemo } from 'react';
import { useState } from "react";
import "./styles.css"

import { FlexContainer, Title, InnerContainer, ImageWrapper, ListItem, ListItemName, DoneButton } from "./styled";
import { Button } from 'antd';
import { ShootersListViewTheme } from '../BaselBurgerShootersInput';

export class BaselBurgerListItem {
    id: string
    name: string
    image: string

    constructor(name: string, image: string) {
        this.name = name
        this.image = image
    }
}

// export class BaselBurgerShootersListViewTheme {
//     backgroundImageUrl: string

//     constructor(inputParams: Record<string, any>) {
//         Object.assign(this, inputParams);
//     }
// }

interface BaselBurgerSelectionScreenProps {
    list: Array<BaselBurgerListItem>,
    theme: ShootersListViewTheme,
    onItemClick: (id: string | number, index: number) => void
}

export const BaselBurgerSelectionScreen: React.FC<BaselBurgerSelectionScreenProps> = ({ list, theme, onItemClick }) => {
    const [selectedItem, setSelectedItem] = useState<{ id: string | number, index: number } | null>(null);

    const handleItemClick = (id: string | number, index: number) => {
        setSelectedItem({ id, index });
    };

    const handleDoneClick = () => {
        if (selectedItem) {
            onItemClick(selectedItem.id, selectedItem.index);
            setSelectedItem(null);
        }
    };

    useEffect(() => {
        buildImagesRows()
    }, [list, selectedItem])

    const buildImagesRows = () => {
        var container = document.getElementById("container");
        container.innerHTML = ""
        for (let i = 0; i < list.length; i += 3) {
            const row = document.createElement("div");
            row.className = "row";
            container.appendChild(row);
            let indexes = [1, 0, 2]
            for (let index of indexes) {
                if (i + index < list.length) {
                    const img = document.createElement("img");
                    img.setAttribute("key", `${i + index}`);
                    img.addEventListener("click", () => handleItemClick(i + index, i + index));
                    img.id = String(index + i);
                    if (selectedItem && selectedItem.index === i + index) {
                        img.style.border = '2px solid aqua'
                    }
                    row.appendChild(img);
                    const first: any = document.getElementById(`${i + index}`);
                    const second: any = document.getElementById(`${i + index}`);
                    const third: any = document.getElementById(`${i + index}`);
                    second.src = list[i + index].image;
                    first.src = list[i + index].image;
                    third.src = list[i + index].image;
                }
            }
        }
    }


    return (
        <FlexContainer backgroundImage={theme.backgroundImageUrl}>
            <div>
                {theme.title && <Title>{theme.title}</Title>}
            </div>
            <div className="wrapper" >
                <div id='container' style={{ height: '90%', overflowY: 'auto' }}></div>
                <DoneButton onClick={handleDoneClick} disabled={!selectedItem}>Done</DoneButton>
            </div>
        </FlexContainer>
    )
};