import Lottie from 'lottie-react';
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons'
import { useEffect, useState, useContext } from "react";
import { LottieNames } from "..";
import { AppStore } from "../../../app-store/store";
import { Mixpanel } from '../../../Managers/AnalyticsClient/AnalyticsClient';
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from '../../../Managers/AnalyticsClient/AnalyticsKeys';


export const ErrorPage = () => {
    const [animationData, setAnimationData] = useState<any | null>(null);
    const { errorType, errorMessage } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.ErrorScreen, {
            [AnalyticsEventPropertyKeys.ErrorMessage]: errorMessage
        })
    }, [])

    useEffect(() => {
        fetchLottieData()
    }, [errorType])

    const fetchLottieData = async () => {
        try {
            const response = await fetch(`/assets/Lottie/${LottieNames.failure}.json`);
            if (!response.ok) {
                console.log("response", response)
            }
            const data = await response.json();
            setAnimationData(data);
        } catch (error) {
            console.error('Loading page - Error fetching data:', error);
        }
    };

    return (
        <div>
            {
                animationData !== null &&
                <div id="lottie-container">
                    <Lottie animationData={animationData} />
                </div>
            }
            <h1>{errorType}</h1>
            <h2>{errorMessage}</h2>
        </div>
    )
}