import * as THREE from 'three';

export class ExperienceARAnalytics {
    objectAnalytics: Record<string, ObjectARAnalytics>
    userAnalytics: UserARAnalytics

    constructor(objectAnalytics: Record<string, ObjectARAnalytics>,
        userAnalytics: UserARAnalytics) {
        this.objectAnalytics = objectAnalytics
        this.userAnalytics = userAnalytics
    }

    toDictionary(): Record<string, any> {
        var result: Record<string, any> = {}
        for (const key in this.objectAnalytics) {
            if (Object.prototype.hasOwnProperty.call(this.objectAnalytics, key)) {
                result[key] = this.objectAnalytics[key].toDictionary();
            }
        }

        return {
            objectAnalytics: result,
            userAnalytics: this.userAnalytics.toDictionary()
        };
    }
}

export class ARDataTuple {
    time: number;
    value: number;

    constructor(time: number, value: number) {
        this.time = time;
        this.value = value;
    }

    toDictionary() {
        return {
            "time": this.time,
            "value": this.value
        }
    }
}

export class ObjectARAnalytics {
    object: THREE.Object3D
    numFramesOnScreen: number
    numTaps: number
    numLongTaps: number
    numReaches: number
    distOverTime: ARDataTuple[]
    scalings: ARDataTuple[]
    rotations: ARDataTuple[]
    // translations: ARDataTupleClass[]

    constructor(object: THREE.Object3D) {
        this.object = object;
        this.numFramesOnScreen = 0;
        this.numTaps = 0;
        this.numLongTaps = 0;
        this.numReaches = 0;
        this.distOverTime = []
        this.scalings = []
        this.rotations = []
    }

    toDictionary(): Record<string, any> {
        return {
            objectID: this.object.id,
            objectName: this.object.name,
            objectMetadata: this.object.userData.objectMetadata ?? {},
            numFramesOnScreen: Math.round(this.numFramesOnScreen/30),
            numTaps: this.numTaps,
            numLongTaps: this.numLongTaps,
            numReaches: this.numReaches,
            distOverTime: this.distOverTime,
            scalings: this.scalings,
            rotations: this.rotations
        };
    }
}

class UserARAnalytics {
    private distance: number
    private trajectory: THREE.Vector3[]
    private angles: THREE.Vector3[]
    private times: number[]
    private lastDistancePos: THREE.Vector3

    constructor() {
        this.distance = 0;
        this.trajectory = [new THREE.Vector3(0,0,0)];
        this.angles = [new THREE.Vector3(0,0,0)];
        this.times = [Date.now()];
        this.lastDistancePos = new THREE.Vector3(0,0,0)
    }

    addNewPose(position: THREE.Vector3, angles: THREE.Vector3) {
        const dist = position.clone().sub(this.lastDistancePos).length()
        if (dist > 0.1) {
            this.distance += dist
            this.lastDistancePos = position
        }

        const currTime = Date.now()
        if (this.times.length == 0 || (currTime - this.times[this.times.length-1]) > 1000) {
            this.trajectory.push(position)
            this.angles.push(angles)
            this.times.push(currTime)
        }
    }

    toDictionary(): Record<string, any> {
        return {
            distance: this.distance,
            trajectory: this.trajectory.map(v => [v.x, v.y, v.z]),
            angles: this.angles.map(v => [v.x, v.y, v.z]),
            times: this.times
        };
    }
}

export class ARAnalyticsManager {
    userAnalytics: UserARAnalytics;
    objectAnalytics: Record<string, ObjectARAnalytics>;
    lastUserPosition: THREE.Vector3;
    userPositionFunc: (() => THREE.Vector3) | null;
    userAnglesFunc: (() => THREE.Vector3) | null;

    // lastTimestamp: number | null = null
    lastTimestampPerObject: Record<string, number>;

    camera: THREE.PerspectiveCamera | null

    constructor(camera: THREE.PerspectiveCamera | null = null) {
        this.userAnalytics = new UserARAnalytics();
        this.objectAnalytics = {};

        this.lastUserPosition = new THREE.Vector3(0, 0, 0);
        this.userPositionFunc = null;
        this.lastTimestampPerObject = {}

        this.camera = camera
    }

    cleanup() {

    }

    tick(frame: XRFrame, time: number) {
        this.updateUserPositionAndNodesReached()
        // this.updateObjectsAnalytics(time)
        this.updateObjectsAnalytics(Date.now())
        this.updateNumFramesOnScreen()
    }

    getAnalytics(): ExperienceARAnalytics | null {
        if (Object.keys(this.objectAnalytics).length === 0) { return null }
        return new ExperienceARAnalytics(this.objectAnalytics, this.userAnalytics)
    }

    ///////////// callback functions
    userPosition(callback: (() => THREE.Vector3)) {
        this.userPositionFunc = callback
    }

    userAngles(callback: (() => THREE.Vector3)) {
        this.userAnglesFunc = callback
    }

    //////////// update functions
    objectAddedToScene(object: THREE.Object3D) {
        if (!(object.userData.settings && object.userData.settings.addToAnalytics)) {
            return
        }
        if (this.isTrackedObject(String(object.id))) {
            // console.log("ARAnalyticsManager - Object is already tracked...", object)
        } else {
            this.objectAnalytics[String(object.id)] = new ObjectARAnalytics(object)
        }

        // Add callbacks to the object that counts the number of times it was rendered
        this.addFrameCountCallback(object, String(object.id))
    }

    // Add the callback to a renderable object (THREE.Mesh) only. The function updateNumFramesOnScreen handles non-Mesh objects
    addFrameCountCallback(object: THREE.Object3D, objectID: string) {
        // console.log("ADDING FRAME COUNT CALLBACK", object, object instanceof THREE.Object3D, object instanceof THREE.Mesh)
        if (object instanceof THREE.Mesh) {
            object.onAfterRender = () => {
                this.objectAnalytics[objectID].numFramesOnScreen += 1
            }
        } 
    }

    // Update the frame count for all non-Mesh objects!
    updateNumFramesOnScreen() {
        for (const objectID in this.objectAnalytics) {
            const object = this.objectAnalytics[objectID].object
            if (!(object instanceof THREE.Mesh)) {
                if (this.isObjectInFrustum(object)) {
                    this.objectAnalytics[objectID].numFramesOnScreen += 1
                }
            }
        }
    }

    isObjectInFrustum(object: THREE.Object3D): boolean {
        if (!this.camera) return false;

        const frustum = new THREE.Frustum();
        const cameraViewProjectionMatrix = new THREE.Matrix4();

        // Update frustum with camera's view-projection matrix
        this.camera.updateMatrixWorld();
        // this.camera.matrixWorldInverse.getInverse(this.camera.matrixWorld);
        cameraViewProjectionMatrix.multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse);
        frustum.setFromProjectionMatrix(cameraViewProjectionMatrix);

        // Assuming object3D is your THREE.Object3D instance
        const objectPosition = new THREE.Vector3();
        object.getWorldPosition(objectPosition);

        // Check if object's position is inside the camera's frustum
        const isInsideFrustum = frustum.containsPoint(objectPosition);

        return isInsideFrustum
    }

    objectTapped(object: THREE.Object3D) {
        const objectID = String(object.id)
        if (!this.isTrackedObject(objectID) || !this.objectAnalytics[objectID]) { return }
        this.objectAnalytics[objectID]!.numTaps += 1
    }

    planeTapped(pose :XRPose) {

    }
    
    objectScaled(object: THREE.Object3D, scale: number) {
        const objectID = String(object.id)
        if (!this.isTrackedObject(objectID)) { return }
        const tup = new ARDataTuple(Date.now(), scale)
        this.objectAnalytics[objectID]?.scalings.push(tup)
    }

    objectRotated(object: THREE.Object3D, angle: number) {
        const objectID = String(object.id)
        if (!this.isTrackedObject(objectID)) { return }
        const tup = new ARDataTuple(Date.now(), angle)
        this.objectAnalytics[objectID]?.rotations.push(tup)
    }

    updateUserPositionAndNodesReached() {
        if (this.userPositionFunc == null || this.userAnglesFunc == null) return null;

        const currUserPosition = this.userPositionFunc()
        const currUserAngles = this.userAnglesFunc()
        if (currUserPosition && currUserAngles) {
            this.userAnalytics.addNewPose(currUserPosition, currUserAngles)

            if (currUserPosition.clone().sub(this.lastUserPosition).length() > 0.1) {
                // In this case, update all relevant parameters of the tracked nodes
                this.updateNodesReached(this.lastUserPosition.clone(), currUserPosition.clone())
                this.lastUserPosition = currUserPosition
            }
        }
    }

    updateNodesReached(lastUserPos: THREE.Vector3, newUserPos: THREE.Vector3) {
        for (let id in this.objectAnalytics) {
            if (this.checkIfUserReachedObject(this.objectAnalytics[id].object, lastUserPos, newUserPos)) {
                this.objectAnalytics[id].numReaches += 1
                // console.log("OBJECT REACHED!!", this.objectAnalytics[id].object)
            }
        }
    }

    updateDistToObjects(objectID: string, timestamp: number) {
        if (!this.isTrackedObject(objectID)) { return }
        // If not enough time has ellapsed since the last update, do nothing
        const objectLastTimestamp = this.lastTimestampPerObject[objectID]
        if (objectLastTimestamp && (timestamp - objectLastTimestamp) < 1000.0) { return }
        if (this.userPositionFunc == null) return null;
        
        const userPos = this.userPositionFunc()
        const object = this.objectAnalytics[objectID].object
        if (!object) { return }

        this.lastTimestampPerObject[objectID] = Date.now()
        const distToObject = (object.position.clone().sub(userPos).length())
        const tup = new ARDataTuple(Math.floor(Date.now()), distToObject)
        this.objectAnalytics[objectID]?.distOverTime.push(tup)
    }

    updateObjectsAnalytics(timestamp: number) {
        for (let objectID in this.objectAnalytics) {
            this.updateDistToObjects(objectID, timestamp)
        }
    }

    /////////// helper functions
    isTrackedObject(objectID: string) {
        return (objectID in this.objectAnalytics) ? true : false
    }

    // Check if the line between lastUSerPos and newUserPos passes through/near the object
    checkIfUserReachedObject(object: THREE.Object3D, lastUserPos: THREE.Vector3, newUserPos: THREE.Vector3) {

        const lineTriangle = new THREE.Triangle(lastUserPos, newUserPos, newUserPos)
        const objectBB = new THREE.Box3().setFromObject(object);
        const intersects = (objectBB.intersectsTriangle(lineTriangle))
        return intersects ? true : false

        // const line = new THREE.Line3(lastUserPos,newUserPos);
        // var closestLinePoint = new THREE.Vector3();
        // line.closestPointToPoint(object.position, true, closestLinePoint)
        // const distToObject = closestLinePoint.distanceTo(object.position);
        // // console.log("Distance to object: ", distToObject)
        // return (distToObject < 0.08) ? true : false

        // const direction = newUserPos.sub(lastUserPos).normalize()
        // var ray = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3()); 
        // ray.setFromCamera(new THREE.Vector2(0, 0), this.camera)
        // // var ray = new THREE.Raycaster(lastUserPos, direction); 
        // var rayIntersects = ray.intersectObjects(object, true); 
        // console.log("Object pos: ", object.position)
        // console.log("ray: ", ray)
        // if (rayIntersects.length > 0) {
        //     console.log("DISTANCE TO INTERSECTED OBJECT: ", rayIntersects[0].distance)
        // }

        // return (rayIntersects.length > 0) ? true : false
    }
}