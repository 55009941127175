export const GridMetaTemplateDemoExperience = `
{
    "metadata":
    {
        "experienceID": "gridMetaTemplate1234",
        "experienceType": "GridTemplate"
    },
    "theme":{
        "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgermenugame%2FImages%2FBaselBurgerLogo.png?alt=media&token=32ea38d0-32bc-41cb-97b2-e4ba86d13539",
        "primaryColor":[
         100,
         200,
         35
        ],
        "secondaryColor":[
           0,
           0,
           0
        ],
        "plantLogoOnSurfaces":false
     },
    "pageRoutingConfig":
    [
        {
            "pageName": "game",
            "pageParamsName": "experience"
        }
    ],
    "experience":
    {
        
    }
}
`