import * as CANNON from 'cannon-es';
import * as THREE from 'three';
import { TriggerManager } from '../TriggerAction/TriggerManager';
// import { threeToCannon, ShapeType } from 'three-to-cannon';

export class PhysicsManager {
    private physicsWorld: CANNON.World;
    private physicsWorldRemovalQueue: CANNON.Body[];    // A queue for storing the objects to be removed from the physics world.
    private physicsObjects: Map<number, CANNON.Body>;
    private objects: Map<number, THREE.Object3D>;
    private clock: THREE.Clock;
    private gravityVec: THREE.Vector3;

    private triggerManager: TriggerManager;

    constructor(triggerManager: TriggerManager, gravityVec: THREE.Vector3 | null = null) {
        // this.physicsWorld = new CANNON.World({gravity: new CANNON.Vec3(0, -9.81, 0)});
        this.physicsWorld = new CANNON.World({gravity: new CANNON.Vec3(0, 0, 0)});
        this.physicsWorldRemovalQueue = [];
        // this.physicsWorld.addEventListener('collide', this.handleCollisions);
        this.physicsObjects = new Map<number, CANNON.Body>();
        this.objects = new Map<number, THREE.Object3D>();
        this.clock = new THREE.Clock()
        this.gravityVec = gravityVec ?? new THREE.Vector3(0,-0.5,0)

        this.triggerManager = triggerManager
    }

    tick() {
        const deltaTime = this.clock.getDelta();
        this.physicsWorld.step(deltaTime);
        while (this.physicsWorldRemovalQueue.length > 0) {
            const body = this.physicsWorldRemovalQueue.pop()
            this.physicsWorld.removeBody(body)
        }
        this.applyPhysicsToAllObjects()
    }

    cleanup() {
        
    }

    applyPhysicsToAllObjects() {
        for (let object of this.objects.values()) {
            if (object.userData.settings && object.userData.settings.isAffectedByGravity) {
                this.applyPhysicsToObject(object)
            }
        }
    }

    applyPhysicsToObject(object: THREE.Object3D) {
        if (object.userData.settings && object.userData.settings.isAffectedByGravity) {
            const objectPhysicsBody = this.getARObjectPhysicsBody(object)
            if (objectPhysicsBody)
            {
                const position = objectPhysicsBody.position;
                const quaternion = objectPhysicsBody.quaternion;
    
                object.position.set(position.x, position.y, position.z);
                // This is commented out so that the three.object maintains its orientation towards the camera
                // object.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w); 
            }
            // const forceVec3 = new CANNON.Vec3(this.gravityVec.x, this.gravityVec.y, this.gravityVec.z);
            // objectPhysicsBody.applyForce(forceVec3);
        }
    }

    addARObjectToPhysicsWorld(object: THREE.Object3D | THREE.Mesh, mass: number = 1) {
        // console.log("Adding object to physics world", object)

        let isKinematicShape = false
        if ("settings" in object.userData) {
            isKinematicShape = object.userData.settings.physicsShapeType != 2 // only if physicsShapeType is kinematic (2) then set collision response to true
            // TODO: Ibrahim - add collisionResponse false to objects that we dont want to move at all.
        }
        
        const cannonBody = new CANNON.Body({ mass: mass, collisionResponse: isKinematicShape });
        
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(object);

        const size = new THREE.Vector3();
        boundingBox.getSize(size);
        const cannonBoxShape = new CANNON.Box(new CANNON.Vec3(size.x / 2, size.y / 2, size.z / 2));
        // const cannonBoxShape = new CANNON.Box(new CANNON.Vec3(Math.max(size.x / 2, 0.01), Math.max(size.y / 2, 0.01), Math.max(size.z / 2, 0.01)));
        // const cannonBoxShape = new CANNON.Box(new CANNON.Vec3(0.1, 0.1, 0.1));
        
        cannonBody.addShape(cannonBoxShape);        
        // }
        const position = new CANNON.Vec3(object.position.x, object.position.y, object.position.z)
        cannonBody.position.copy(position);
        
        // Step 2: Set the Orientation (Rotation)
        const quaternion = new CANNON.Quaternion();
        const euler = new THREE.Euler();
        euler.copy(object.rotation);
        quaternion.setFromEuler(euler.x, euler.y, euler.z);
        cannonBody.quaternion.copy(quaternion);

        cannonBody.addEventListener('collide', this.handleCollisions.bind(this));
        cannonBody.id = object.id
        this.physicsWorld?.addBody(cannonBody)
        this.physicsObjects.set(object.id, cannonBody);
        this.objects.set(object.id, object);
        // console.log(`PhysicsManager = Added object id: ${object.id}, cannonbody id: ${cannonBody.id}`)
    }

    removeARObjectFromPhysicsWorld(object: THREE.Object3D) {
        const cannonBody = this.physicsObjects.get(object.id);
        const storedObject = this.objects.get(object.id);

        if (cannonBody) {
            // Remove the body from the Cannon.js world
            // this.physicsWorld.removeBody(cannonBody);
            this.physicsWorldRemovalQueue.push(cannonBody)  // Instead of removing the object immidiately, add it to queue and remove them after the world.step()
            // Delete the entry from the bodyMap
            this.physicsObjects.delete(object.id);
        }

        if (storedObject) {
            this.objects.delete(object.id);
        }
    }

    handleCollisions(event) {
        // const contact: CANNON.ContactEquation = event.contact;
        const bodyA: CANNON.Body = event.body;
        const bodyB: CANNON.Body = event.target;

        const objectA = this.objects.get(bodyA.id);
        const objectB = this.objects.get(bodyB.id);

        if (objectA && objectB)
            this.triggerManager.collision(objectA, objectB);
    
        // Handle the collision as needed
        // console.log(`Collision between body ${bodyA.id} and body ${bodyB.id}`);
    }
    
    getARObjectPhysicsBody(object: THREE.Object3D) {
        const physicsObject = this.physicsObjects.get(object.id)
        return physicsObject
    }

    ARObjectMovedManually(object: THREE.Object3D) {
        const objectPhysicsBody = this.getARObjectPhysicsBody(object)
        if (objectPhysicsBody) {
            // console.log("PHYSICS MANAGER: CHANING CANNON BODY POSITION", objectPhysicsBody.position, object.position)
            objectPhysicsBody.position.set(object.position.x, object.position.y, object.position.z)
        }
    }

    ARObjectRotatedManually(object: THREE.Object3D) {
        const objectPhysicsBody = this.getARObjectPhysicsBody(object)
        if (objectPhysicsBody) {
            objectPhysicsBody.quaternion.set(object.quaternion.x, object.quaternion.y, object.quaternion.z, object.quaternion.w)
        }
    }

    ARObjectScaledManually(object: THREE.Object3D) {
        const objectPhysicsBody = this.getARObjectPhysicsBody(object)
        if (objectPhysicsBody && objectPhysicsBody.shapes[0]) {
            // TODO: complete scaling of cannon body
        }
    }

    applyVelocityToObject(object: THREE.Object3D, velocity: THREE.Vector3) {
        const objectPhysicsBody = this.getARObjectPhysicsBody(object)
        if (objectPhysicsBody) {
            const initialVelocity = new CANNON.Vec3(velocity.x, velocity.y, velocity.z); // Adjust the velocity as needed
            objectPhysicsBody.velocity.copy(initialVelocity);
        }
    }

    applyForceToObject(object: THREE.Object3D, force: THREE.Vector3) {
        const objectPhysicsBody = this.getARObjectPhysicsBody(object)
        if (objectPhysicsBody) {
            const forceVec3 = new CANNON.Vec3(force.x, force.y, force.z); // Adjust the velocity as needed
            objectPhysicsBody.applyForce(forceVec3);
            console.log("APPLYING FORCE", forceVec3)
        }
    }
}