export const PhotoAlbumDemoExperience = `
{
   "metadata":{
      "experienceID":"photoAlbum1234",
      "experienceType":"PhotoAlbum"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor":[
         255,
         255,
         255
      ],
      "secondaryColor":[
         92,
         92,
         92
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"game",
         "pageParamsName":"experience"
      }
   ],
   "experience":{
      "input":{
         "photoAlbumType":"Tour",
         "images":[
            {
               "image":{
                  "fileName":"img1.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FTemplateCardImages%2FBaselBurgerShooters.png?alt=media&token=39674422-aefb-4228-a473-f3f8d904a432"
               },
               "height":1
            },
            {
               "image":{
                  "fileName":"BlackAquaOrbLogo.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FBlackAquaOrbLogo.png?alt=media&token=10cc96b3-affd-4663-86b7-9bb3755dbc72"
               },
               "height":0.5
            },
            {
               "image":{
                  "fileName":"BlackAquaOrbLogo.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FBlackAquaOrbLogo.png?alt=media&token=10cc96b3-affd-4663-86b7-9bb3755dbc72"
               },
               "height":0.5
            },
            {
               "image":{
                  "fileName":"BlackAquaOrbLogo.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FBlackAquaOrbLogo.png?alt=media&token=10cc96b3-affd-4663-86b7-9bb3755dbc72"
               },
               "height":0.5
            },
            {
               "image":{
                  "fileName":"instructions.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FTemplateInstructions%2Fcatalogue-1.png?alt=media&token=773bb73b-e0bc-42d6-a939-c7144d466d1c"
               },
               "height":0.5
            },
            {
               "image":{
                  "fileName":"img2.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FTemplateCardImages%2FBuildYourOwn.png?alt=media&token=a699454b-2fbb-4b10-9804-44939e4f11d4"
               },
               "height":0.5
            },
            {
               "image":{
                  "fileName":"img3.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FTemplateCardImages%2FDoubleShooters.png?alt=media&token=98474b46-4273-41f0-86ea-4cfb3c1e634a"
               },
               "height":0.5
            }
         ]
      },
      "auxData":{
         "backgroundExperienceJSON":{
            "objects":[
               
            ],
            "generalRules":[
               
            ],
            "UIObjects":[
               
            ]
         }
      }
   }
}
`