import { ExperienceMetadata } from "../ExperienceObjects/ExperienceObject";
import { SessionStatus } from "../components/pages";

export enum UserAgent {
    iOS = 0,
    Android = 1,
}

class ExperienceAnalytics {
    experienceStartTime: number = -1;
    experienceEndTime: number = -1;
    sessionStatus: SessionStatus = SessionStatus.ExperienceNotCompleted;
    userAgent: UserAgent = UserAgent.Android;
    dwellTime: number = 0;

    finalize() {
        this.computeDwellTime()
        this.dwellTime = (this.experienceEndTime - this.experienceStartTime)/1000
    }

    computeDwellTime() {
        this.experienceEndTime = performance.now()
    }

    toDictionary(): Record<string, any> {
        return {
            sessionStatus: this.sessionStatus,
            userAgent: this.userAgent as number,
            dwellTime: this.dwellTime
        };
    }
}

export class ExperienceAnalyticsManager {
    private metadata: ExperienceMetadata;
    private userID: string;
    private analyticsDict: Record<string, any> = {};
    private experienceAnalytics: ExperienceAnalytics = new ExperienceAnalytics();

    constructor(metadata: ExperienceMetadata, userID: string) {
        this.metadata = metadata;
        this.userID = userID;

        const metadataDict = this.metadata.toDictionary();
        if (metadataDict) {
            metadataDict['userID'] = this.userID;
            this.analyticsDict['metadata'] = metadataDict;
        }
    }

    setExperienceStartTime(): void {
        this.experienceAnalytics.experienceStartTime = performance.now()
    }

    integrateAdditionalAnalytics(key: string, analytics: Record<string, any>): void {
        this.appendDictionariesToAnalytics(analytics, key);
    }

    changeSessionStatus(status: SessionStatus): void {
        this.experienceAnalytics.sessionStatus = status;
    }

    getAnalyticsDict(): Record<string, any> {
        this.experienceAnalytics.finalize();

        const experienceAnalyticsDict = this.experienceAnalytics.toDictionary();
        if (experienceAnalyticsDict) {
            this.appendDictionariesToAnalytics(experienceAnalyticsDict, 'experienceAnalytics');
        }

        return this.analyticsDict;
    }

    private appendDictionariesToAnalytics(dictionaryToAppend: Record<string, any>, key: string): void {
        const existingData = this.analyticsDict[key] || {};
        
        for (const [newKey, value] of Object.entries(dictionaryToAppend)) {
            if (!(newKey in existingData)) {
                existingData[newKey] = value;
            }
        }

        this.analyticsDict[key] = existingData;
    }
}