import { useState } from "react";
import { MenuItem } from "../MenuTemplateInput";
import { FlexContainer, ImageWrapper, InnerContainer, ListItem, ListItemName, Title } from "./styled";

export enum ComponentOptions {
    add = 0,
    remove = 1,
    present = 2,
    hide = 3
}

export class MenuListViewTheme {
    backgroundImageUrl: string

    constructor(inputParams: Record<string, any>) {
        Object.assign(this, inputParams);
    }
}

interface MenuListItemProps {
    menuList: Array<MenuItem>,
    menuTheme: MenuListViewTheme,
    onItemClick: (id: string | number, index: number) => void
}

export const MenuListView: React.FC<MenuListItemProps> = ({ menuList, menuTheme, onItemClick }) => {
    const [selectedItem, setSelectedItem] = useState<{ id: string, index: number } | null>(null);

    const handleItemClick = (id: string, index: number) => {
        setSelectedItem({ id, index });
    };

    return (
        <FlexContainer backgroundImage={menuTheme.backgroundImageUrl}>
            <Title>Menu</Title>
            <InnerContainer>
                {
                    menuList.length && menuList.map((item, index) => (
                        <ListItem onClick={() => onItemClick(item.objectID, index)} key={item.objectID} isSelected={selectedItem && selectedItem.index === index}>
                            <ImageWrapper imageUrl={item.objectImage.fileUrl} />
                            <ListItemName>{item.objectName}</ListItemName>
                        </ListItem>
                    ))
                }
            </InnerContainer>
        </FlexContainer>
    )
}