const testJsonStr = `{
  "metadata":
  {
      "experienceID": "12345",
      "experienceType": "custom"
  },
  "theme":
  {
      "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor": [255, 255, 255],
      "secondaryColor": [92, 92, 92],
      "plantLogoOnSurfaces": false
  },
  "experience":
  {
      "objects": 
      [
        {
          "id": "1111",
          "name": "targetObject",
          "maxExecutions": 20,
          "object": {
            "fileName": "orbarlogo.png",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "settings": {
              "allowUserGestures": true,
              "size": 0.1,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": false
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onButtonTap",
                "id": "shootButton"
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "infront"
                }
              ]
            }
          ]
        }
      ],
      "generalRules": [],
      "UIObjects": [
          {
              "tag": "image1",
              "type": "image",
              "locX": 0.5,
              "locY": 0.5,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
          },
          {
              "tag": "shootButton",
              "type": "button",
              "locX": 0.5,
              "locY": 0.9,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
          },
		  {
			"tag": "stats123",
			"type": "stats",
			"locX": 0.5,
			"locY": 0.1,
			"height": 100,
			"width": 100,
			"useTimer": true,
			"useScore": true,
			"useLevels": false,
			"maxTime": 60,
			"maxScore": 20,
			"imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FBlu%2FBluLogo.png?alt=media&token=1ab996c8-1473-4bc6-a15b-364dacabc7d4"
		  }
      ]
  }
}`

export const testJsonStr2 = `{
  "metadata":
  {
      "experienceID": "12345",
      "experienceType": "custom"
  },
  "theme":
  {
      "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor": [255, 255, 255],
      "secondaryColor": [92, 92, 92],
      "plantLogoOnSurfaces": false
  },
  "experience":
  {
      "objects": 
      [
        {
          "id": "1111",
          "name": "targetObject",
          "maxExecutions": 20,
          "object": {
            "fileName": "orbarlogo.png",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "settings": {
              "allowUserGestures": false,
              "size": 0.1,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": true
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onButtonTap",
                "id": "buttonLeft"
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "userPos"
                },
                {
                  "action": "shoot"
                }
              ]
            },
            {
              "trigger": {
                "trigger": "onCollideWith",
                "id2": "targetObject2"
              },
              "actions": [
                {
                  "action": "kill",
                  "delay": -1
                },
                {
                  "action": "playSound",
                  "soundtrackName": "BubblePop.mp3",
                  "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                }
              ]
            }
          ]
        },
        {
          "id": "2222",
          "name": "targetObject2",
          "object": {
            "fileName": "sunflower.gltf",
            "fileUrl": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
            "fileUrlAndroid": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
            "settings": {
              "allowUserGestures": false,
              "size": 0.1,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": false
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onRandDelta",
                "minDelta": 1,
                "maxDelta": 3
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "infront"
                },
                {
                    "action": "move",
                    "x": 0,
                    "y": 0.3,
                    "z": 0
                }
              ]
            },
            {
              "trigger": {
                "trigger": "onCollideWith",
                "id2": "targetObject"
              },
              "actions": [
                {
                  "action": "kill",
                  "delay": -1
                }
              ]
            }
          ]
        }
      ],
      "generalRules": [
          {
              "activity": {
              "trigger": {
                  "trigger": "onTimeEnd"
              },
              "actions": [
                  {
                  "action": "experienceFailed"
                  }
              ]
              }
          }
      ],
      "UIObjects": [
          {
              "tag": "image1",
              "type": "image",
              "locX": 0.5,
              "locY": 0.5,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
          },
          {
              "tag": "buttonLeft",
              "type": "button",
              "locX": 0.2,
              "locY": 0.9,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
          },
          {
            "tag": "buttonRight",
            "type": "button",
            "locX": 0.8,
            "locY": 0.9,
            "height": 70,
            "width": 70,
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
        }
      ]
  }
}`

const testJsonStr3 = `{
  "metadata":
  {
      "experienceID": "12345",
      "experienceType": "custom"
  },
  "theme":
  {
      "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor": [255, 255, 255],
      "secondaryColor": [92, 92, 92],
      "plantLogoOnSurfaces": false
  },
  "experience":
  {
      "objects": 
      [
        {
          "id": "1111",
          "name": "targetObject",
          "maxExecutions": 20,
          "object": {
            "fileName": "sunflower.gltf",
            "fileUrl": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
            "fileUrlAndroid": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
            "settings": {
              "allowUserGestures": true,
              "size": 0.5,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": false
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onButtonTap",
                "id": "buttonLeft"
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "infront"
                }
              ]
            }
          ]
        },
        {
          "id": "2222",
          "name": "targetObject2",
          "object": {
            "fileName": "orbarlogo.png",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "settings": {
              "allowUserGestures": true,
              "size": 0.2,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": false
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onButtonTap",
                "id": "buttonRight"
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "infront"
                },
                {
                    "action": "move",
                    "x": 0,
                    "y": 1.0,
                    "z": 0
                }
              ]
            }
          ]
        }
      ],
      "generalRules": [
          {
              "activity": {
              "trigger": {
                  "trigger": "onTimeEnd"
              },
              "actions": [
                  {
                  "action": "experienceFailed"
                  }
              ]
              }
          }
      ],
      "UIObjects": [
          {
              "tag": "image1",
              "type": "image",
              "locX": 0.5,
              "locY": 0.5,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
          },
          {
              "tag": "buttonLeft",
              "type": "button",
              "locX": 0.2,
              "locY": 0.9,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
          },
          {
            "tag": "buttonRight",
            "type": "button",
            "locX": 0.8,
            "locY": 0.9,
            "height": 70,
            "width": 70,
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
        }
      ]
  }
}`

const shootersExperience = `
{
  "metadata": {
    "experienceID": "BluDemoExperience",
    "experienceType": "custom"
  },
  "theme": {
    "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FBlu%2FIMG_0662.png?alt=media&token=527e0d9a-6f5c-4a47-a655-bad388edd544",
    "primaryColor": [
      255,
      255,
      255
    ],
    "secondaryColor": [
      92,
      92,
      92
    ],
    "plantLogoOnSurfaces": false
  },
  "experience": {
    "objects": [
      {
        "id": "OriginalBluCan",
        "name": "OriginalBluCanTarget",
        "object": {
          "fileName": "blucan3.glb",
          "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBlu%2FBluCanGLTF%2Fblucan3.glb?alt=media&token=057765f5-5d6f-4b09-94c2-c4643e18c664&_gl=1*1v7apjy*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY5NzAyODM3MC4yMy4xLjE2OTcwMzA4NjQuNTYuMC4w",
          "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBlu%2FBluCanGLTF%2Fblucan3.glb?alt=media&token=057765f5-5d6f-4b09-94c2-c4643e18c664&_gl=1*1v7apjy*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY5NzAyODM3MC4yMy4xLjE2OTcwMzA4NjQuNTYuMC4w",
          "settings": {
            "allowUserGestures": false,
            "size": 0.3,
            "timeOut": -1,
            "addPhysicsBody": true,
            "physicsShapeType": 2,
            "isAffectedByGravity": false
          }
        },
        "activities": [
          {
            "trigger": {
              "trigger": "onRandDelta",
              "minDelta": 3,
              "maxDelta": 5
            },
            "actions": [
              {
                "action": "bringToLife",
                "loc": "infront"
              },
              {
                "action": "kill",
                "delay": 5
              }
            ]
          },
          {
            "trigger": {
              "trigger": "onCollideWith",
              "id2": "bulletObjectLeft"
            },
            "actions": [
              {
                "action": "kill",
                "delay": 0
              },
              {
                "action": "playSound",
                "soundtrackName": "SodaCanOpen.mp3",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FSodaCanOpen.mp3?alt=media&token=b6f7677d-f426-4e43-98f4-30fe8cc40f00"
              }
            ]
          }
        ]
      },
      {
        "id": "2222",
        "name": "bulletObjectLeft",
        "object": {
          "fileName": "orbarlogo.png",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
          "settings": {
            "allowUserGestures": false,
            "size": 0.3,
            "timeOut": -1,
            "addPhysicsBody": true,
            "physicsShapeType": 2,
            "isAffectedByGravity": false
          }
        },
        "activities": [
          {
            "trigger": {
              "trigger": "onButtonTap",
              "id": "leftButton"
            },
            "actions": [
              {
                "action": "bringToLife",
                "loc": "userPos"
              },
              {
                "action": "shoot"
              },
              {
                "action": "kill",
                "delay": 5
              }
            ]
          },
          {
            "trigger": {
              "trigger": "onCollideWith",
              "id2": "OriginalBluCanTarget"
            },
            "actions": [
              {
                "action": "kill",
                "delay": 0
              }
            ]
          }
        ]
      }
    ],
    "generalRules": [
      
    ],
    "UIObjects": [
      {
        "tag": "leftButton",
        "type": "button",
        "locX": 0.2,
        "locY": 0.9,
        "height": 70,
        "width": 70,
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FBlu%2FIMG_0666.png?alt=media&token=85abd92e-0cf5-41c7-829c-99ea1c6f41b7"
      },
      {
        "tag": "image1",
        "type": "image",
        "locX": 0.5,
        "locY": 0.5,
        "height": 70,
        "width": 70,
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
      }
    ]
  }
}`

const shootersExperience2 = `{
  "metadata":
  {
      "experienceID": "12345",
      "experienceType": "custom"
  },
  "theme":
  {
      "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor": [255, 255, 255],
      "secondaryColor": [92, 92, 92],
      "plantLogoOnSurfaces": false
  },
  "experience":
  {
      "objects": 
      [
        {
          "id": "1111",
          "name": "targetObject",
          "maxExecutions": 20,
          "object": {
            "fileName": "orbarlogo.png",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
            "settings": {
              "allowUserGestures": false,
              "size": 0.1,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": true
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onButtonTap",
                "id": "buttonLeft"
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "userPos"
                },
                {
                  "action": "shoot"
                }
              ]
            },
            {
              "trigger": {
                "trigger": "onCollideWith",
                "id2": "targetObject2"
              },
              "actions": [
                {
                  "action": "kill",
                  "delay": -1
                },
                {
                  "action": "playSound",
                  "soundtrackName": "BubblePop.mp3",
                  "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                }
              ]
            }
          ]
        },
        {
          "id": "2222",
          "name": "targetObject2",
          "object": {
            "fileName": "blucan3.glb",
            "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBlu%2FBluCanGLTF%2Fblucan3.glb?alt=media&token=057765f5-5d6f-4b09-94c2-c4643e18c664&_gl=1*1v7apjy*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY5NzAyODM3MC4yMy4xLjE2OTcwMzA4NjQuNTYuMC4w",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBlu%2FBluCanGLTF%2Fblucan3.glb?alt=media&token=057765f5-5d6f-4b09-94c2-c4643e18c664&_gl=1*1v7apjy*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY5NzAyODM3MC4yMy4xLjE2OTcwMzA4NjQuNTYuMC4w",
            "settings": {
              "allowUserGestures": false,
              "size": 0.2,
              "timeOut": -1,
              "addPhysicsBody": true,
              "physicsShapeType": 2,
              "isAffectedByGravity": false
            }
          },
          "activities": [
            {
              "trigger": {
                "trigger": "onRandDelta",
                "minDelta": 1,
                "maxDelta": 3
              },
              "actions": [
                {
                  "action": "bringToLife",
                  "loc": "infront"
                },
                {
                    "action": "move",
                    "x": 0,
                    "y": 0.3,
                    "z": 0
                }
              ]
            },
            {
              "trigger": {
                "trigger": "onCollideWith",
                "id2": "targetObject"
              },
              "actions": [
                {
                  "action": "kill",
                  "delay": -1
                }
              ]
            }
          ]
        }
      ],
      "generalRules": [
          {
              "activity": {
              "trigger": {
                  "trigger": "onTimeEnd"
              },
              "actions": [
                  {
                  "action": "experienceFailed"
                  }
              ]
              }
          }
      ],
      "UIObjects": [
          {
              "tag": "image1",
              "type": "image",
              "locX": 0.5,
              "locY": 0.5,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
          },
          {
              "tag": "buttonLeft",
              "type": "button",
              "locX": 0.2,
              "locY": 0.9,
              "height": 70,
              "width": 70,
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
          },
          {
            "tag": "buttonRight",
            "type": "button",
            "locX": 0.8,
            "locY": 0.9,
            "height": 70,
            "width": 70,
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
        }
      ]
  }
}`

export const pageRoutingTest = `{
	"metadata":
	{
		"experienceID": "12345",
		"experienceType": "custom"
	},
	"theme":
	{
		"imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
		"primaryColor": [255, 255, 255],
		"secondaryColor": [92, 92, 92],
		"plantLogoOnSurfaces": false
	},
	"experience":
	{
		"objects": 
		[
      {
        "id": "1111",
        "name": "targetObject",
        "maxExecutions": 20,
        "object": {
          "fileName": "orbarlogo.png",
          "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
          "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092",
          "settings": {
            "allowUserGestures": false,
            "size": 0.1,
            "timeOut": -1,
            "addPhysicsBody": true,
            "physicsShapeType": 2,
            "isAffectedByGravity": false
          }
        },
        "activities": [
          {
            "trigger": {
              "trigger": "onButtonTap",
              "id": "shootButton"
            },
            "actions": [
              {
                "action": "bringToLife",
                "loc": "userPos"
              },
              {
                "action": "shoot"
              }
            ]
          },
          {
            "trigger": {
              "trigger": "onCollideWith",
              "id2": "targetObject2"
            },
            "actions": [
              {
                "action": "kill",
                "delay": -1
              },
              {
                "action": "playSound",
                "soundtrackName": "BubblePop.mp3",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
              }
            ]
          }
        ]
      },
      {
        "id": "2222",
        "name": "targetObject2",
        "object": {
          "fileName": "sunflower.gltf",
            "fileUrl": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
            "fileUrlAndroid": "https://immersive-web.github.io/webxr-samples/media/gltf/sunflower/sunflower.gltf",
          "settings": {
            "allowUserGestures": false,
            "size": 0.2,
            "timeOut": -1,
            "addPhysicsBody": true,
            "physicsShapeType": 2,
            "isAffectedByGravity": false
          }
        },
        "activities": [
          {
            "trigger": {
              "trigger": "onRandDelta",
              "minDelta": 1,
              "maxDelta": 3
            },
            "actions": [
              {
                "action": "bringToLife",
                "loc": "infront"
              },
              {
                  "action": "move",
                  "x": 0,
                  "y": 0.3,
                  "z": 0
              }
            ]
          },
          {
            "trigger": {
              "trigger": "onCollideWith",
              "id2": "targetObject"
            },
            "actions": [
              {
                "action": "kill",
                "delay": -1
              }
            ]
          }
        ]
      }
		],
		"generalRules": [],
		"UIObjects": [
			{
				"tag": "image1",
				"type": "image",
				"locX": 0.5,
				"locY": 0.5,
				"height": 70,
				"width": 70,
				"imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
			},
			{
				"tag": "shootButton",
				"type": "button",
				"locX": 0.5,
				"locY": 0.9,
				"height": 70,
				"width": 70,
				"imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
			}
		]
  },
  "pageRoutingConfig":
  [
    {
      "pageName": "game",
      "pageParamsName": "experience"
    },
    {
      "pageName": "postGame",
      "pageParamsName": ""
    }
  ]
}`

export const legoExperience = `
{
  "metadata":{
     "experienceID":"LegoExperience1234",
     "experienceType":"custom"
  },
  "theme":{
     "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FLegoExperience1234%2FImages%2FLegoLogo.jpg?alt=media&token=fd83045e-4f43-43f9-84ae-c383bdd24061",
     "primaryColor":[
        255,
        0,
        0
     ],
     "secondaryColor":[
        239,
        239,
        0
     ],
     "plantLogoOnSurfaces":false
  },
  "experience":{
     "objects":[
        {
           "id":"1111",
           "name":"Red Velvet Cake",
           "maxExecutions":1,
           "object":{
              "fileName":"LegoBatmobile.usdz",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FLegoExperience1234%2FModels%2FLegoBatmobile.usdz?alt=media&token=744732ed-832f-4eba-8e38-9547850aeeab",
              "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FLegoExperience1234%2FModels%2FLegoBatmobile.glb?alt=media&token=aec9b95f-b6fe-48c1-87da-5439f1c871fd",
              "settings":{
                "allowUserGestures":true,
                "size":0.2
              }
            },
           "activities":[
              {
                 "maxExecutions":1,
                 "trigger":{
                    "trigger":"onButtonTap",
                    "id":"selectionButton"
                 },
                 "actions":[
                    {
                       "action":"bringToLifeViaFS",
                       "loc":"infront"
                    },
                    {
                       "action":"animate",
                       "keyPath":"rotation",
                       "duration":20,
                       "repeatCount":1000,
                       "fromValue":[
                          0,
                          1,
                          0,
                          0
                       ],
                       "toValue":[
                          0,
                          1,
                          0,
                          6.2831
                       ]
                    }
                 ]
              }
           ]
        }
     ],
     "generalRules":[
        
     ],
     "UIObjects":[
        {
           "tag":"selectionButton",
           "type":"button",
           "locX":0.5,
           "locY":0.9,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBAqua.png?alt=media&token=e531f565-d996-4199-9c4c-75a060e69c1b"
        }
     ]
  },
  "pageRoutingConfig":[
     {
        "pageName":"game",
        "pageParamsName":"experience"
     }
  ]
}
`

export const surveyExperience = `
{
   "metadata":{
      "experienceID":"SurveyDemo1234",
      "experienceType":"survey"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3",
      "primaryColor":[
         255,
         255,
         255
      ],
      "secondaryColor":[
         92,
         92,
         92
      ],
      "plantLogoOnSurfaces":true
   },
   "pageRoutingConfig":[
      {
         "pageName":"game",
         "pageParamsName":"experience"
      }
   ],
   "experience":{
      "survey":{
         "questions":[
            {
               "question":"How was the AR experience?",
               "answers":[
                  {
                     "answer":"Poor",
                     "imageUrl":""
                  },
                  {
                     "answer":"Fine",
                     "imageUrl":""
                  },
                  {
                     "answer":"Excellent",
                     "imageUrl":""
                  }
               ]
            },
            {
               "question":"Second question??",
               "answers":[
                  {
                     "answer":"Poor",
                     "imageUrl":""
                  },
                  {
                     "answer":"Fine",
                     "imageUrl":""
                  },
                  {
                     "answer":"Excellent",
                     "imageUrl":""
                  }
               ]
            }
         ]
      },
      "review":{
         "questions":[
            {
               "question":"How realistic were the 3D models",
               "numRatings":5
            },
            {
               "question":"Were the instructions understandale?",
               "numRatings":5
            }
         ]
      },
      "emojiReview":{
         "questions":[
            
         ]
      },
      "staticConfig":{
         "questionBackgroundColor":[
            0.25,
            0.25,
            0.25,
            1.0
         ],
         "questionBackgroundOpacity":0.87,
         "questionBackgroundCornerRadius":0.25,
         "surveyAnswerBackgroundColor":[
            0.25,
            0.25,
            0.25,
            1.0
         ],
         "surveyAnswerBackgroundOpacity":0.87,
         "surveyAnswerBackgroundCornerRadius":0.5,
         "reviewAnswerBackgroundColor":[
            0.5,
            0.5,
            0.5,
            1.0
         ],
         "questionTextColor":[
            0.07,
            0.92,
            0.81,
            1.0
         ],
         "answerTextColor":[
            0.847,
            0.847,
            0.847,
            1.0
         ],
         "textDepth":0.05,
         "distanceFromUser":[
            0,
            0.1,
            -1.6
         ],
         "questionYOffset":0.2,
         "surveyAnswerOnBackgroundColor":[
            0.07,
            0.92,
            0.81,
            1.0
         ],
         "surveyRowDelta":-0.15,
         "reviewColDelta":0.2,
         "reviewRatingBackgroundHeight":0.22,
         "emojiReviewBackgroundHeight":0.4,
         "emojiReviewBackgroundWidth":0.75,
         "ratingOffOpacity":0.2,
         "ratingOnOpacity":1.0,
         "ratingObjectScale":0.2,
         "bulletObjectScale":0.02,
         "ratingObjectDict":{
            "fileName":"ratingObject.usdz",
            "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FGoldStar.usdz?alt=media&token=3910f99a-20ea-44fd-b676-37b03d7224c6",
            "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FSurveyDemo1234%2FModels%2Fgold_star.glb?alt=media&token=785218af-d7a0-446d-a39e-efe5f567a475",
            "settings":{
               "allowUserGestures":false,
               "size":0.1,
               "timeOut":-1,
               "addPhysicsBody":true,
               "physicsShapeType":2,
               "isAffectedByGravity":false
            }
         },
         "surveyBulletObjectDict":{
            "fileName":"surveyBulletObject.usdz",
            "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FORBAqua.usdz?alt=media&token=838488ee-8765-4a30-88ff-5ac6de315b90",
            "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FSurveyDemo1234%2FModels%2FORBAqua.glb?alt=media&token=724c6377-448a-4642-82a0-fe9e746dfc4d",
            "settings":{
               "allowUserGestures":false,
               "size":0.1,
               "timeOut":-1,
               "addPhysicsBody":true,
               "physicsShapeType":2,
               "isAffectedByGravity":false
            }
         },
         "reviewBulletObjectDict":{
            "fileName":"reviewBulletObject.usdz",
            "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FGoldStar.usdz?alt=media&token=3910f99a-20ea-44fd-b676-37b03d7224c6",
            "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FSurveyDemo1234%2FModels%2Fgold_star.glb?alt=media&token=785218af-d7a0-446d-a39e-efe5f567a475",
            "settings":{
               "allowUserGestures":false,
               "size":0.1,
               "timeOut":-1,
               "addPhysicsBody":true,
               "physicsShapeType":2,
               "isAffectedByGravity":false
            }
         },
         "surveyShootButtonImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3",
         "reviewShootButtonImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FGoldStarBlueORB.png?alt=media&token=f72b9316-7efd-472c-8f5d-be1ac4e338b7",
         "hitSoundFileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FItemClaim.wav?alt=media&token=74534540-6439-4bd6-9d08-1440924bd5c1"
      },
      "UIObjects":[
         {
            "tag":"image1",
            "type":"image",
            "locX":0.5,
            "locY":0.5,
            "height":100,
            "width":100,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
         },
         {
            "tag":"shootButton",
            "type":"button",
            "locX":0.5,
            "locY":0.9,
            "height":140,
            "width":140,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FGoldStarBlueORB.png?alt=media&token=f72b9316-7efd-472c-8f5d-be1ac4e338b7"
         },
         {
            "tag":"leftArrowButton",
            "type":"button",
            "locX":0.1,
            "locY":0.9,
            "height":100,
            "width":100,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FARSurvey%2FArrowLeft.png?alt=media&token=261518e0-33ad-4d48-a57c-d4ad43168e16"
         },
         {
            "tag":"rightArrowButton",
            "type":"button",
            "locX":0.9,
            "locY":0.9,
            "height":100,
            "width":100,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FARSurvey%2FArrowRight.png?alt=media&token=f8cbfb28-eeaf-4077-b098-58a4d9c6a19b"
         },
         {
            "tag":"repeatButton",
            "type":"button",
            "locX":0.5,
            "locY":0.78,
            "height":60,
            "width":120,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FARSurvey%2Fundo.png?alt=media&token=64aa3202-86f2-4c7a-bcb2-9da9272deb12"
         },
         {
            "tag":"doneButton",
            "type":"button",
            "locX":0.9,
            "locY":0.1,
            "height":70,
            "width":70,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FARSurvey%2FQ-Aqua.png?alt=media&token=bee96c26-ba49-4284-82a9-4a414c66e9eb"
         },
         {
            "tag":"emojiSlider",
            "type":"slider",
            "locX":0,
            "locY":0.75,
            "height":0.5,
            "width":100,
            "imageSize":100,
            "minimumLineSpacing":20,
            "isVertical":true,
            "imageUrls":[
               "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FEmojiHappy.png?alt=media&token=706cdcfb-8f48-415e-805c-dae91ae0badf",
               "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FEmojiMindBlowing.png?alt=media&token=ae496d50-edd6-49d4-b305-a10b1d1fcb9b",
               "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FEmojiAngry.png?alt=media&token=70a19278-d198-4cc3-851b-1c048657312c",
               "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FEmojiDisappointed.png?alt=media&token=039dc270-9c48-4a2f-be80-a268d24f6fa5"
            ]
         }
      ]
   }
}
`

export const cremerieDemoExperience = `
{
  "metadata":{
     "experienceID":"CremerieExperience1234",
     "experienceType":"custom"
  },
  "theme":{
     "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FCremerieExperience1234%2FImages%2Fcremerie_logo.png?alt=media&token=bc296106-d983-47d6-bc9e-29a490becd00",
     "primaryColor":[
        255,
        0,
        0
     ],
     "secondaryColor":[
        239,
        239,
        0
     ],
     "plantLogoOnSurfaces":false
  },
  "experience":{
     "objects":[
        {
           "id":"1111",
           "name":"Red Velvet Cake",
           "maxExecutions":1,
           "object":{
              "fileName":"RedVelvet.usdz",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FCremerieExperience1234%2FModels%2FRedVelvet.usdz?alt=media&token=2ba591df-85e6-4e85-9a8c-e0485a3dd50d",
              "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FCremerieExperience1234%2FModels%2FRedVelvet.glb?alt=media&token=59fd7a6a-13e5-4987-b05e-a9bc142851ae",
              "settings":{
                 "allowUserGestures":true,
                 "size":0.2
              }
           },
           "activities":[
              {
                 "maxExecutions":1,
                 "trigger":{
                    "trigger":"onButtonTap",
                    "id":"selectionButton"
                 },
                 "actions":[
                    {
                       "action":"bringToLifeViaFS",
                       "loc":"infront"
                    },
                    {
                       "action":"animate",
                       "keyPath":"rotation",
                       "duration":20,
                       "repeatCount":1000,
                       "fromValue":[
                          0,
                          1,
                          0,
                          0
                       ],
                       "toValue":[
                          0,
                          1,
                          0,
                          6.2831
                       ]
                    }
                 ]
              }
           ]
        }
     ],
     "generalRules":[
        
     ],
     "UIObjects":[
        {
           "tag":"selectionButton",
           "type":"button",
           "locX":0.5,
           "locY":0.9,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBAqua.png?alt=media&token=e531f565-d996-4199-9c4c-75a060e69c1b"
        }
     ]
  },
  "pageRoutingConfig":[
     {
        "pageName":"game",
        "pageParamsName":"experience"
     }
  ]
}
`

export const ShootersDemo1234 = `
{
  "metadata":{
     "experienceID":"ShootersDemo1234",
     "experienceType":"custom"
  },
  "theme":{
     "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3",
     "primaryColor":[
        255,
        255,
        255
     ],
     "secondaryColor":[
        92,
        92,
        92
     ],
     "plantLogoOnSurfaces":false
  },
  "pageRoutingConfig":[
     {
        "pageName":"welcome",
        "pageParamsName":"welcomeScreenInput"
     },
     {
      "pageName": "registration"
     },
     {
        "pageName":"game",
        "pageParamsName":"experience"
     },
     {
      "pageName":"summary"
      }
  ],
  "welcomeScreenInput":{
     "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3",
     "title":"Orb",
     "description":"Welcome to Orb's Augmented Reality Shooters game!",
     "notes":[
        
     ]
  },
  "experience":{
     "objects":[
        {
           "id":"1111",
           "name":"targetObject",
           "object":{
              "fileName":"Images-ORBAR-logo.png",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onRandDelta",
                    "minDelta":1,
                    "maxDelta":2
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"infront"
                    },
                    {
                       "action":"move",
                       "x":0,
                       "y":1.0,
                       "z":0
                    },
                    {
                       "action":"kill",
                       "delay":5
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObject"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    },
                    {
                       "action":"updateCounter",
                       "counterID":"stats123",
                       "delta":1
                    },
                    {
                       "action":"playSound",
                       "soundtrackName":"BubblePop.mp3",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                    }
                 ]
              }
           ]
        },
        {
           "id":"1234",
           "name":"targetObject2",
           "object":{
              "fileName":"mine.png",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2Fmine.png?alt=media&token=85ebb759-1ebf-4525-a600-2216b8b881bf"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onRandDelta",
                    "minDelta":2,
                    "maxDelta":4
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"infront"
                    },
                    {
                       "action":"move",
                       "x":0,
                       "y":0,
                       "z":0.1
                    },
                    {
                       "action":"kill",
                       "delay":4
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObject"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    },
                    {
                       "action":"updateCounter",
                       "counterID":"stats123",
                       "delta":-1
                    },
                    {
                       "action":"playSound",
                       "soundtrackName":"Block.wav",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBlock.wav?alt=media&token=f63fcaea-c835-4663-be7b-630b62d90d10"
                    }
                 ]
              }
           ]
        },
        {
           "id":"2222",
           "name":"bulletObject",
           "object":{
              "fileName":"ORBAqua.png",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onButtonTap",
                    "id":"button123"
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"userPos"
                    },
                    {
                       "action":"shoot"
                    },
                    {
                       "action":"kill",
                       "delay":5
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"targetObject"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"targetObject2"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              }
           ]
        }
     ],
     "generalRules":[
        {
           "activity":{
              "trigger":{
                 "trigger":"onTimeEnd"
              },
              "actions":[
                 {
                    "action":"experienceFailed"
                 }
              ]
           }
        }
     ],
     "UIObjects":[
        {
           "tag":"button123",
           "type":"button",
           "locX":0.5,
           "locY":0.9,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
        },
        {
           "tag":"stats123",
           "type":"stats",
           "locX":0.5,
           "locY":0.1,
           "height":100,
           "width":100,
           "useTimer":true,
           "useScore":true,
           "useLevels":false,
           "maxTime":7,
           "maxScore":100
        },
        {
           "tag":"image1",
           "type":"image",
           "locX":0.5,
           "locY":0.5,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
        }
     ]
  }
}
`

export const BluDemoExperience = `
{
  "metadata":{
     "experienceID":"BluDemoExperience3",
     "experienceType":"custom"
  },
  "theme":{
     "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBluDemoExperience3%2FImages%2FIMG_0662.png?alt=media&token=1d24c0b5-7580-4497-b4d7-4e5b90c69c8d",
     "primaryColor":[
        255,
        255,
        255
     ],
     "secondaryColor":[
        92,
        92,
        92
     ],
     "plantLogoOnSurfaces":false
  },
  "pageRoutingConfig":[
     {
        "pageName":"game",
        "pageParamsName":"experience"
     },
     {
        "pageName":"postGame"
     }
  ],
  "experience":{
     "objects":[
        {
           "id":"OriginalBluCan",
           "name":"OriginalBluCanTarget",
           "object":{
              "fileName":"OriginalBluCan.usdz",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBluDemoExperience3%2FModels%2FBluOriginalCan.usdz?alt=media&token=4d0f9959-56d5-4695-b528-72cff478bef8",
              "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBaselBurgerMenu%2FModels%2FBaselBurgerClassicBurger.glb?alt=media&token=52e5750a-2508-4cbb-b148-e7d096175b98"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onRandDelta",
                    "minDelta":3,
                    "maxDelta":5
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"infront"
                    },
                    {
                       "action":"rotate",
                       "angleY":3.141
                    },
                    {
                       "action":"scale",
                       "scale":0.04
                    },
                    {
                       "action":"kill",
                       "delay":5
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObjectLeft"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    },
                    {
                       "action":"updateCounter",
                       "counterID":"stats123",
                       "delta":1
                    },
                    {
                       "action":"playSound",
                       "soundtrackName":"SodaCanOpen.mp3",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBluDemoExperience3%2FAudios%2FSodaCanOpen.mp3?alt=media&token=b5bef913-78d6-4dda-b9e7-d187ac5e44be"
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObjectRight"
                 },
                 "actions":[
                    {
                       "action":"playSound",
                       "soundtrackName":"Block.wav",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FAudioFiles%2FORBAudio%2FBlock.wav?alt=media&token=2f6fc966-9e14-4ecd-9d0d-fe2309c3a76a"
                    }
                 ]
              }
           ]
        },
        {
           "id":"BluDayCan",
           "name":"BluDayCanTarget",
           "object":{
              "fileName": "BluDayCan.usdz",
              "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBluDemoExperience3%2FModels%2FBluDayCan.usdz?alt=media&token=56bceb90-295a-4b26-b652-ed31295d8a1e",
              "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBaselBurgerMenu%2FModels%2FBaselBurgerMexicanBurger.glb?alt=media&token=ffff7c14-acc1-44fb-b70b-8374d275d9c1"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onRandDelta",
                    "minDelta":5,
                    "maxDelta":8
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"infront"
                    },
                    {
                       "action":"scale",
                       "scale":0.04
                    },
                    {
                       "action":"kill",
                       "delay":3
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObjectRight"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    },
                    {
                       "action":"updateCounter",
                       "counterID":"stats123",
                       "delta":2
                    },
                    {
                       "action":"playSound",
                       "soundtrackName":"SodaCanOpen.mp3",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FBluDemoExperience3%2FAudios%2FSodaCanOpen.mp3?alt=media&token=b5bef913-78d6-4dda-b9e7-d187ac5e44be"
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"bulletObjectLeft"
                 },
                 "actions":[
                    {
                       "action":"playSound",
                       "soundtrackName":"Block.wav",
                       "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FAudioFiles%2FORBAudio%2FBlock.wav?alt=media&token=2f6fc966-9e14-4ecd-9d0d-fe2309c3a76a"
                    }
                 ]
              }
           ]
        },
        {
           "id":"2222",
           "name":"bulletObjectLeft",
           "object":{
              "fileName":"ORBDarkBlue.png",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBDarkBlue.png?alt=media&token=6e9065a8-93b6-4ca2-b0e8-9c26c01a615b"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onButtonTap",
                    "id":"leftButton"
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"userPos"
                    },
                    {
                       "action":"shoot"
                    },
                    {
                       "action":"kill",
                       "delay":5
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"OriginalBluCanTarget"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"BluDayCanTarget"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              }
           ]
        },
        {
           "id":"3333",
           "name":"bulletObjectRight",
           "object":{
              "fileName":"ORBLightAqua.png",
              "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBLightAqua.png?alt=media&token=c9239a9b-dd0e-4258-8037-f1721ab3e0cb"
           },
           "activities":[
              {
                 "trigger":{
                    "trigger":"onButtonTap",
                    "id":"rightButton"
                 },
                 "actions":[
                    {
                       "action":"bringToLife",
                       "loc":"userPos"
                    },
                    {
                       "action":"shoot"
                    },
                    {
                       "action":"kill",
                       "delay":5
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"OriginalBluCanTarget"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              },
              {
                 "trigger":{
                    "trigger":"onCollideWith",
                    "id2":"BluDayCanTarget"
                 },
                 "actions":[
                    {
                       "action":"kill",
                       "delay":0
                    }
                 ]
              }
           ]
        }
     ],
     "generalRules":[
        {
           "activity":{
              "trigger":{
                 "trigger":"onTimeEnd"
              },
              "actions":[
                 {
                    "action":"experienceFailed"
                 }
              ]
           }
        }
     ],
     "UIObjects":[
        {
           "tag":"leftButton",
           "type":"button",
           "locX":0.2,
           "locY":0.9,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBDarkBlue.png?alt=media&token=6e9065a8-93b6-4ca2-b0e8-9c26c01a615b"
        },
        {
           "tag":"rightButton",
           "type":"button",
           "locX":0.8,
           "locY":0.9,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBLightAqua.png?alt=media&token=c9239a9b-dd0e-4258-8037-f1721ab3e0cb"
        },
        {
           "tag":"stats123",
           "type":"stats",
           "locX":0.5,
           "locY":0.1,
           "height":100,
           "width":100,
           "useTimer":true,
           "useScore":true,
           "useLevels":false,
           "maxTime":60,
           "maxScore":100        
        },
        {
           "tag":"image1",
           "type":"image",
           "locX":0.5,
           "locY":0.5,
           "height":70,
           "width":70,
           "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FScopeImage.png?alt=media&token=c71fe90c-9ad0-4169-9dbb-cc0bc2f77663"
        }
     ]
  }
}
`

export const DoubleShootersExperience = `
{
  "experience": {
    "UIObjects": [
      {
        "height": 70,
        "imageUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/1db3.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=IF8PV7o5cim10C7y%2F%2BFgqSrj%2Ff48vy7la65Xn3%2BNEiaKl1%2Fd8DMOX3O3o2bMcPcA9fXfmZtkmHO5k9FoWe3dKvwccsjSLUM96aKXO%2F0gSYjtdMCH7Hv%2BDqoM72XFY0%2BnEVhNCVQAsYxgv7QTbo1a8AwZaYjcVDo8qNbZlCAxcBA2j11KTOcyjrKR9XbSMRtGV5LeSNJmE8qIxVfvu0VsGIFbK%2BBL14w9nfZAop1z60i9fwVed6Nvvw1SJ76ecVJXrFBnhoKeH37LtmAwavTbOZZ7SZn9KxmjrsKiwTmxSeYtSGdA%2F95XkMXSitRsmLNPe3iELYcby6M6SO52FS7mIA%3D%3D",
        "locX": 0.2,
        "locY": 0.9,
        "tag": "leftButton",
        "type": "button",
        "width": 70
      },
      {
        "height": 70,
        "imageUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/725d.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=BqLda%2FPgwyrpAP1mDJ4um4iTb%2FQzbM5vdi%2FQNY5IpxwqN%2B%2Ba3dp8k4lFgDtcZOppqbJk0BzXPyqGTn%2Fu5nda7BPiMfOn5gGrMoKbXxLjnLRobdF8%2Fin8BKOxouC1WxFtc5N4S3qcMRmvHDBIM99LkQ3PWYYi%2B9zFRjoDHxHgHTk8pkkB5xH5lpzp2WoSltRLlkI4oOVR8iqAoorkf%2FQwnPEdqykZJBBFoJk32kjC0CwD89d7FAgS1MFec5YVFaPo8AMEF33mPf6eY0XOWDhtSHzJ9AkHyMOjxH9m6FCZ%2Bmc2BvEWpbRAzNHvsBsBliNweGwvYXJIaCOAk%2Bl1dalRCw%3D%3D",
        "locX": 0.8,
        "locY": 0.9,
        "tag": "rightButton",
        "type": "button",
        "width": 70
      },
      {
        "height": 100,
        "locX": 0.5,
        "locY": 0.1,
        "maxScore": 100,
        "maxTime": 60,
        "tag": "stats123",
        "type": "stats",
        "useLevels": false,
        "useScore": true,
        "useTimer": true,
        "width": 100
      },
      {
        "height": 70,
        "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FScopeImage.png?alt=media&token=c71fe90c-9ad0-4169-9dbb-cc0bc2f77663",
        "locX": 0.5,
        "locY": 0.5,
        "tag": "scopeImage",
        "type": "image",
        "width": 70
      }
    ],
    "generalRules": [
      {
        "activity": {
          "actions": [
            {
              "action": "experienceFailed"
            }
          ],
          "trigger": {
            "trigger": "onTimeEnd"
          }
        }
      }
    ],
    "objects": [
      {
        "activities": [
          {
            "actions": [
              {
                "action": "bringToLife",
                "loc": "infront"
              },
              {
                "action": "move",
                "x": 0,
                "y": 1,
                "z": 0
              },
              {
                "action": "kill",
                "delay": 5
              }
            ],
            "trigger": {
              "maxDelta": 1,
              "minDelta": 1,
              "trigger": "onRandDelta"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              },
              {
                "action": "updateCounter",
                "counterID": "stats123",
                "delta": 1
              },
              {
                "action": "playSound",
                "soundtrackName": "BubblePop.mp3",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
              }
            ],
            "trigger": {
              "id2": "leftBulletObjectName",
              "trigger": "onCollideWith"
            }
          },
          {
            "actions": [
              {
                "action": "playSound",
                "soundtrackName": "Block.wav",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FAudioFiles%2FORBAudio%2FBlock.wav?alt=media&token=2f6fc966-9e14-4ecd-9d0d-fe2309c3a76a"
              }
            ],
            "trigger": {
              "id2": "rightBulletObjectName",
              "trigger": "onCollideWith"
            }
          }
        ],
        "id": "leftObjectID",
        "name": "leftObjectName",
        "object": {
          "fileName": "LotusDonut.usdz",
          "fileThumbnail": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745664583/ce39.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=l6Z7zsia55RqPTfWBgAyeUmvb9siagXvk1wXP4Emm5vgneJC%2B2ILdGTgplX5OpQtsrfbdkChFCWbW24YvJ8bqdDmEpmhXih77FdgqIQUYKU9ECoXgEwEBIpTCe707Kexhghd%2F9mPcZrxRkKRZqQ1tp3whoXAOnckBk3YIMzmnS%2BSEIvoc60wSharxHkCqUIOhH6LM0h7NRYmin4Rnk7qaQbo7%2F0pdKXEkmqpv0sZilNcYrg7N0pRChTduQbvUetKT5GD5%2FtQfo9ISAh5dogcxzVD3naG2DZJLiABSBULhYKFX%2B4PJUOXTGbg6idJ7gNsbw5D1n6aCpXQYcip4UdJiQ%3D%3D",
          "fileUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745664583/4983.usdz?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=ZuOTQh6jqYtmXzsSQZa%2FUYFXakKl%2FE6x4DFtrvaMaeRWEgtlXgITnCO6iy%2FeYSPknx8RkgvVLo4d0Gt4bIGQvlp9L%2FBGUk4O0uiSUOeEEcxWpUXiglV11X5pF8EaM8jS2knwECSlrRj2QiIZkDL0ClOW31IlurJnJwY2y9E%2FXcRXefy%2FjejB%2BFQlsqsLhvfQ9hkRtEd5cQBPx1rex4PzK6x40I03s00N2DM2Pq0tGvaGzQMzscUEZyz7DePVwzfWkM83wkRvQmSoFcpLFtzeJVF6jO4ENnYjgM01eiZBJ8UxKwaER8Zz11ABzD%2Bd09IDFknJb7P1nnqpGloJ7zlz4g%3D%3D",
          "fileUrlAndroid": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745664583/83a7.glb?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=KbDxc%2FXflawM%2BeIO365bcmjnkT72eQOG1%2B6x5poEB61gcXdIXBu29W82XjCBLf3%2F9T9uh2XABLr6vwWj1hbit5iNXfHq3JTYqSeGYJ14No4nI3rXFxdB1gJmmZAYsIilvH6ZjQluhLRwyQ9JnIT36kc5F6UMkvMLgXEdOmqvPmuD%2B1KwFRDp29is4eIuuqR%2FY3MULn7%2FveMGZrnqc%2Fh4KgkdIOm%2BoVTvZyNxkjAhy1GjNi3VpKfprWqyl4ibIvg%2FJEFK2x2jlEFGJLvKRjpOjbl4kbDTkm2v4yzgCd%2BIs2brvNELBF9BivBbcD%2B45nYbidka40m6vvQhBjDlopJScA%3D%3D",
          "settings": {
            "addPhysicsBody": true,
            "addToAnalytics": false,
            "allowUserGestures": false,
            "isAffectedByGravity": false,
            "offsetVec": [
              0,
              0,
              0
            ],
            "physicsShapeType": 2,
            "scaleAxis": 0,
            "size": 0.05
          }
        }
      },
      {
        "activities": [
          {
            "actions": [
              {
                "action": "bringToLife",
                "loc": "infront"
              },
              {
                "action": "move",
                "x": 0.5,
                "y": 0.5,
                "z": 0
              },
              {
                "action": "kill",
                "delay": 3
              }
            ],
            "trigger": {
              "maxDelta": 2,
              "minDelta": 2,
              "trigger": "onRandDelta"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              },
              {
                "action": "updateCounter",
                "counterID": "stats123",
                "delta": 2
              },
              {
                "action": "playSound",
                "soundtrackName": "BubblePop.mp3",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
              }
            ],
            "trigger": {
              "id2": "rightBulletObjectName",
              "trigger": "onCollideWith"
            }
          },
          {
            "actions": [
              {
                "action": "playSound",
                "soundtrackName": "Block.wav",
                "soundtrackUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FAudioFiles%2FORBAudio%2FBlock.wav?alt=media&token=2f6fc966-9e14-4ecd-9d0d-fe2309c3a76a"
              }
            ],
            "trigger": {
              "id2": "leftBulletObjectName",
              "trigger": "onCollideWith"
            }
          }
        ],
        "id": "rightObjectID",
        "name": "rightObjectName",
        "object": {
          "fileName": "LayaliBeirut.usdz",
          "fileThumbnail": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745635101/0aa0.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=Un%2Fewtbb2%2BuO8sAihsjtEwmi1dYDWCFxPMWJOIFGPdruTVNyoAfuEu%2BGYwoqXR476QpuYs1eeUJfQGjdUAKUypanuO%2FDlkzmMi9eF1iSYZf6AvPAq5acQpK4IksxDiSXTBiYmEtmGn%2FCLeDvOUdjondIlggf7OaYgYfsT6he8d04ol0Wn6ndF9Lt638AZIUxvrzohy1CXWhRl5Hoxd8n2JdeZSre7OFxXFQeXgI7iYdPfAZiGViIjJHs0ESB6hyqzyJUEcROUc8a7Fe%2F69QWwBl3%2FmkPATpt8ZcYTONftzi%2F%2BssIQiLoUVcUin2xnARCtya7WJsA95%2FQvh2hRqVNaw%3D%3D",
          "fileUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745635101/c868.usdz?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=ZDzbTGTxse7e%2FxsP9sSI8loA7Vw0qYiiiN%2BrD5XFy%2Btv8oXJTcuTQp3WmEoSBq8R1ZCJOmZt4SA%2FgWrHBAmoCy8MPh1005DGrQSPXJ5G%2B2wbJnn3ULU5%2BChsmAFdWge4n2qQTu9wuC3FIlg70pbPBrQSqMOcOIUFkTgDuRWwjYwVukV4DREn%2BnEjX5mMqx5vqgBPTtQu5OCWLQ6G9M0qmtlAzVUkgcib8Me0lLLdyY7WNBfV6W9nc3qrOePkI00Sc%2B05VTyQlvmtsLQoVC%2FaKVKPDpdOUG3sB3NmGzhakQiIqnog62CgMuSsRUQJ3V%2F9v0HbbmS1mjCjkBChoWP32A%3D%3D",
          "fileUrlAndroid": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Models/1716745635101/8e1c.glb?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=fHLkP4LxObt9uhkXWks07abNUw1KovYMVxjxFtjlwk7oEcJgiIaHdrm7cf2jCt1UueteA6avWbAy3LyjV6gQzkhEB9yYunE5EoNSwHBaxs3n812IOq4KzvFJa6YiV1u4ES4q5Ha10NwA3%2FOX9%2FO%2Bh95VusOy1epwRsvJdy88i9W8dXUvuZlRea6PoWusZnGqrfWvV5hsiAGJ25SlQC6vEkIGXR2ifTGfdBNqCo74yRBqmYw6XsTGUS6Vo6A80X1QmRXT5xxugG8UY5izdAystTaONMjUTyCJNdQLMdF%2FRtiucpjzJWzAvTVOV8mxX5K1ZyBjzXIJUMaBAQnYKihyEA%3D%3D",
          "settings": {
            "addPhysicsBody": true,
            "addToAnalytics": false,
            "allowUserGestures": false,
            "isAffectedByGravity": false,
            "offsetVec": [
              0,
              0,
              0
            ],
            "physicsShapeType": 2,
            "scaleAxis": 0,
            "size": 0.05
          }
        }
      },
      {
        "activities": [
          {
            "actions": [
              {
                "action": "bringToLife",
                "loc": "userPos"
              },
              {
                "action": "shoot"
              },
              {
                "action": "kill",
                "delay": 5
              }
            ],
            "trigger": {
              "id": "leftButton",
              "trigger": "onButtonTap"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              }
            ],
            "trigger": {
              "id2": "leftObjectName",
              "trigger": "onCollideWith"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              }
            ],
            "trigger": {
              "id2": "rightObjectName",
              "trigger": "onCollideWith"
            }
          }
        ],
        "id": "leftBulletObjectID",
        "name": "leftBulletObjectName",
        "object": {
          "fileName": "LotusOrb.png",
          "fileThumbnail": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/1db3.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=IF8PV7o5cim10C7y%2F%2BFgqSrj%2Ff48vy7la65Xn3%2BNEiaKl1%2Fd8DMOX3O3o2bMcPcA9fXfmZtkmHO5k9FoWe3dKvwccsjSLUM96aKXO%2F0gSYjtdMCH7Hv%2BDqoM72XFY0%2BnEVhNCVQAsYxgv7QTbo1a8AwZaYjcVDo8qNbZlCAxcBA2j11KTOcyjrKR9XbSMRtGV5LeSNJmE8qIxVfvu0VsGIFbK%2BBL14w9nfZAop1z60i9fwVed6Nvvw1SJ76ecVJXrFBnhoKeH37LtmAwavTbOZZ7SZn9KxmjrsKiwTmxSeYtSGdA%2F95XkMXSitRsmLNPe3iELYcby6M6SO52FS7mIA%3D%3D",
          "fileUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/1db3.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=IF8PV7o5cim10C7y%2F%2BFgqSrj%2Ff48vy7la65Xn3%2BNEiaKl1%2Fd8DMOX3O3o2bMcPcA9fXfmZtkmHO5k9FoWe3dKvwccsjSLUM96aKXO%2F0gSYjtdMCH7Hv%2BDqoM72XFY0%2BnEVhNCVQAsYxgv7QTbo1a8AwZaYjcVDo8qNbZlCAxcBA2j11KTOcyjrKR9XbSMRtGV5LeSNJmE8qIxVfvu0VsGIFbK%2BBL14w9nfZAop1z60i9fwVed6Nvvw1SJ76ecVJXrFBnhoKeH37LtmAwavTbOZZ7SZn9KxmjrsKiwTmxSeYtSGdA%2F95XkMXSitRsmLNPe3iELYcby6M6SO52FS7mIA%3D%3D",
          "fileUrlAndroid": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/1db3.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=IF8PV7o5cim10C7y%2F%2BFgqSrj%2Ff48vy7la65Xn3%2BNEiaKl1%2Fd8DMOX3O3o2bMcPcA9fXfmZtkmHO5k9FoWe3dKvwccsjSLUM96aKXO%2F0gSYjtdMCH7Hv%2BDqoM72XFY0%2BnEVhNCVQAsYxgv7QTbo1a8AwZaYjcVDo8qNbZlCAxcBA2j11KTOcyjrKR9XbSMRtGV5LeSNJmE8qIxVfvu0VsGIFbK%2BBL14w9nfZAop1z60i9fwVed6Nvvw1SJ76ecVJXrFBnhoKeH37LtmAwavTbOZZ7SZn9KxmjrsKiwTmxSeYtSGdA%2F95XkMXSitRsmLNPe3iELYcby6M6SO52FS7mIA%3D%3D",
          "settings": {
            "addPhysicsBody": true,
            "addToAnalytics": false,
            "allowUserGestures": false,
            "isAffectedByGravity": false,
            "offsetVec": [
              0,
              0,
              0
            ],
            "physicsShapeType": 2,
            "scaleAxis": 0,
            "size": 0.05
          }
        }
      },
      {
        "activities": [
          {
            "actions": [
              {
                "action": "bringToLife",
                "loc": "userPos"
              },
              {
                "action": "shoot"
              },
              {
                "action": "kill",
                "delay": 5
              }
            ],
            "trigger": {
              "id": "rightButton",
              "trigger": "onButtonTap"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              }
            ],
            "trigger": {
              "id2": "leftObjectName",
              "trigger": "onCollideWith"
            }
          },
          {
            "actions": [
              {
                "action": "kill",
                "delay": 0
              }
            ],
            "trigger": {
              "id2": "rightObjectName",
              "trigger": "onCollideWith"
            }
          }
        ],
        "id": "rightBulletObjectID",
        "name": "rightBulletObjectName",
        "object": {
          "fileName": "PistachioOrb.png",
          "fileThumbnail": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/725d.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=BqLda%2FPgwyrpAP1mDJ4um4iTb%2FQzbM5vdi%2FQNY5IpxwqN%2B%2Ba3dp8k4lFgDtcZOppqbJk0BzXPyqGTn%2Fu5nda7BPiMfOn5gGrMoKbXxLjnLRobdF8%2Fin8BKOxouC1WxFtc5N4S3qcMRmvHDBIM99LkQ3PWYYi%2B9zFRjoDHxHgHTk8pkkB5xH5lpzp2WoSltRLlkI4oOVR8iqAoorkf%2FQwnPEdqykZJBBFoJk32kjC0CwD89d7FAgS1MFec5YVFaPo8AMEF33mPf6eY0XOWDhtSHzJ9AkHyMOjxH9m6FCZ%2Bmc2BvEWpbRAzNHvsBsBliNweGwvYXJIaCOAk%2Bl1dalRCw%3D%3D",
          "fileUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/725d.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=BqLda%2FPgwyrpAP1mDJ4um4iTb%2FQzbM5vdi%2FQNY5IpxwqN%2B%2Ba3dp8k4lFgDtcZOppqbJk0BzXPyqGTn%2Fu5nda7BPiMfOn5gGrMoKbXxLjnLRobdF8%2Fin8BKOxouC1WxFtc5N4S3qcMRmvHDBIM99LkQ3PWYYi%2B9zFRjoDHxHgHTk8pkkB5xH5lpzp2WoSltRLlkI4oOVR8iqAoorkf%2FQwnPEdqykZJBBFoJk32kjC0CwD89d7FAgS1MFec5YVFaPo8AMEF33mPf6eY0XOWDhtSHzJ9AkHyMOjxH9m6FCZ%2Bmc2BvEWpbRAzNHvsBsBliNweGwvYXJIaCOAk%2Bl1dalRCw%3D%3D",
          "fileUrlAndroid": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/725d.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=BqLda%2FPgwyrpAP1mDJ4um4iTb%2FQzbM5vdi%2FQNY5IpxwqN%2B%2Ba3dp8k4lFgDtcZOppqbJk0BzXPyqGTn%2Fu5nda7BPiMfOn5gGrMoKbXxLjnLRobdF8%2Fin8BKOxouC1WxFtc5N4S3qcMRmvHDBIM99LkQ3PWYYi%2B9zFRjoDHxHgHTk8pkkB5xH5lpzp2WoSltRLlkI4oOVR8iqAoorkf%2FQwnPEdqykZJBBFoJk32kjC0CwD89d7FAgS1MFec5YVFaPo8AMEF33mPf6eY0XOWDhtSHzJ9AkHyMOjxH9m6FCZ%2Bmc2BvEWpbRAzNHvsBsBliNweGwvYXJIaCOAk%2Bl1dalRCw%3D%3D",
          "settings": {
            "addPhysicsBody": true,
            "addToAnalytics": false,
            "allowUserGestures": false,
            "isAffectedByGravity": false,
            "offsetVec": [
              0,
              0,
              0
            ],
            "physicsShapeType": 2,
            "scaleAxis": 0,
            "size": 0.05
          }
        }
      }
    ]
  },
  "metadata": {
    "description": "sdvsdv",
    "experienceID": "b9a73a709758461b908286a9f91be46f",
    "experienceName": "wdvgsd",
    "experienceType": "BuildYourOwn",
    "imageUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Experiences/b9a73a709758461b908286a9f91be46f/Images/3a07c6e0.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=SLAi8Oc4wA6m74DKOr8LP06Nid7b8m6ZZA43Ks7%2B1IF41VaBlOAfdZqrLe71pA1CjBx9Z%2BAGkMjCf2ck%2F3nTB8a3VnvZiJyP1Nm10%2FT9dll52YInp4Y24dBBRjIWlTKE5NZFEvum4ubXF9pfE%2BNtirkDfRFDpv7kDtdDQPeAz8gxvQP9qMQWHrNLk7krnONmJdJ175uK6KU8sAQn03NI1FiL6ThLC5H3%2BKsGudYcyl2%2B1X4R22zKSW714O%2B4jmUnfRNH4X%2FBxBq4Ju6jPvh1sCt30KgKYjXQaDL7mXw4KtRckYMJ0EEP7v1%2Fp%2F3Mexo1dSb5srLo4KJZx%2B0xSX3O8w%3D%3D"
  },
  "pageRoutingConfig": [
    {
      "pageName": "game",
      "pageParamsName": "experience"
    }
  ],
  "theme": {
    "imageUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Dev/Businesses/b976e7d8-8a45-4096-9c92-649a7e3ce3b2/Images/ORBARLogo.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2529705600&Signature=mKAUzZpRJOCOKW3efu7EWkWyUwzhv2HdPuQEZWgnzveUL%2FLDeICI7HSMA1zZbtw1TdWkfW3OG0iu7CHI3ca2zDJP4yF56f8%2BiMfoX2HES3K7Rdx61ha8B2xpnCgYc7pF7xblxiMj8w0yxvh%2FGNjRd3b8sq67qdmWaCNCAWOTnViOWic8eIWXqEC4%2BUuwlsH%2BnNXhO7a%2FceT%2BUYPkWWBa6aRR2yHotgggvy6L5rwZCG%2FSwnpwOOsypj1gDDqbfNfAOA1XL%2BAn%2By%2BOg9skqcm%2F%2F0DJNqPOKUgmLnprL6IUJJDNLtzz99At3NpBC5ma5N1cHBi9kmCa5GOYpIOS6iq%2BBQ%3D%3D",
    "plantLogoOnSurfaces": false,
    "primaryColor": [
      255,
      255,
      255
    ],
    "secondaryColor": [
      255,
      255,
      255
    ]
  }
}
`

export const testReplaceObject = `
{
   "metadata":{
      "experienceID":"ShootersDemo1234",
      "experienceType":"BuildYourOwn"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3",
      "primaryColor":[
         255,
         255,
         255
      ],
      "secondaryColor":[
         92,
         92,
         92
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"game",
         "pageParamsName":"experience"
      },
      {
         "pageName":"postGame"
      }
   ],
   "registrationScreenInput":{
      
   },
   "experience":{
      "objects":[
         {
            "id":"1111",
            "name":"targetObject",
            "object":{
               "fileName":"Images-ORBAR-logo.png",
               "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FORBARLogo.png?alt=media&token=64e4b756-6499-4dba-ba07-bbe4c989e8d3"
            },
            "maxExecutions":1,
            "activities":[
               {
                  "trigger":{
                     "trigger":"onButtonTap",
                     "id":"button123"
                  },
                  "actions":[
                     {
                        "action":"replaceObject",
                        "replacementObjectId":"targetObject2"
                     }
                  ]
               },
               {
                  "trigger":{
                     "trigger":"onRandDelta",
                     "minDelta":1,
                     "maxDelta":2
                  },
                  "actions":[
                     {
                        "action":"bringToLife",
                        "loc":"infront"
                     },
                     {
                        "action":"kill",
                        "delay":20
                     }
                  ]
               },
               {
                  "trigger":{
                     "trigger":"onCollideWith",
                     "id2":"bulletObject"
                  },
                  "actions":[
                     {
                        "action":"kill",
                        "delay":0
                     },
                     {
                        "action":"updateCounter",
                        "counterID":"stats123",
                        "delta":1
                     },
                     {
                        "action":"playSound",
                        "soundtrackName":"BubblePop.mp3",
                        "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                     }
                  ]
               }
            ]
         },
         {
            "id":"1234",
            "name":"targetObject2",
            "object":{
               "fileName":"mine.png",
               "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2Fmine.png?alt=media&token=85ebb759-1ebf-4525-a600-2216b8b881bf"
            },
            "activities":[]
         }
      ],
      "generalRules":[
         {
            "activity":{
               "trigger":{
                  "trigger":"onTimeEnd"
               },
               "actions":[
                  {
                     "action":"experienceFailed"
                  }
               ]
            }
         }
      ],
      "UIObjects":[
         {
            "tag":"button123",
            "type":"button",
            "locX":0.5,
            "locY":0.9,
            "height":70,
            "width":70,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
         },
         {
            "tag":"stats123",
            "type":"stats",
            "locX":0.5,
            "locY":0.1,
            "height":100,
            "width":100,
            "useTimer":true,
            "useScore":true,
            "useLevels":false,
            "maxTime":30,
            "maxScore":100
         },
         {
            "tag":"image1",
            "type":"image",
            "locX":0.5,
            "locY":0.5,
            "height":70,
            "width":70,
            "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
         }
      ]
   }
}
`