import styled from "styled-components";

interface FlexContainerProps {
  backgroundImage: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 90;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h1`
  color: white;
  font-weight: bolder;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  height: 90%;
  padding-bottom: 5vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
`;

export const ListItem = styled.div<{ isSelected: boolean }>`
  width: calc(50% - 10px);
  margin-bottom: 9%;
  box-sizing: border-box;
  border: ${(props) => (props.isSelected ? "2px solid green" : "none")};
`;

export const ImageWrapper = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ListItemName = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  word-wrap: break-word;
`;
