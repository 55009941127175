import { IRegistrationPage } from "../interfaces";
import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonContainer,
  FormContainer,
  FormGroup,
  Label,
} from './styled';
import { Button, Input, Checkbox } from "antd";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { CloudUploadOutlined, StepForwardOutlined, UploadOutlined } from "@ant-design/icons";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { ServerManager } from "../../../Managers/ServerManager";
import { AppStore } from "../../../app-store/store";
import { loadImage, resizeImage } from "../../../utils";

export interface IRegistrationResponse {
  fullName: string
  nickname: string
  email: string
  phoneNumber: string
}

const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const RegistrationPage: React.FC<IRegistrationPage> = ({ params, onPageComplete, onPageSkip }) => {

  const { globalMetadata } = useContext(AppStore)

  const [fullName, setFullName] = useState('');
  const [nickname, setNickname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phonenumberError, setPhonenumberError] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  useEffect(() => {
    Mixpanel.track(AnalyticsKeys.RegistrationScreen, {});
  }, []);

  useEffect(() => {
    if (fullName && nickname && phonenumber && email && check && (!params.uploadImage || (params.uploadImage && selectedImage))) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [fullName, nickname, phonenumber, email, check, selectedImage]);

  useEffect(() => {
    setEmailError(false);
  }, [email]);

  useEffect(() => {
    setPhonenumberError(false);
  }, [phonenumber]);

  const registerButtonTapped = () => {
    Mixpanel.track(AnalyticsKeys.ButtonTapped, {
      [AnalyticsEventPropertyKeys.buttonID]: 'Submit',
    });
    handleSubmit();
  };

  const handleSubmit = () => {
    const validateEmail = emailRegex.test(email);
    if (email.length && !validateEmail) {
      setEmailError(true);
    }
    if (phonenumber.length !== 10) {
      setPhonenumberError(true);
    } else {
      const response: IRegistrationResponse = {
        fullName,
        nickname,
        email,
        phoneNumber: phonenumber,
      };

      onPageComplete(response, selectedImage);
    }
  };

  const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormGroup>
        <Label>* Full Name</Label>
        <Input onChange={(e) => setFullName(e.target.value)} placeholder="Enter full name..." />
      </FormGroup>
      <FormGroup>
        <Label>* Display Name</Label>
        <Input onChange={(e) => setNickname(e.target.value)} placeholder="Enter display name..." />
      </FormGroup>
      <FormGroup>
        <Label>* Phone Number</Label>
        <Input
          type="number"
          status={phonenumberError ? 'error' : ''}
          prefix={phonenumberError ? <ClockCircleOutlined /> : null}
          onChange={(e) => setPhonenumber(e.target.value)}
          placeholder="Enter phone number..."
        />
      </FormGroup>
      <FormGroup>
        <Label>* Email</Label>
        <Input
          type="email"
          status={emailError ? 'error' : ''}
          prefix={emailError ? <ClockCircleOutlined /> : null}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email..."
        />
      </FormGroup>
      <Checkbox onChange={(event) => setCheck(event.target.checked)}>
        I would like to share my registration information with brands to receive offers and discounts
      </Checkbox>

      {params.uploadImage && (
        <FormGroup>
          <Label>{params.uploadImageDescription ?? "Upload Image"}</Label>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageSelection}
          />
        </FormGroup>
      )}

      <ButtonContainer>
        <Button
          onClick={registerButtonTapped}
          disabled={disabled}
          size="large"
          shape="round"
          icon={<CloudUploadOutlined />}
        >
          Submit
        </Button>
      </ButtonContainer>

      {params.skipEnabled && onPageSkip && (
        <ButtonContainer>
          <Button
            onClick={onPageSkip}
            size="large"
            shape="round"
            icon={<StepForwardOutlined />}
          >
            Skip
          </Button>
        </ButtonContainer>
      )}
    </FormContainer>
  );
};