import { Button } from "antd";
import styled from "styled-components";

export const MainWrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;


export const StyledButton = styled(Button)`
  height: 40px;
  background-color: rgb(18, 235, 207);
  color: #000000;
  border: 1px solid black;
  border-radius: 12px;
  font-weight: bolder;
  font-size: 1rem;
`;

export const CustomizedButton = styled.button<{
  primaryColor: string;
  secondaryColor: string;
}>`
  background: ${({ primaryColor, secondaryColor }) =>
    `linear-gradient(to right,${primaryColor},${secondaryColor})`};
  color: white;
  width:130px;
  height:40px;
  border-radius:10px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);
`;

export const LottieContainer = styled.div`
  width: 60%;
  height: auto;
  margin: auto;
  margin-top: 8%;
`;