import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, WhatsappIcon, TwitterIcon, FacebookIcon, InstapaperIcon, InstapaperShareButton } from 'react-share';
import { Button, Drawer, Space } from 'antd';
import { CloseOutlined, CopyFilled, ShareAltOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { AppStore } from '../app-store/store';

const title = 'Check out this amazing website!';

export const SocialShareButtons: React.FC = () => {
    const [showDrawer, setShowDrawer] = useState<boolean>(false)
    const { experienceUrl, setShowAlert } = useContext(AppStore)

    return (
        <>
            <Button style={{ position: 'absolute', top: 25, right: 25 }} onClick={() => setShowDrawer(true)} icon={<ShareAltOutlined />} />
            <Drawer
                title="Share via.."
                placement={'bottom'}
                closable={false}
                onClose={() => setShowDrawer(false)}
                open={showDrawer}
                size='large'
                height={'210px'}
                extra={
                    <Space>
                        <Button onClick={() => setShowDrawer(false)} style={{ border: 'none' }} icon={<CloseOutlined />} />
                    </Space>
                }
                styles={{
                    body: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center'
                    }
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <FacebookShareButton onClick={() => setShowDrawer(false)} style={{ border: 'none' }} url={experienceUrl} title={title}>
                        <div>
                            <FacebookIcon size={50} round />
                            Facebook
                        </div>
                    </FacebookShareButton>
                    <TwitterShareButton onClick={() => setShowDrawer(false)} style={{ border: 'none' }} url={experienceUrl} title={title}>
                        <div>
                            <TwitterIcon size={50} round />
                            Twitter
                        </div>
                    </TwitterShareButton>
                    <WhatsappShareButton onClick={() => setShowDrawer(false)} style={{ border: 'none' }} url={experienceUrl} title={title}>
                        <div>
                            <WhatsappIcon size={50} round />
                            WhatsApp
                        </div>
                    </WhatsappShareButton>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Button onClick={() => {
                            navigator.clipboard.writeText(`${experienceUrl}&source=share`)
                            setShowDrawer(false)
                            setShowAlert(true)
                            setTimeout(() => { setShowAlert(false) }, 2000)
                        }} style={{ width: '52px', height: '52px' }} icon={<CopyFilled style={{ fontSize: '2.2rem' }} />} shape="circle" />
                        Copy
                    </div>
                </div>
            </Drawer>
        </>
    );
};