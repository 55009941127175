import * as THREE from 'three';
import { NetworkARObjectDict } from '../../ExperienceObjects/ExperienceObject';

export enum PortalCategory {
    Spherical = "Spherical",
    Rectangular = "Rectangular"
}

export interface IPortalTemplateConfig {
    spherePortals: ISpherePortal[]
    rectanglePortals: IRectanglePortal[]
    portalGate?: NetworkARObjectDict
}

export class PortalTemplateConfig {
    spherePortals: SpherePortal[]
    rectanglePortals: RectanglePortal[]
    portalGate?: THREE.Object3D
    networkConfig: IPortalTemplateConfig

    constructor(spherePortals: SpherePortal[],
        rectanglePortals: RectanglePortal[],
        networkConfig: IPortalTemplateConfig,
        portalGate?: THREE.Object3D,) {
        this.spherePortals = spherePortals
        this.rectanglePortals = rectanglePortals
        this.portalGate = portalGate
        this.networkConfig = networkConfig
    }
}

interface ISpherePortal {
    textureImage: NetworkARObjectDict
    image: NetworkARObjectDict
    name: string
    description: string
    radius: number
    distFromUser: number
}

export class SpherePortal {
    textureImage: THREE.Texture
    image: NetworkARObjectDict
    name: string
    description: string
    radius: number
    distFromUser: number

    constructor(data: ISpherePortal, textureImage: THREE.Texture) {
        this.textureImage = textureImage
        this.image = data.image
        this.name = data.name
        this.description = data.description
        this.radius = data.radius
        this.distFromUser = data.distFromUser
    }
}

interface IRectanglePortal {
    scene: NetworkARObjectDict
    sceneImage: NetworkARObjectDict
    name: string
    description: string
    height: number
    width: number
    depth: number
    distFromUser: number
}
export class RectanglePortal {
    scene: THREE.Object3D
    sceneImage: NetworkARObjectDict
    name: string
    description: string
    height: number
    width: number
    depth: number
    distFromUser: number

    constructor(data: IRectanglePortal, scene: THREE.Object3D) {
        this.scene = scene
        this.sceneImage = data.sceneImage
        this.name = data.name
        this.description = data.description
        this.height = data.height
        this.width = data.width
        this.depth = data.depth
        this.distFromUser = data.distFromUser
    }
}