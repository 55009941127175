import styled from "styled-components";
import { Button } from "antd";

export const MainWrapper = styled.div`
  width: 95vw;
  height: 100vh;
`;

export const InnerContainer = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
`;

export const LeaderboardWrapper = styled.div`
  position: relative;
  flex: 0.8;
`;

export const RegisterButtonContainer = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  margin: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.2;
`;

export const StyledButton = styled(Button)`
  width: 80%;
  height: 40px;
  background-color: rgb(18, 235, 207);
  color: #000000;
  border: 1px solid black;
  border-radius: 12px;
  font-weight: bolder;
  font-size: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid gray;
  margin-top: 20px;
  box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45),
    25px 0 20px -20px rgba(0, 0, 0, 0.45);
  height: 1px;
`;

export const Paragraph = styled.p`
  font-weight: bolder;
  font-size: 0.9rem;
  line-height: 1px;
`;
