import { useContext, useEffect } from "react";
import { ICustomerResponse, IExperienceResponse } from "../../../Managers/ServerManager";
import { HomePageContainer, ExperienceList, Divider, LogoContainer } from "./styled";
import { AppStore } from "../../../app-store/store";
import { SocialShareButtons } from "../../ShareButton";
import { Card } from "antd";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
const { Meta } = Card;

export const HomePage = () => {
    const { allExperiences, experienceSelected, urlParams } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.HomeScreen, {
            [AnalyticsEventPropertyKeys.customerID]: urlParams.id
        })
    }, [])

    useEffect(() => {
        const numExperiences = allExperiences[0].experiences.length ?? -1
        Mixpanel.track(AnalyticsKeys.GotAllExperiences, {
            [AnalyticsEventPropertyKeys.NumberOfCustomerExperiences]: numExperiences
        })
    }, [allExperiences])

    const experienceTapped = (experience: IExperienceResponse) => {
        Mixpanel.track(AnalyticsKeys.ExperienceTapped, {
            [AnalyticsEventPropertyKeys.ExperienceID]: experience.experienceID
        })
        experienceSelected(experience.experienceID)
    }

    return (
        <HomePageContainer>
            <SocialShareButtons />
            {
                allExperiences.map((customer: ICustomerResponse, index: number) => (
                    <div key={index}>
                        <LogoContainer image={customer.businessDescription.logo} />
                        <ExperienceList>
                            {customer.experiences.map((experience: IExperienceResponse, expIndex: number) => (
                                <div key={expIndex}>
                                    <Card
                                        style={{ margin: '10px', border: 'none' }}
                                        cover={<img style={{ height: '200px', objectFit: 'contain', borderRadius: '40px', width: '200px', margin: 'auto' }} alt="example" src={experience.imageUrl} />}
                                        onClick={() => experienceTapped(experience)}
                                    >
                                        <Meta style={{ width: '100%', color: 'white' }} title={experience.name} />
                                    </Card>
                                    {
                                        expIndex < customer.experiences.length - 1 &&
                                        <Divider />
                                    }
                                </div>
                            ))}
                        </ExperienceList>
                    </div>
                ))
            }
        </HomePageContainer>
    );
}