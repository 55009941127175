import { useContext, useEffect, useState } from "react";
import "./style.css";
import { AppStore } from "../../app-store/store";

export const StatsComponent: React.FC<{ seconds: number, score: number, timeDelta: number, callback: () => void, scoreChangeCallback: (newScore: number) => void }> = ({ seconds, score, timeDelta, callback, scoreChangeCallback }) => {
    const [timer, setTimer] = useState(seconds);
    const [lastTimeDelta, setLastTimeDelta] = useState(0)
    let { setExperienceScore } = useContext(AppStore)

    useEffect(() => {
        if (score > 0) {
            setExperienceScore(() => score)
            scoreChangeCallback(score)
        }
    }, [score])

    useEffect(() => {
        if (timeDelta !== 0) {
            setTimer((prevTime) => {
                return prevTime + timeDelta - lastTimeDelta
            })
            setLastTimeDelta(timeDelta)
        }
    }, [timeDelta])

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timerInterval);
                    callback()
                }
                return prevTimer - 1;
            });
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, []);

    return (
        <div className="stats-container">
            <div className="timer-container">
                <p className="stats-title">Time</p>
                <p>{timer}</p>
            </div>
            <div className="score-container">
                <p className="stats-title">Score</p>
                <p>{score}</p>
            </div>
        </div>
    );
}

