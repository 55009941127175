import React, { useContext, useEffect } from 'react';
import { ILeaderboardPage } from '../interfaces';
import * as Styled from './styles';
import { ExperienceStore } from '../../../app-store/experienceStore';
import { AppStore } from '../../../app-store/store';
import { Mixpanel } from '../../../Managers/AnalyticsClient/AnalyticsClient';
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from '../../../Managers/AnalyticsClient/AnalyticsKeys';

export interface LeaderboardEntry {
    id?: string;
    userID: string;
    email?: string;
    nickname: string;
    fullName: string
    score: number;
    timestamp?: any;
    phoneNumber: string
}

enum ROW_COLOR {
    ME = "lightblue",
    NOT_ME = "rgb(255, 255, 255)"
}

export const LeaderboardPage: React.FC<ILeaderboardPage> = ({ leaderboardEntries }) => {

    const { experienceResponse } = useContext(ExperienceStore)
    const { globalTheme } = useContext(AppStore)
    const sortedEntries = leaderboardEntries.sort((a, b) => b?.score - a?.score);
    const topThree = sortedEntries.slice(0, 3);
    const rest = sortedEntries.slice(3, 10);

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.LeaderboardScreen, {
            [AnalyticsEventPropertyKeys.GameScore]: experienceResponse.stats.score
        })
      }, [])

    return (
        <Styled.StyledLeaderboardContainer>
            <div className="leaderboard-container">
                <Styled.PodiumContainer>
                    <Styled.LogoContainer>
                        <img src={globalTheme.imageUrl} />
                        {/* <p>Score: {experienceResponse.stats.score} pts</p> */}
                    </Styled.LogoContainer>
                    <Styled.Podium>
                        <Styled.PodiumColumnContainer>
                            <p><b>{topThree[1]?.nickname}</b></p>
                            <b style={{ fontSize: '1.2rem' }}>{`${topThree[1] ? topThree[1].score + "pts" : ""}`}</b>
                            <Styled.PodiumColumn height={120} bgColor="#646464">
                                <p><b>2</b></p>
                            </Styled.PodiumColumn>
                        </Styled.PodiumColumnContainer>

                        <Styled.PodiumColumnContainer>
                            <p><b>{topThree[0]?.nickname}</b></p>
                            <b style={{ fontSize: '1.2rem' }}>{`${topThree[0] ? topThree[0].score + "pts" : ""}`}</b>
                            <Styled.PodiumColumn height={170} bgColor="#969696">
                                <p><b>1</b></p>
                            </Styled.PodiumColumn>
                        </Styled.PodiumColumnContainer>

                        <Styled.PodiumColumnContainer>
                            <p><b>{topThree[2]?.nickname}</b></p>
                            <b style={{ fontSize: '1.2rem' }}>{`${topThree[2] ? topThree[2].score + "pts" : ""}`}</b>
                            <Styled.PodiumColumn height={100} bgColor="#323232">
                                <p><b>3</b></p>
                            </Styled.PodiumColumn>
                        </Styled.PodiumColumnContainer>
                    </Styled.Podium>
                </Styled.PodiumContainer>

                <Styled.LeaderboardRows>
                    {rest.map((entry, index) => (
                        <Styled.LeaderboardRow color={entry.userID === localStorage.getItem('user-session') ? ROW_COLOR.ME : ROW_COLOR.NOT_ME} key={index}>
                            <p><b>{index + 4}.</b></p>
                            <p>{entry.nickname}</p>
                            <p><b>{entry.score.toFixed(0)}</b></p>
                        </Styled.LeaderboardRow>
                    ))}
                </Styled.LeaderboardRows>
            </div>
        </Styled.StyledLeaderboardContainer>
    );
};