import { UIElementProps } from "../../Managers/UIManager";

export class GridMetaTemplateProps {
    numRows: number
    numCols: number
    numLayers: number
    height: number
    width: number
    depth: number
    curved: boolean
    cellShape: GridCellShape
    cellSpacing: number
    distToCenter: number

    FOV_X: number
    FOV_Y: number
    radius: number

    constructor(
        numRows: number = 15,
        numCols: number = 15,
        numLayers: number = 1,
        height: number = 2,
        width: number = 2,
        depth: number = 0.1,
        curved: boolean = false,
        cellShape: GridCellShape = GridCellShape.cube,
        cellSpacing: number = 0.01,
        distToCenter: number = 2,
        FOV_X: number = 180,
        FOV_Y: number = 60,
        radius: number = 1
    ) {
        this.numRows = numRows
        this.numCols = numCols
        this.numLayers = numLayers
        this.height = height
        this.width = width
        this.depth = depth
        this.curved = curved
        this.cellShape = cellShape
        this.cellSpacing = cellSpacing
        this.distToCenter = distToCenter

        this.FOV_X = FOV_X
        this.FOV_Y = FOV_Y
        this.radius = radius
    }
}

export enum GridCellShape {
    cube = "cube",
    sphere = "sphere"
}

export class GridCellIdx {
    row: number
    col: number
    layer: number

    constructor(layer: number, row: number, col: number) {
        this.row = row
        this.col = col
        this.layer = layer
    }
}

export class GridFullExperience {
    gridProps: GridMetaTemplateProps
    UIObjects: UIElementProps[] = [];

    constructor(gridProps: GridMetaTemplateProps,UIObjects: UIElementProps[]) {
        this.gridProps = gridProps
        this.UIObjects = UIObjects
    }
}