import { NetworkARObjectDict } from "../../ExperienceObjects/ExperienceObject";

export class ShootersListViewTheme {
    backgroundImageUrl: string
    logo: string
    title: string

    constructor(inputParams: Record<string, any>) {
        Object.assign(this, inputParams);
    }
}

export class BaselBurgerShootersConfig {
    shootersItems: ShootersItem[]
    shootersListViewTheme: ShootersListViewTheme

    constructor(
        shootersItems: ShootersItem[],
        shootersNetworkConfig: BaselBurgerShootersNetworkConfig
        ) {
        this.shootersItems = shootersItems
        this.shootersListViewTheme = shootersNetworkConfig.shootersListViewTheme
    }
}

export class BaselBurgerShootersNetworkConfig {
    shootersItems: ShootersNetworkItem[]
    shootersListViewTheme: ShootersListViewTheme

    constructor(data: Record<string, any>) {
        this.shootersItems = data.shootersItems.map((itemData: Record<string, any>) => {
            return new ShootersNetworkItem(
                // itemData.object,
                itemData.objectImage,
                itemData.objectName
            );
        });
        this.shootersListViewTheme = new ShootersListViewTheme(data.shootersListViewTheme)
    }
}

export class ShootersItem {
    object: THREE.Object3D;
    // objectID: string;
    objectImage: NetworkARObjectDict;
    objectName: string;

    constructor(
        object: THREE.Object3D,
        menuNetworkItem: ShootersNetworkItem
    ) {
        this.object = object;
        // this.objectID = menuNetworkItem.objectID
        this.objectImage = menuNetworkItem.objectImage
        this.objectName = menuNetworkItem.objectName;
    }
}

export class ShootersNetworkItem {
    // object: NetworkARObjectDict;
    // objectID: string
    objectImage: NetworkARObjectDict;
    objectName: string;

    constructor(
        // object: NetworkARObjectDict,
        objectImage: NetworkARObjectDict,
        objectName: string
    ) {
        // this.object = object;
        // this.objectID = uuidv4()
        this.objectImage = objectImage;
        this.objectName = objectName;
    }
}