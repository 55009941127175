import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { AppStoreProvider } from './app-store/store.tsx'
import React from 'react'
import * as Sentry from "@sentry/react";
import { ENV } from './Configs/ServerConfig.ts'

if (ENV !== "local") {
  Sentry.init({
    dsn: "https://ee63fc1198b5d8620937b3b23e19899e@o4507463365689344.ingest.de.sentry.io/4507938672279632",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["arplatform.orb-ar.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppStoreProvider>
      <App />
    </AppStoreProvider>
  </React.StrictMode>
)
