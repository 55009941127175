import { useContext, useEffect, useRef, useState } from "react";
import { PlatformManager } from "../../../Templates/CustomTemplate/PlatformManager";
import { ARSurveyTemplate } from "../../../Templates/ARSurveyTemplate/ARSurveyTemplate";
import { Button } from "antd";
import { CloseOutlined, AppleOutlined, CodepenOutlined, CodepenCircleOutlined } from '@ant-design/icons'
import { LottieContainer, StatsContainer } from "./styled";
import { CloseButtonModal } from "../../utils/CloseButtonModal";
import { IExperiencePage } from "../interfaces";
import { ServerManager } from "../../../Managers/ServerManager";
import { ITemplatesBasics } from "../../../Templates/TemplatesInterface";
import { servicesUrlsByName } from "../../../Configs/ServerConfig";
import { PlayCircleOutlined } from "@ant-design/icons";
// import * as SM from 'soundmanager2';
import { SoundManager } from "../../../Managers/SoundManager";
import { TriggerManager } from '../../../TriggerAction/TriggerManager';
import { ARManager } from "../../../Managers/ARManager"
import { ORBImageUI, UIManager } from '../../../Managers/UIManager';
import { BrandThemeManager } from '../../../Managers/BrandThemeManager';
import { MenuTemplate } from "../../../Templates/MenuTemplate/MenuTemplate";
import { BaselBurgerShootersTemplate } from "../../../Templates/BaselBurgerShooters/BaselBurgerShooters";
import { ExperienceAnalyticsManager } from "../../../Managers/ExperienceAnalyticsManager";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsKeys, AnalyticsEventPropertyKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { ComponentOptions, MenuListView, MenuListViewTheme } from "../../../Templates/MenuTemplate/menu-list-component/MenuListView";
import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom';

import Lottie from 'lottie-react';
import loadingLottie from '../../../assets/Lottie/ORB_Intro1.json';
import { LoadingPage } from "../loading-page/LoadingPage";
import { MenuItem } from "../../../Templates/MenuTemplate/MenuTemplateInput";
import * as Paths from '../../../Configs/ServerConfig'
import { BaselBurgerListItem, BaselBurgerSelectionScreen } from "../../../Templates/BaselBurgerShooters/basel-burger-shooters-list-component/BaselBurgerShootersListView";
import { ExperienceType } from "../../../TriggerAction/Config";
import { AppStore } from "../../../app-store/store";
import { ErrorType } from "../error-page/errorTypes";
import { StatsComponent } from "../../../UIElements/progress-circle/StatsComponent";
import { ShootersListViewTheme } from "../../../Templates/BaselBurgerShooters/BaselBurgerShootersInput";
import { GridFullExperience, GridMetaTemplateProps } from "../../../Templates/GridMetaTemplate/GridExperienceObject";
import { GridMetaTemplate } from "../../../Templates/GridMetaTemplate/GridMetaTemplate";
import { observer } from "mobx-react-lite";
import { bool, timerDelta } from "three/examples/jsm/nodes/Nodes.js";
import { IndoorDesignTemplate } from "../../../Templates/IndoorDesign/IndoorDesignTemplate";
import { IJoystickSlider, JoystickController, JoystickSliderOptions } from "../../../UIElements/JoystickComponent";
import { PhotoAlbum } from "../../../Templates/PhotoAlbumTemplate/PhotoAlbum";
import { PortalTemplate } from "../../../Templates/PortalTemplate/PortalTemplate";

export enum SessionStatus {
    ExperienceCompleted = 0,
    ExperienceNotCompleted = 1,
    ExperienceClosed = 2,
    ARSessionRequested = 3,
    ARSessionInitialized = 4,
    ARSessionStabilized = 5,
    ARSessionEnded = 6
}

export enum LottieNames {
    scanArea = "scanArea",
    loadingOrb = "ORB_Intro1",
    loading = "loading",
    failure = "failure",
    scanPlane = "scanPlane",
    tapScreen = "tapScreen",
    taskCompleted = "TaskCompleted",
    taskFailed = "TaskFailed",
    gameOver = "GameOver"
}

export enum GameResultType {
    award = "award",
    noAward = "noAward",
    taskComplete = "taskComplete",
    taskIncomplete = "taskIncomplete",
    taskQuit = "taskQuit"
}

export class GameStats {
    score: number
    level: number | null
    time: number | null

    constructor(score: number, level: number | null = null, time: number | null = null) {
        this.score = score
        this.level = level
        this.time = time
    }
}

export class ExperienceEndResponse {
    gameResultType: GameResultType
    allowRetry: boolean
    stats: GameStats | null
    analytics: Record<string, any> | null

    constructor(gameResultType: GameResultType, stats: GameStats, allowRetry: boolean = true, analytics: Record<string, any> | null = null) {
        this.gameResultType = gameResultType
        this.stats = stats
        this.allowRetry = allowRetry
        this.analytics = analytics
    }
}


export const ExperiencePage: React.FC<IExperiencePage> = ({ jsonObject, metadata, theme, onPageComplete }) => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const app = useRef<ITemplatesBasics | null>(null)
    const [score, setScore] = useState(0);
    const [timeDelta, setTimeDelta] = useState(0);
    const [statsParams, setStatsParams] = useState<{ status: boolean, initTime: number, callBackFun: () => void, scoreChangeCallback: (newScore: number) => void }>({ status: false, initTime: 0, callBackFun: () => undefined, scoreChangeCallback: (score) => undefined })

    const { setErrorType, setErrorMessage, setShowErrorPage, experienceScore, userSession } = useContext(AppStore)
    const [lottieData, setLottieData] = useState<any>(null);
    const [showJoystickButton, setShowJoystickButton] = useState<boolean>(false);
    const [showJoystick, setShowJoystick] = useState<boolean>(false);

    var uiManager: UIManager | null = null

    useEffect(() => {
        initiateAR();
        Mixpanel.track(AnalyticsKeys.ExperienceScreen, {
            [AnalyticsEventPropertyKeys.ExperienceID]: metadata.experienceID,
            [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType,
        })
        return () => {
            app.current = null
        }
    }, [])

    const experienceStartButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Start"
        })
        app.current.launchAR()
    }

    const experienceCloseButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        sessionStatusChanged(SessionStatus.ExperienceClosed)
    }

    const initiateAR = async () => {
        let template = await createTemplateInstance()
        Mixpanel.track(AnalyticsKeys.TemplateInitialized, { [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType })
        app.current = template
        setIsLoading(false)
        // app.current.launchAR()
    }

    const createTemplateInstance = async (): Promise<ITemplatesBasics> => {
        const triggerManager = new TriggerManager()
        uiManager = new UIManager(triggerManager, setStatsParams, setScore, setTimeDelta, showInstructionsLottie.bind(this))
        const soundManager = new SoundManager()
        const userID: string = userSession
        const experienceAnalyticsManager = new ExperienceAnalyticsManager(metadata, userID)
        var brandThemeManager: BrandThemeManager | null = null
        if (theme) {
            brandThemeManager = new BrandThemeManager(theme)
        }
        const manager = new ARManager(triggerManager, brandThemeManager, sessionStatusChanged.bind(this), showInstructionsLottie.bind(this), setShowJoystickButton.bind(this))
        switch (metadata.experienceType) {
            case ExperienceType.SURVEY: {
                const surveyInput = await ARSurveyTemplate.parseInput(jsonObject)
                const surveyTemplate = new ARSurveyTemplate(surveyInput, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                return surveyTemplate
            }
            case ExperienceType.SHOOTERSTEMPLATE:
            case ExperienceType.BUILDYOUROWN: {
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject)
                const platformManager = new PlatformManager(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                return platformManager
            }
            case ExperienceType.MENUTEMPLATE: {
                // Load template input
                const menuConfig = await MenuTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const menuTemplate = new MenuTemplate(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                menuTemplate.config = menuConfig
                menuTemplate.menuListComponentHandler = menuListComponentHandler

                return menuTemplate
            }
            case ExperienceType.BASELBURGERSHOOTERS: {
                // Load template input
                const config = await BaselBurgerShootersTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])

                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new BaselBurgerShootersTemplate(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config
                template.itemSelectionListComponentHandler = itemSelectedComponentHandler

                return template
            }
            case ExperienceType.GRIDTEMPLATE: {
                const gridProps = new GridMetaTemplateProps()
                const imageElement = new ORBImageUI({
                    "tag": "image1",
                    "type": "image",
                    "locX": 0.5,
                    "locY": 0.5,
                    "height": 70,
                    "width": 70,
                    "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
                })
                imageElement.loadData(() => { })
                const uiElements = [imageElement]
                const gridFullExperience = new GridFullExperience(gridProps, uiElements)

                const template = new GridMetaTemplate(gridFullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))

                return template
            }
            case ExperienceType.INDOORDESIGN: {
                // Load template input
                const config = await IndoorDesignTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])

                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new IndoorDesignTemplate(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            case ExperienceType.PHOTOALBUM: {
                // Load template input
                const config = await PhotoAlbum.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])

                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new PhotoAlbum(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            case ExperienceType.PORTALTEMPLATE: {
                // Load template input
                const config = await PortalTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])

                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new PortalTemplate(fullExperience, triggerManager, manager, uiManager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            default:
                setErrorType(ErrorType.BadIDType)
                setErrorMessage(`${metadata.experienceType} bad experience type!`)
                setShowErrorPage(true)
        }
    }

    const sessionStatusChanged = (status: SessionStatus) => {
        console.log("ExperiencePage: session status changed", status)
        switch (status) {
            case SessionStatus.ExperienceCompleted:
            // Fall-through
            case SessionStatus.ExperienceNotCompleted:
            // Fall-through
            case SessionStatus.ExperienceClosed:
                experienceOver(status)
                break;
            case SessionStatus.ARSessionRequested:
                // console.log("AR SESSION STATUS: REQUESTED")
                break;
            case SessionStatus.ARSessionInitialized:
                uiManager?.placeLottie(LottieNames.scanArea)
                console.log("AR SESSION STATUS: INITIALIZED")
                break;
            case SessionStatus.ARSessionStabilized:
                console.log("AR SESSION STATUS: STABILIZED")
                uiManager?.placeLottie(null)
                Mixpanel.track(AnalyticsKeys.ARStabilized)
                app.current.startExperience()
                break;
            case SessionStatus.ARSessionEnded:
                console.log("AR SESSION STATUS: ENDED")
                experienceOver(SessionStatus.ExperienceClosed)
                break;
        }
    }

    const experienceOver = (sessionStatus: SessionStatus) => {
        // If the app.current is null it means that this function has already been called.
        // Example: in case the user exits via the button, then experience over is called, then sessionStatusChanged is called with ARSessionEnded, which again calls experienceOver.
        if (app.current === null) {
            return
        }

        Mixpanel.track(AnalyticsKeys.ExperienceDone, { [AnalyticsEventPropertyKeys.ExperienceDoneStatus]: String(sessionStatus) })
        // handleExperienceAnalytics(sessionStatus)
        // TODO: (Ibrahim) better define session status
        handleExperienceAnalytics(SessionStatus.ExperienceCompleted)
        app.current.cleanup()

        setStatsParams({
            status: false,
            initTime: 0,
            callBackFun: () => undefined,
            scoreChangeCallback: (score) => undefined
        })

        const gameStats = new GameStats(experienceScore)
        switch (sessionStatus) {
            case SessionStatus.ExperienceCompleted:
                var response = new ExperienceEndResponse(GameResultType.taskComplete, gameStats)
                onPageComplete(response)
                break;
            case SessionStatus.ExperienceNotCompleted:
                var response = new ExperienceEndResponse(GameResultType.taskIncomplete, gameStats)
                onPageComplete(response)
                break;
            case SessionStatus.ExperienceClosed:
                var response = new ExperienceEndResponse(GameResultType.taskQuit, gameStats)
                onPageComplete(response)
                break;
        }
    }

    const showInstructionsLottie = async (lottieName: string | null) => {
        if (lottieName === null) {
            // Remove the lottie view
            setLottieData(null)
        } else {
            const response = await fetch(`/assets/Lottie/${lottieName}.json`);
            const data = await response.json();
            setLottieData(data)
        }
    }

    const handleExperienceAnalytics = (sessionStatus: SessionStatus) => {
        const analytics = app.current.prepareFullAnalytics(sessionStatus)
        console.log("ANALYTICSSS", analytics)
        // const jsonString = JSON.stringify(analytics)
        ServerManager.uploadJsonFile(analytics, servicesUrlsByName.analytics, `/analytics`)
    }

    const menuListComponentHandler = (option: ComponentOptions, list: MenuItem[], menuTheme: MenuListViewTheme, callback?: (id: string | number, index: number) => void) => {
        switch (option) {
            case ComponentOptions.add:
                {
                    if (list.length && callback) {
                        const menuWrapper = document.createElement('div')
                        menuWrapper.className = 'menu-wrapper'
                        document.getElementById('overlay-container').appendChild(menuWrapper)
                        createRoot(menuWrapper).render(<MenuListView menuList={list} menuTheme={menuTheme} onItemClick={callback} />)
                    }
                    break;

                }
            case ComponentOptions.remove:
                {
                    const menuWrapper = document.querySelector('.menu-wrapper');
                    if (menuWrapper) {
                        ReactDOM.unmountComponentAtNode(menuWrapper);
                        menuWrapper.remove();
                    }
                    break;
                }
            case ComponentOptions.present:
                {
                    const menuWrapper = document.querySelector('.menu-wrapper') as HTMLElement | null;;
                    if (menuWrapper) {
                        menuWrapper.style.display = 'block';
                    }
                    break;
                }
            case ComponentOptions.hide:
                {
                    const menuWrapper = document.querySelector('.menu-wrapper') as HTMLElement | null;;
                    if (menuWrapper) {
                        menuWrapper.style.display = 'none';
                    }
                    break;
                }
        }
    }

    const itemSelectedComponentHandler = (option: ComponentOptions, list: BaselBurgerListItem[], theme: ShootersListViewTheme, callback?: (id: string | number, index: number) => void) => {
        switch (option) {
            case ComponentOptions.add:
                {
                    if (list.length && callback) {
                        const wrapper = document.createElement('div')
                        wrapper.className = 'item-selected-wrapper'
                        document.getElementById('overlay-container').appendChild(wrapper)
                        createRoot(wrapper).render(<BaselBurgerSelectionScreen list={list} theme={theme} onItemClick={callback} />)
                    }
                    break;

                }
            case ComponentOptions.remove:
                {
                    const wrapper = document.querySelector('.item-selected-wrapper');
                    if (wrapper) {
                        ReactDOM.unmountComponentAtNode(wrapper);
                        wrapper.remove();
                    }
                    break;
                }
            case ComponentOptions.present:
                {
                    const wrapper = document.querySelector('.item-selected-wrapper') as HTMLElement | null;;
                    if (wrapper) {
                        wrapper.style.display = 'block';
                    }
                    break;
                }
            case ComponentOptions.hide:
                {
                    const wrapper = document.querySelector('.item-selected-wrapper') as HTMLElement | null;;
                    if (wrapper) {
                        wrapper.style.display = 'none';
                    }
                    break;
                }
        }
    }

    const joystickButtonTapped = () => {
        setShowJoystick((prev) => {
            return (!prev)
        })
    }

    const joystickChange = (joystickIdx: JoystickSliderOptions) => (value) => {
        // console.log("@@@ changeee", joystickIdx, value)
    }

    const rotateX: IJoystickSlider = {
        minValue: -90,
        maxValue: 90,
        length: 200,
    };
    const rotateY: IJoystickSlider = {
        minValue: -90,
        maxValue: 90,
        length: 200,
    };
    const rotateZ: IJoystickSlider = {
        minValue: -90,
        maxValue: 90,
        length: 200,
    };
    const moveX: IJoystickSlider = {
        minValue: -100,
        maxValue: 100,
        length: 200,
    };
    const moveY: IJoystickSlider = {
        minValue: -100,
        maxValue: 100,
        length: 200,
    };
    const moveZ: IJoystickSlider = {
        minValue: -100,
        maxValue: 100,
        length: 200,
    };

    return (
        <>
            <div>
                <div id="overlay-container">
                    {
                        showJoystickButton && <div style={{
                            position: 'fixed',
                            top: '50%',
                            right: '20px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Button type="primary" icon={<CodepenCircleOutlined />} onClick={joystickButtonTapped} />
                        </div>
                    }
                    {
                        showJoystick && <JoystickController
                            rotateX={rotateX}
                            rotateY={rotateY}
                            rotateZ={rotateZ}
                            moveX={moveX}
                            moveY={moveY}
                            moveZ={moveZ}
                            onChange={joystickChange}
                        />
                    }
                    {
                        statsParams.status &&
                        <StatsComponent
                            seconds={statsParams.initTime}
                            score={score}
                            timeDelta={timeDelta}
                            callback={() => statsParams.callBackFun()}
                            scoreChangeCallback={(score: number) => statsParams.scoreChangeCallback(score)} />
                    }
                    {
                        lottieData !== null &&
                        <LottieContainer id="lottie-container">
                            <Lottie animationData={lottieData} />
                        </LottieContainer>
                    }
                    <div>
                        <canvas
                            id="touch-canvas"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: -1,
                            }}
                        />
                    </div>
                    {
                        openConfirmModal ?
                            <CloseButtonModal closeModal={() => setOpenConfirmModal(false)} handleCloseExperience={() => experienceCloseButtonTapped()} /> : null
                    }
                    <Button
                        onClick={() => setOpenConfirmModal(true)}
                        style={{
                            position: 'absolute',
                            top: "20px",
                            zIndex: 99,
                            left: "20px",
                            color: 'black',
                            backgroundColor: "#C0C0C0",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }} shape="circle" icon={<CloseOutlined />} />
                </div>
                {
                    isLoading ? <LoadingPage lottieFileName={LottieNames.loadingOrb} /> :
                        <Button style={{ backgroundColor: "#00DED1", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }} onClick={() => experienceStartButtonTapped()} shape="round" size="large" icon={<PlayCircleOutlined />}>
                            Start experience
                        </Button>
                }
            </div>
        </>
    )
}

