import { useContext, useEffect, useState } from "react"
import { MainWrapper, CustomButton } from "./styled"
import { AppStore } from "../../../app-store/store"
import { SocialShareButtons } from "../../ShareButton"
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient"
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys"
import { ENV } from "../../../Configs/ServerConfig"

enum BrowserType {
    Chrome = "Chrome",
    SamsungInternet = "SamsungInternet",
    Unknown = "Unknown"
}

export const IntroScreen = () => {

    const [isSupported, setIsXrSupported] = useState<boolean | null>(null)
    const [browserType, setBrowserType] = useState<BrowserType | null>(null)
    const [showPolicy, setShowPolicy] = useState<boolean>(true)
    const { loading, handleIntroPageComplete, introDescription, introTitle } = useContext(AppStore)

    useEffect(() => {
        const policyAccepted = localStorage.getItem('policyAccepted') ?? false
        Mixpanel.track(AnalyticsKeys.IntroScreen, {
            [AnalyticsEventPropertyKeys.PolicyAccepted]: policyAccepted.toString()
        })

        if (policyAccepted) {
            setShowPolicy(false)
        }
        checkBrowserType()
        checkWebXRSupport()
    }, [])

    useEffect(() => {
        if (browserType !== null && isSupported !== null) {
            Mixpanel.track(AnalyticsKeys.BrowserCompatibility, {
                [AnalyticsEventPropertyKeys.BrowserName]: browserType,
                [AnalyticsEventPropertyKeys.XRCompatibility]: isSupported ? "Supported" : "Not Supported",
            })
        }

    }, [browserType, isSupported])

    const checkWebXRSupport = async (): Promise<boolean> => {
        if ("xr" in navigator) {
            const supported = await (navigator as any).xr.isSessionSupported(
                "immersive-ar"
            );
            if (supported) {
                setIsXrSupported(true)
                return
            }
        }
        setIsXrSupported(false)
        return;
    }

    const checkBrowserType = () => {
        const isChrome = /Chrome/.test(navigator.userAgent) 
        && /Google Inc/.test(navigator.vendor) 
        && !/Samsung/.test(navigator.userAgent);

        const isSamsungInternet = /SamsungBrowser/i.test(navigator.userAgent);

        if (isChrome) {
            setBrowserType(BrowserType.Chrome)
        } else if (isSamsungInternet) {
            setBrowserType(BrowserType.SamsungInternet)
        } else {
            setBrowserType(BrowserType.Unknown)
        }

        // // In dev mode pretend it is a chrome browser so we can test if it wowrks
        // if (ENV === "dev") {
        //     setBrowserType("Chrome")
        // }
    }

    const startButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Start"
        })
        handleIntroPageComplete()
    }

    return (
        <MainWrapper>
            <div>
                <SocialShareButtons />
                {
                    <div>
                        <img style={{ width: '220px', height: '220px' }} src='https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2Forbarlogo.png?alt=media&token=1421b3de-c97e-475e-9ef2-49b9cf662092' />
                        {/* <p>Launch exciting augmented reality experiences created by the Orb AR platform.</p> */}
                        <h2>{introTitle}</h2>
                        <p>{introDescription}</p>
                    </div>
                }
                {
                    isSupported === null || browserType === null ?
                        <p>Checking browser support...</p> : null
                }
                {
                    // Unknown browser, or samsung internet in production
                    browserType === BrowserType.Unknown || (ENV === "prod" && browserType === BrowserType.SamsungInternet) ?
                        <p style={{ color: 'red' }}>Please open this page in a <b>Chrome</b> browser on an <b>Android</b> device.</p> : null
                }
                {
                    // If good browser but for some reason it is not supported
                    (browserType === BrowserType.Chrome || browserType === BrowserType.SamsungInternet) && !isSupported ?
                        <div>
                            <p style={{ color: 'red' }}>Sorry, this browser does not support webXR technology.</p>
                            <p style={{ color: 'blue' }}>Please open using <b>Chrome</b> browser on an <b>Android</b> device</p>
                        </div> : null
                }
                {
                    // Either a chrome browser, or a samsung internet browser in dev mode
                    (browserType === BrowserType.Chrome || (ENV === "dev" && browserType === BrowserType.SamsungInternet)) && isSupported ? <div>
                        <CustomButton onClick={startButtonTapped} shape="round" type="primary" size="large" loading={loading}>
                            Start
                        </CustomButton>
                        {
                            showPolicy &&
                            <p style={{ fontSize: '0.7rem' }}>By clicking 'Start', I agree to the <a target="_blank" href="https://orb-legal.web.app/privacyPolicy">Privacy Policy</a> and <a target="_blank" href="https://orb-legal.web.app/termsOfUse">Terms of Use</a> of Orb Mobile App</p>
                        }
                    </div> : null
                }
            </div>
        </MainWrapper>
    )
}