import { BrandTheme } from "../ExperienceObjects/ExperienceObject"

export class BrandThemeManager {
    brandTheme: BrandTheme

    constructor(brandTheme: BrandTheme) {
        this.brandTheme = brandTheme
    }

    cleanup() {
        
    }

    getPrimaryColor(): string {
        return this.brandTheme.primaryColor
    }

    getSecondaryColor(): string {
        return this.brandTheme.secondaryColor
    }

    getBrandLogoUrl(): string {
        return this.brandTheme.imageUrl
    }
}