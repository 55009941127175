import { Howl, Howler } from 'howler';

interface ISoundManager {
    cleanup(): void
    createSound(id: string, path: string): void
    playSound(id: string): void
    stopAllSounds(): void
}

export class SoundManager implements ISoundManager {

    sounds: Record<string, Howl> = {}

    cleanup() {
        this.stopAllSounds()
    }

    createSound(id: string, path: string) {
        const sound = new Howl({
            src: [path],
            autoplay: false,
            loop: false,
            preload: true,
            volume: 0.5,
        });

        if (sound !== null) {
            this.sounds[id] = sound
        }
    }

    playSound(id: string) {
        if (id in this.sounds) {
            const sound = this.sounds[id]
            sound?.play()

            // sound?.on('end', function () {
            //     delete this.sounds[id]
            // });
        }
    }

    stopAllSounds() {
        for (const [id, sound] of Object.entries(this.sounds)) {
            sound.stop()
            sound.unload()
        }
    }
}