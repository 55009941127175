import * as THREE from "three";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import {
  ARObjectSettings,
  DelayedARObject,
} from "../../ExperienceObjects/ExperienceObject";
import { ITemplatesBasics } from "../TemplatesInterface";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import { MenuConfig, MenuItem, MenuNetworkConfig } from "./MenuTemplateInput";
import { v4 as uuidv4 } from "uuid";
import {
  ComponentOptions,
  MenuListViewTheme,
} from "./menu-list-component/MenuListView";
import { LottieNames } from "../../components/pages";
import { ORBLottieUI } from "../../Managers/UIManager";
import { SpinParams } from "../../Managers/ObjectActionManager";

interface IMenuTemplate extends ITemplatesBasics {}

export class MenuTemplate extends PlatformManager implements IMenuTemplate {
  config: MenuConfig;

  placedItemIdx: number | null = null;
  placedItemTransform: THREE.Matrix4 | null = null;
  placedItem: THREE.Object3D | null = null;
  placedItemTxt: THREE.Object3D | null = null;
  placedLottieObject: THREE.Object3D | null = null;

  // isMenuPresented: boolean = false

  menuListComponentHandler: (
    option: ComponentOptions,
    list: MenuItem[],
    menuTheme: MenuListViewTheme,
    callback?: (id: string | number, index: number) => void
  ) => void;

  // menuListItems: MenuListItem[] = []
  menuListItemsNumSelection: Record<number, number> = {};
  menuListItemsTotalDwellTime: Record<number, number> = {};
  itemSetTime: number | null = null;

  ///////////// Parse input json //////////////////
  static async parseInput(json: Record<string, any>): Promise<MenuConfig> {
    const menuNetworkConfig = new MenuNetworkConfig(json);
    console.log("MenuNetworkConfig(json)", menuNetworkConfig);

    const menuItems: MenuItem[] = [];

    for (const menuNetworkItem of menuNetworkConfig.menuItems) {
      const delayedObject = new DelayedARObject(menuNetworkItem.object);
      const menuItem = new MenuItem(delayedObject, menuNetworkItem);
      menuItems.push(menuItem);
    }

    const menuConfig = new MenuConfig(menuItems, menuNetworkConfig);
    return menuConfig;
  }

  getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
    this.updateCurrentItemTime();

    const key: string = "experienceAnalytics";
    const analyticsDict: Record<string, any> = {};

    const selectedItemDwellTime: Record<string, number> = {};
    const itemNumSelections: Record<string, number> = {};
    for (let itemIdx = 0; itemIdx < this.config.menuItems.length; itemIdx++) {
      const menuItem = this.config.menuItems[itemIdx];
      selectedItemDwellTime[menuItem.objectName] =
        this.menuListItemsTotalDwellTime[itemIdx];
      itemNumSelections[menuItem.objectName] =
        this.menuListItemsNumSelection[itemIdx];
      // analyticsDict[menuItem.objectName] = this.menuListItemsNumSelection[itemIdx];
      // analyticsDict[menuItem.objectName] = this.menuListItemsTotalDwellTime[itemIdx];
    }

    analyticsDict["selectedItemDwellTime"] = selectedItemDwellTime;
    analyticsDict["itemNumSelections"] = itemNumSelections;

    return [analyticsDict, key];
  }

  updateCurrentItemTime() {
    let currTime = performance.now();
    if (this.itemSetTime !== null && this.placedItemIdx !== null) {
      let menuItem = this.config.menuItems[this.placedItemIdx];
      let objectPlacedTime = (currTime - this.itemSetTime) / 1000;
      if (menuItem.objectName in this.menuListItemsTotalDwellTime) {
        this.menuListItemsTotalDwellTime[this.placedItemIdx] +=
          objectPlacedTime;
      } else {
        this.menuListItemsTotalDwellTime[this.placedItemIdx] = objectPlacedTime;
      }
    }
  }

  startExperience(): void {
    super.startExperience();

    this.addMoreLightToScene()
    if (this.config.placeViaFS != 4) {
      this.prepare2DMenu();
    }
  }

  addMoreLightToScene() {
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff);
    hemiLight.position.set(0, 30, 0);
    
    this.ARManager.scene.add(hemiLight);
  }

  prepare2DMenu() {
    for (const [idx, menuItem] of this.config.menuItems.entries()) {
      this.menuListItemsNumSelection[idx] = 0;
    }

    this.menuListComponentHandler(
      ComponentOptions.add,
      this.config.menuItems,
      this.config.menuListViewTheme,
      this.menuItemSelected.bind(this)
    );
  }

  display2DMenu() {
    // this.isMenuPresented = true
    this.menuListComponentHandler(
      ComponentOptions.present,
      [],
      this.config.menuListViewTheme
    );
  }

  hide2DMenu() {
    // this.isMenuPresented = false
    this.menuListComponentHandler(
      ComponentOptions.hide,
      [],
      this.config.menuListViewTheme
    );
  }

  resetARObjects() {
    if (this.placedItem !== null) {
      // console.log("MEMORY BEFORE CLEANUP", this.ARManager.renderer.info.memory)
      this.ARManager.removeARObjectFromScene(this.placedItem);
      this.ARManager.ARObjectCleanup(this.placedItem);
      this.placedItem = null;
    }

    if (this.placedItemTxt !== null) {
      this.ARManager.removeARObjectFromScene(this.placedItemTxt);
      this.placedItemTxt = null;
    }

    if (this.placedLottieObject !== null) {
      this.ARManager.removeARObjectFromScene(this.placedLottieObject);
      this.placedLottieObject = null;
    }
  }

  resetButtonTapped() {
    this.resetARObjects();
    this.placedItem = null;
    this.placedItemTxt = null;
    this.placedLottieObject = null;
    this.placedItemTransform = null;
    // this.menuView = null
  }

  async getDownloadedMenuItem(index: number): Promise<MenuItem | null> {
    const menuItem = this.config.menuItems[index];

    if (menuItem.object instanceof DelayedARObject) {
      let object = await menuItem.object.loadObject();
      if (object !== null) {
        menuItem.object = object;
        this.config.menuItems[index] = menuItem;
        return menuItem;
      } else {
        return null;
      }
    } else {
      return menuItem;
    }
  }

  async addMenuItemToScene(index: number) {
    // If invalid index or an index of an already placed item, dont do anything
    if (index < 0 || index >= this.config.menuItems.length || index == this.placedItemIdx) {
      return;
    }

    if (this.config.menuItems[index].object instanceof DelayedARObject) {
      this.UIManager.placeLottie(LottieNames.loading);
    }
    let menuItem = await this.getDownloadedMenuItem(index);
    this.UIManager.placeLottie(null);

    // Particle system
    if (this.config.smokeEffect == 1) {
      // const smokeEffect = await this.ARManager.getSmokeEffect()
      // const smokeSettings = new ARObjectSettings()
      // smokeEffect.position.set(0, 0, 0)
      // smokeEffect.userData.settings = smokeSettings
      // console.log("smokeEffect", smokeEffect)
      // menuItem.object.add(smokeEffect)
    }
      
    // If there is already an item placed, use its transform for the current menuItem
    if (this.placedItemTransform !== null) {
      this.resetARObjects();

      let position = new THREE.Vector3();
      let quaternion = new THREE.Quaternion();
      let scale = new THREE.Vector3();
      this.placedItemTransform.decompose(position, quaternion, scale);
      menuItem.object.position.set(position.x, position.y, position.z);
      menuItem.object.quaternion.set(
        quaternion.x,
        quaternion.y,
        quaternion.z,
        quaternion.w
      );

      this.updateCurrentItemTime();
      this.itemSetTime = performance.now();
      this.ARManager.addARObjectToScene(menuItem.object);

      // this.placeTextNextToItem(menuItem.objectName, menuItem.object)
    } else {
      // this.UIManager.placeLottie(LottieNames.scanPlane);
      this.ARManager.addARObjectToSceneViaFS(
        menuItem.object,
        (transform) => {
          //   this.updateCurrentItemTime()
          this.itemSetTime = performance.now();
          this.UIManager.placeLottie(null);
          // this.placeTextNextToItem(menuItem.objectName, menuItem.object)
          this.placedItemTransform = transform.clone();
        },
        (transform) => {
          // this.UIManager.placeLottie(LottieNames.tapScreen);
        }
      );
    }

    // this.addSpinAnimation(menuItem.object);
    this.placedItemIdx = index;
    this.placedItem = menuItem.object;
  }

  async addAllObjectsToScene() {
    if (this.placedItemTransform !== null) { return }
    let xOffset: number = 0
    this.UIManager.placeLottie(LottieNames.scanPlane);
    this.ARManager.getTransformViaFS(async (transform: THREE.Matrix4) => {
      this.UIManager.placeLottie(null);
      this.placedItemTransform = transform
      var currentTransform = transform.clone()
      this.UIManager.placeLottie(LottieNames.loading);
      for (let menuItemIdx = 0; menuItemIdx < this.config.menuItems.length; menuItemIdx++) {
        let menuItem = await this.getDownloadedMenuItem(menuItemIdx);
        if (menuItem) {
          this.ARManager.changeARObjectTransform(menuItem.object, currentTransform)
          this.ARManager.addARObjectToScene(menuItem.object)

          const objectBB = new THREE.Box3().setFromObject(menuItem.object);
          xOffset = (objectBB.max.x-objectBB.min.x) + 0.02
          const offsetVec = new THREE.Vector3(xOffset, 0, 0)
          currentTransform = this.ARManager.getTranslatedTransform(currentTransform, offsetVec)
        }
      }
      this.UIManager.placeLottie(null);
    },
    (transform) => {
      this.UIManager.placeLottie(LottieNames.tapScreen);
    })
  }

  addSpinAnimation(object: THREE.Object3D) {
    const params: SpinParams = {
      action: "animate",
      keyPath: "rotation",
      duration: 20,
      repeatCount: Number.MAX_SAFE_INTEGER,
      fromValue: [0, 1, 0, 0],
      toValue: [0, 1, 0, 6.2831],
    };

    this.ARManager.spinARObject(object, params);
  }

  async placeTextNextToItem(text: string, object: THREE.Object3D) {
    const transform = new THREE.Matrix4();
    transform.copy(object.matrixWorld);

    let txtNode = await this.createNewTextNodeWithBackground(
      text,
      "#FFFFFF",
      "#000000"
    );

    this.ARManager.addARObjectToScene(txtNode);
    this.placedItemTxt = txtNode;
  }

  menuItemSelected(id: string, index: number) {
    this.hide2DMenu();
    this.addMenuItemToScene(index);

    if (index in this.menuListItemsNumSelection) {
      this.menuListItemsNumSelection[index] += 1;
    } else {
      this.menuListItemsNumSelection[index] = 1;
    }
  }

  executeCustomAction(
    object: THREE.Object3D | null,
    data: Record<string, any>
  ): void {
    if ("buttonID" in data) {
      if (data["buttonID"] == "menuButton") {
        if (this.config.placeViaFS == 4) {
          this.addAllObjectsToScene()
        } else {
          this.display2DMenu();
        }
      } else if (data["buttonID"] == "resetButton") {
        this.updateCurrentItemTime();
        this.resetButtonTapped();
      }
    }
  }

  async createNewTextNodeWithBackground(
    text: string,
    textColor: string,
    backgroundColor: string,
    fontSize: number = 0.2,
    height: number = 0.05
  ) {
    const geometry = await this.createTextGeometry(text, fontSize, height);
    const material = new THREE.MeshBasicMaterial({ color: textColor });
    const mesh = new THREE.Mesh(geometry, material);

    this.addBackgroundMeshToObject(mesh, backgroundColor, 0.75);

    mesh.userData.settings = new ARObjectSettings({ addPhysicsBody: false });
    return mesh;
  }

  async createTextGeometry(
    text: string,
    fontSize: number,
    height: number
  ): Promise<TextGeometry> {
    return new Promise((resolve, reject) => {
      const loader = new FontLoader();

      loader.load(
        `node_modules/three/examples/fonts/helvetiker_regular.typeface.json`,
        (font) => {
          const textGeometry = new TextGeometry(text, {
            font: font,
            size: fontSize,
            height: height,
          });
          textGeometry.center();
          resolve(textGeometry);
        },
        undefined,
        reject
      );
    });
  }

  addBackgroundMeshToObject(
    object: THREE.Mesh,
    color: string,
    opacity: number,
    backgroundWidth: number | null = null,
    backgroundHeight: number | null = null
  ) {
    const backgroundMaterial = new THREE.MeshBasicMaterial({
      color: color,
      side: THREE.DoubleSide,
    }); // Set the background color
    backgroundMaterial.opacity = opacity;
    backgroundMaterial.transparent = true;
    const backgroundGeometry = this.createBackgroundPlaneWithRoundedCorners(
      backgroundWidth ??
        object.geometry.boundingBox.max.x - object.geometry.boundingBox.min.x,
      backgroundHeight ??
        object.geometry.boundingBox.max.y - object.geometry.boundingBox.min.y
    );
    const backgroundMesh = new THREE.Mesh(
      backgroundGeometry,
      backgroundMaterial
    );
    backgroundMesh.position.set(0, 0, -0.02); // Slightly behind the text
    backgroundMesh.name = "backgroundMesh";

    object.add(backgroundMesh);
  }

  createBackgroundPlaneWithRoundedCorners(
    foregroundWidth: number,
    foregroundHeight: number
  ) {
    const roundedRectShape = new THREE.Shape();
    const additionalSize = 0.05;
    // const foregroundWidth = foregroundMesh.geometry.boundingBox.max.x - foregroundMesh.geometry.boundingBox.min.x
    // const foregroundHeight = foregroundMesh.geometry.boundingBox.max.y - foregroundMesh.geometry.boundingBox.min.y
    const x = -foregroundWidth / 2 - additionalSize / 2;
    const y = -foregroundHeight / 2 - additionalSize / 2;
    const width = foregroundWidth + additionalSize;
    const height = foregroundHeight + additionalSize;
    const radius = 0.05;

    roundedRectShape.moveTo(x + radius, y);
    roundedRectShape.lineTo(x + width - radius, y);
    roundedRectShape.quadraticCurveTo(x + width, y, x + width, y + radius);
    roundedRectShape.lineTo(x + width, y + height - radius);
    roundedRectShape.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius,
      y + height
    );
    roundedRectShape.lineTo(x + radius, y + height);
    roundedRectShape.quadraticCurveTo(x, y + height, x, y + height - radius);
    roundedRectShape.lineTo(x, y + radius);
    roundedRectShape.quadraticCurveTo(x, y, x + radius, y);

    const backgroundShape = new THREE.ShapeGeometry(roundedRectShape);
    return backgroundShape;
  }
}
