import { createContext, useContext, useState } from "react";
import { AppStore } from "./store";
import { PageNames } from "./consts";
import { ExperienceEndResponse, ExperiencePage, GameResultType, PostExperiencePage, PostGameSelection, WelcomeScreen } from "../components/pages";
import { RegistrationPage, IRegistrationResponse } from "../components/pages/registration-page/RegistrationPage";
import { SummaryPageSelection, SummaryScreen } from "../components/pages/summary-page/SummaryPage";
import { IRegisterEndUser, ServerManager } from "../Managers/ServerManager";
import { IRegistrationPageParams } from "../components/pages/interfaces";
import { loadImage, resizeImage } from "../utils";
export const ExperienceStore = createContext<any>({} as any)

export const ExperienceStoreProvider = ({ children }) => {

    const { pageComponents, globalTheme, globalMetadata, setStartExperience, userSession } = useContext(AppStore)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [componentToRender, setComponentToRender] = useState<any>(null)
    const [experienceResponse, setExperienceResponse] = useState<ExperienceEndResponse>(null)
    let experiencePageIndex: number = 0;
    let experienceEndResponse: ExperienceEndResponse = null

    const handleExperienceStarted = () => {
        setTotalPages(pageComponents.length)
        experiencePageIndex = 0
        setComponentToRender(createPage(pageComponents[experiencePageIndex]))
    }

    const handleExitExperience = () => {
        // console.log("EXITING EXPERIENCEEE")
        setStartExperience(false)
        experiencePageIndex = 0
    }

    const setNextPageToRender = () => {
        // const nextPage = experiencePageIndex + 1
        // console.log("Setting next page", pageComponents[nextPage])
        setComponentToRender(createPage(pageComponents[++experiencePageIndex]))
    }


    const createPage = (page: { pageName: string, params?: any }): any => {
        if (!page) {
            handleExitExperience()
        } else {
            switch (page.pageName) {
                case PageNames.welcomePage:
                    return createWelcomePage(page.params)
                case PageNames.gamePage:
                    return createExperiencePage(page.params)
                case PageNames.postGamePage:
                    return createPostGamePage()
                case PageNames.registrationPage:
                    const registrationParams = page.params as IRegistrationPageParams
                    if (!handlePrevRegistration(registrationParams)) {
                        return createRegistrationPage(page.params)
                    } else {
                        return createPage(pageComponents[++experiencePageIndex])
                    }
                case PageNames.summaryPage:
                    return createSummaryPage(page.params)
                default:
                    return null
            }
        }
    }

    const welcomePageComplete = () => {
        if (experiencePageIndex <= totalPages)
            setComponentToRender(createPage(pageComponents[++experiencePageIndex]))
    }

    const postGamePageComplete = (response: PostGameSelection) => {
        switch (response) {
            case PostGameSelection.playAgain:
                handleExperienceStarted()
                break;
            case PostGameSelection.exit:
                handleExitExperience()
                break
        }
    }

    const createPostGamePage = (): any => {
        if (globalMetadata !== null && experienceEndResponse !== null) {
            setExperienceResponse(experienceEndResponse)
            const page = <PostExperiencePage metadata={globalMetadata} includeLeaderboard={true} includeRegistration={true} experienceEndResponse={experienceEndResponse} onPageComplete={postGamePageComplete} />
            return page
        }
        return null
    }

    const createWelcomePage = (params): any => {
        const page = <WelcomeScreen metadata={globalMetadata} theme={globalTheme} input={params} onPageComplete={welcomePageComplete} />
        return page
    }

    const createExperiencePage = (params): any => {
        const page = <ExperiencePage metadata={globalMetadata} theme={globalTheme} jsonObject={params} onPageComplete={experiencePageComplete} />
        return page
    }

    const experiencePageComplete = (response: ExperienceEndResponse) => {
        experienceEndResponse = response
        switch (response.gameResultType) {
            case GameResultType.award:
                setNextPageToRender()
                break;
            case GameResultType.noAward:
                setNextPageToRender()
                break;
            case GameResultType.taskComplete:
                setNextPageToRender()
                break;
            case GameResultType.taskIncomplete:
                setNextPageToRender()
                break;
            case GameResultType.taskQuit:
                handleExitExperience()
                break;
        }
    }

    const handlePrevRegistration = (registrationParams: IRegistrationPageParams | null): boolean => {
        const prevRegisteredToThisExp = ServerManager.checkIfUserRegisteredToExperience(globalMetadata.experienceID)
        const prevRegistrationString = localStorage.getItem(`user-registration`)

        // If not previously registered, OR if the force registration flag is true, and the user did not previously register for THIS specific experience, then return false (so he will have to register)
        if (!prevRegistrationString || (registrationParams && (registrationParams.forceRegisterToThisExperience) && !prevRegisteredToThisExp)) {
            return false
        }

        // If registered to this specific experience, no need to do anything
        if (prevRegisteredToThisExp) {
            return true
        }

        // If use is registered, but not to this experience, and the force flag is false, do the register API call in the background
        if (prevRegistrationString && !prevRegisteredToThisExp && (registrationParams && (!registrationParams.forceRegisterToThisExperience))) {
            const prevRegistration = JSON.parse(prevRegistrationString) as IRegistrationResponse
            if (prevRegistration) {
                // If we find the user registration parameters, call registerEndUser which either makes the 
                // API call for this specific experienceID or not (based on the localStorage flag)
                ServerManager.registerEndUser(prevRegistration, userSession, globalMetadata.experienceID)

                // Return true so the wrapper calling this function knows to not display the registration page
                return true
            }
        }

        return false
    }

    const createRegistrationPage = (params: IRegistrationPageParams): any => {
        const page = <RegistrationPage
            params={params}
            onPageSkip={() => setNextPageToRender()}
            onPageComplete={
               async (response: IRegistrationResponse, selectedImage?: File) => { registrationPageComplete(params, response, selectedImage) }
            } />
        return page
    }

    const registrationPageComplete = async (params: IRegistrationPageParams, response: IRegistrationResponse, selectedImage?: File) => {
        localStorage.setItem(`user-registration`, JSON.stringify(response))
        ServerManager.registerEndUser(response, userSession, globalMetadata.experienceID)

        if (selectedImage) {
            const didUpload = await handleRegistrationPageImageUpload(params, selectedImage, response)
        }

        setNextPageToRender()
    }

    const createSummaryPage = (params): any => {
        const page = <SummaryScreen metadata={globalMetadata} theme={globalTheme} input={params} onPageComplete={(response: SummaryPageSelection) => { summaryPageComplete(response) }} />
        return page
    }

    const summaryPageComplete = (response: SummaryPageSelection) => {
        switch (response) {
            case SummaryPageSelection.playAgain:
                handleExperienceStarted()
                break;
            case SummaryPageSelection.exit:
                handleExitExperience()
                break
        }
    }

    const handleRegistrationPageImageUpload = async (params: IRegistrationPageParams, file: File, registrationResponse: IRegistrationResponse): Promise<boolean> => {
        try {
            console.log("@@@ uploading imageeee", params, registrationResponse)
            console.log("@@@ the image itself", file)
            const image = await loadImage(file);
            const outputFormat = file.type === 'image/png' ? 'image/png' : 'image/jpeg'; // Decide output format
            const resizedBlob = await resizeImage(image, params.uploadImageSize ?? 1024, params.uploadImageSize ?? 1024, outputFormat);

            if (resizedBlob) {
                const resizedFile = new File([resizedBlob], registrationResponse.phoneNumber, { type: outputFormat });
                console.log("@@@ resized file", resizedFile)
                const didUpload = await ServerManager.uploadEndUserRegistrationImage(resizedFile, globalMetadata, registrationResponse);

                return didUpload
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            return false
        }
    };

    return (
        <ExperienceStore.Provider value={{ handleExperienceStarted, totalPages, componentToRender, experienceResponse, handleExitExperience }}>
            {children}
        </ExperienceStore.Provider>
    )
}
