import { ARObjectLoader, ARObjectSettings, NetworkARObjectDict } from "../../ExperienceObjects/ExperienceObject";
import { UIElementProps } from "../../Managers/UIManager";


export class ARSurveyInput {
    survey: Survey
    review: Review
    emojiReview: EmojiReview
    questionTypes: QuestionType[]
    config: ARSurveyTemplateConfig
    uiObjects: UIElementProps[]

    constructor(survey: Survey,
        review: Review,
        emojiReview: EmojiReview,
        config: ARSurveyTemplateConfig,
        uiObjects: UIElementProps[],
        questionTypes: QuestionType[] | null = null
    ) {
        this.survey = survey
        this.review = review
        this.emojiReview = emojiReview
        this.config = config
        this.uiObjects = uiObjects

        if (questionTypes) {
            this.questionTypes = questionTypes
        } else {
            // Define an array of question types for each category
            const surveyQuestionTypes = Array(survey.questions.length).fill(QuestionType.Survey);
            const reviewQuestionTypes = Array(review.questions.length).fill(QuestionType.Review);
            const emojiReviewQuestionTypes = Array(emojiReview.questions.length).fill(QuestionType.EmojiReview);

            // Combine the question types
            const combinedQuestionTypes = surveyQuestionTypes.concat(reviewQuestionTypes, emojiReviewQuestionTypes);
            this.questionTypes = combinedQuestionTypes
        }
    }
}

export class ARSurveyTemplateConfig {
    questionBackgroundColor: number[]; // [R, G, B, A]
    questionBackgroundOpacity: number;
    questionBackgroundCornerRadius: number;
    surveyAnswerBackgroundColor: number[]; // [R, G, B, A]
    surveyAnswerBackgroundOpacity: number;
    surveyAnswerBackgroundCornerRadius: number;
    reviewAnswerBackgroundColor: number[]; // [R, G, B, A]
    questionTextColor: number[]; // [R, G, B, A]
    answerTextColor: number[]; // [R, G, B, A]
    textDepth: number;
    distanceFromUser: { x: number; y: number; z: number };
    questionYOffset: number;
    surveyAnswerOnBackgroundColor: number[]; // [R, G, B, A]
    surveyRowDelta: number;
    reviewColDelta: number;
    reviewRatingBackgroundHeight: number;
    ratingOffOpacity: number;
    ratingOnOpacity: number;
    // ratingObjectName: string;
    // ratingObjectUrl: string;
    ratingObjectScale: number;
    emojiReviewBackgroundHeight: number;
    emojiReviewBackgroundWidth: number;
    // surveyBulletObjectName: string;
    // surveyBulletObjectUrl: string;

    ratingObjectDict: NetworkARObjectDict
    surveyBulletObjectDict: NetworkARObjectDict
    reviewBulletObjectDict: NetworkARObjectDict

    surveyShootButtonImageUrl: string;
    reviewShootButtonImageUrl: string;
    hitSoundFileUrl?: string;

    ratingObject: THREE.Object3D | null = null
    surveyBulletObject: THREE.Object3D | null = null
    reviewBulletObject: THREE.Object3D | null = null

    constructor(inputParams: Record<string, any>) {
        Object.assign(this, inputParams);
    }

    async loadData() {
        const ratingObjectLoader = new ARObjectLoader(this.ratingObjectDict)
        if (ratingObjectLoader)
            this.ratingObject = await ratingObjectLoader.loadARObject()

        const surveyBulletObjectLoader = new ARObjectLoader(this.surveyBulletObjectDict)
        if (surveyBulletObjectLoader)
            this.surveyBulletObject = await surveyBulletObjectLoader.loadARObject()

        const reviewBulletObjectLoader =  new ARObjectLoader(this.reviewBulletObjectDict)
        if (reviewBulletObjectLoader)
            this.reviewBulletObject = await reviewBulletObjectLoader.loadARObject()
    }
}

// Create classes for Survey, Review, EmojiReview
export enum QuestionType {
    Survey = "survey",
    Review = "review",
    EmojiReview = "emojiReview",
}

export class Survey {
    questions: SurveyQuestion[];

    constructor(questions: SurveyQuestion[]) {
        this.questions = questions
    }

    static fromJSON(jsonData: any): Survey {
        return jsonData as Survey;
        // return JSON.parse(jsonData) as Survey;
    }
}

export class SurveyQuestion {
    question: string;
    answers: SurveyAnswer[];

    constructor(question: string, answers: SurveyAnswer[]) {
        this.question = question
        this.answers = answers
    }

    static fromJSON(jsonData: string): SurveyQuestion {
        return JSON.parse(jsonData) as SurveyQuestion;
    }
}

export class SurveyAnswer {
    answer: string;
    imageUrl: string;

    constructor(answer: string, imageUrl: string) {
        this.answer = answer
        this.imageUrl = imageUrl
    }

    static fromJSON(jsonData: any): SurveyAnswer {
        return jsonData as SurveyAnswer;
    }
}

export class Review {
    questions: ReviewQuestion[];

    constructor(questions: ReviewQuestion[]) {
        this.questions = questions
    }

    static fromJSON(jsonData: any): Review {
        return jsonData as Review;
    }
}

export class ReviewQuestion {
    question: string;
    numRatings: number;

    constructor(question: string, numRatings: number) {
        this.question = question
        this.numRatings = numRatings
    }

    static fromJSON(jsonData: any): ReviewQuestion {
        return jsonData as ReviewQuestion;
    }
}

export class EmojiReview {
    questions: ReviewQuestion[];

    constructor(questions: ReviewQuestion[]) {
        this.questions = questions
    }

    static fromJSON(jsonData: any): EmojiReview {
        return jsonData as EmojiReview;
    }
}