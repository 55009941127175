import { ISummaryPage } from "../interfaces";
import { useContext, useEffect } from "react";
import { ExperienceStore } from "../../../app-store/experienceStore";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { MainWrapper, StyledButton, LottieContainer, CustomizedButton } from "./styled";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AppStore } from '../../../app-store/store';
import Lottie from 'lottie-react';
import summaryLottie from '../../../assets/Lottie/CoinConfetti.json';


export enum SummaryPageSelection {
    playAgain = "playAgain",
    exit = "exit"
}

export interface SummaryScreenInput {

}

export const SummaryScreen: React.FC<ISummaryPage> = ({ metadata, theme, input, onPageComplete }) => {

    const { userSession } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.SummaryScreen, {})
    }, []);

    const tryAgaintButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Play Again"
        })
        onPageComplete(SummaryPageSelection.playAgain)
    }

    const exitButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        onPageComplete(SummaryPageSelection.exit)
    }

    return (
        <MainWrapper>
            <Button
                onClick={exitButtonTapped}
                style={{
                    position: 'absolute',
                    top: "20px",
                    zIndex: 99,
                    left: "20px",
                    color: 'black',
                    backgroundColor: "#C0C0C0",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} shape="circle" icon={<CloseOutlined />} />
            {
                summaryLottie !== null &&
                <LottieContainer id="lottie-container">
                    <Lottie animationData={summaryLottie} />
                </LottieContainer>
            }
            {
                <p>You have completed the experience!</p>
            }
            {/* <StyledButton onClick={() => tryAgaintButtonTapped()}>
                Try again
            </StyledButton> */}
            <CustomizedButton primaryColor={theme.primaryColor} secondaryColor={theme.secondaryColor} onClick={() => tryAgaintButtonTapped()}>
                Try again
            </CustomizedButton>
        </MainWrapper>
    );
}