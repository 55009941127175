import * as THREE from 'three';
import { PType, Tween } from '../ParticleSystemManager';

const loadTexture = async (texturePath: string): Promise<THREE.Texture> => {
	const textureLoader = new THREE.TextureLoader();
  
	try {
	  // Use await to wait for the texture to be loaded
	  const texture = await textureLoader.loadAsync(texturePath);
	  
	  return texture;
	} catch (error) {
	  // Handle any errors during loading
	  console.error('Error loading texture:', error);
	  throw error; // Rethrow the error to propagate it
	}
};

export const ParticleEngineExamples =
{
	// (1) build GUI for easy effects access.
	// (2) write ParticleEngineExamples.js
	
	// Not just any fountain -- a RAINBOW STAR FOUNTAIN of AWESOMENESS
	smoke :
	{
		positionStyle    : PType.CUBE,
		positionBase     : new THREE.Vector3( 0, 0, 0 ),
		positionSpread   : new THREE.Vector3( 0.01, 0.02, 0.01 ),

		velocityStyle    : PType.CUBE,
		velocityBase     : new THREE.Vector3( 0.002, 0.01, 0.002 ),
		velocitySpread   : new THREE.Vector3( 0.01, 0.01, 0.01 ), 
		accelerationBase : new THREE.Vector3( 0, 0, 0 ),
		
		particleTexture : null,//await loadTexture('https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FExperiences%2Fbaselburgermenugame%2Fimages%2Fsmokeparticle.png?alt=media&token=220fd741-4e8e-4fec-93f3-91a79e7a7715'),

		angleBase               : 0,
		angleSpread             : 0,
		angleVelocityBase       : 0,
		angleVelocitySpread     : 0,
		
		sizeTween    : new Tween( [0, 1], [0.01, 0.01] ),
		opacityTween : new Tween( [0.8, 2], [0.0, 1.0] ),
		colorTween   : new Tween( [0.4, 1], [ new THREE.Vector3(0,0,0.2), new THREE.Vector3(0, 0, 0.5) ] ),

		particlesPerSecond : 20,
		particleDeathAge   : 5,		
		emitterDeathAge    : 100
	}
}