import './App.css'
import { AppRouter } from './components/routers/AppRouter.tsx';

function App() {
    return (
        <>
            <AppRouter />
        </>
    )
}

export default App