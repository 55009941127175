import * as THREE from 'three';
import { NetworkARObjectDict } from '../../ExperienceObjects/ExperienceObject';

export enum PhotoAlbumType {
    Spherical = "Spherical",
    Tour = "Tour"
}

export interface IPhotoAlbumConfig {
    photoAlbumType: PhotoAlbumType
    images: IPhotoAlbumImage[]
}

export class PhotoAlbumConfig {
    images: PhotoAlbumImage[]
    photoAlbumType: PhotoAlbumType
    networkConfig: IPhotoAlbumConfig

    constructor(images: PhotoAlbumImage[],
        networkConfig: IPhotoAlbumConfig
    ) {
        this.images = images
        this.photoAlbumType = networkConfig.photoAlbumType
        this.networkConfig = networkConfig
    }
}

export interface IPhotoAlbumImage {
    image: NetworkARObjectDict
    height: number
}

export class PhotoAlbumImage {
    image: NetworkARObjectDict
    texture: THREE.Texture
    height: number

    constructor(data: IPhotoAlbumImage, texture: THREE.Texture) {
        this.texture = texture
        this.image = data.image
        this.height = data.height
    }
}