export const IndoorDesignDemoExperience = `
{
   "metadata":{
      "experienceID":"indoorDesign1234",
      "experienceType":"indoorDesign"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor":[
         255,
         255,
         255
      ],
      "secondaryColor":[
         92,
         92,
         92
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"game",
         "pageParamsName":"experience"
      }
   ],
   "experience":{
      "input":{
         "tiles":[
            {
               "tileImage":{
                  "fileName":"grayTile.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2FindoorDesignImages%2FsquareTile.png?alt=media&token=593061de-933b-4bd3-8360-b31eb53e8b76"
               },
               "tileSize":[
                  0.4,
                  0.4
               ],
               "description":"Our classic gray tile",
               "name":"Gray tile"
            },
            {
               "tileImage":{
                  "fileName":"darkTile.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2FindoorDesignImages%2FdarkTile.png?alt=media&token=49078aaa-c414-43b4-89dd-3ee25557f456"
               },
               "tileSize":[
                  0.4,
                  0.4
               ],
               "description":"A darker gray tile",
               "name":"Dark gray tile"
            }
         ],
         "objects":[
            {
               "object": {
                  "fileName": "couch.usdz",
                  "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fcouch.usdz?alt=media&token=eee639fc-a91d-4f1e-94b9-9726007557f4",
                  "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fwhite_couch_brent.glb?alt=media&token=a5cfbca7-73bd-4d10-81a7-d48b499782cd",
                  "settings": {
                     "allowUserGestures": true,
                     "size": 2,
                     "scaleAxis": 0
                  }
               },
                  "image": {
                     "fileName": "couch.png",
                     "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FImages%2Fcouch.png?alt=media&token=13cfdbfb-69b5-441b-ac9d-e0121685bc6a"
                  },
                  "description": "A classic burger with cheese.",
                  "name": "Classic Burger"
            },
            {
                  "object": {
                     "fileName": "chair.usdz",
                     "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fchair.usdz?alt=media&token=a8333f8b-3d24-4336-8373-f766b7f87b0b",
                     "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fcover_chair.glb?alt=media&token=a87ab12d-4be7-4fb0-8ab5-205c2b6ccf42",
                     "settings": {
                        "allowUserGestures": true,
                        "size": 0.4,
                        "scaleAxis": 0
                     }
                  },
                  "image": {
                     "fileName": "chair.png",
                     "fileUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FImages%2Fchair.png?alt=media&token=d0952aee-7ef7-43e1-b895-f21de8d3110e"
                  },
                  "description": "A classic burger with cheese. 2",
                  "name": "Classic Burger 2"
            }
         ],
         "settings":{
            "gridSize":[
               3,
               3
            ]
         }
      },
      "auxData":{
         "backgroundExperienceJSON":{
            "objects":[
               
            ],
            "generalRules":[
               
            ],
            "UIObjects":[
               {
                  "tag":"menuButton",
                  "type":"button",
                  "locX":0.5,
                  "locY":0.9,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FBlackAquaOrbLogo.png?alt=media&token=10cc96b3-affd-4663-86b7-9bb3755dbc72"
               }
            ]
         }
      }
   }
}
`