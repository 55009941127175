export const PortalTemplateDemoExperience = `
{
   "metadata":{
      "experienceID":"portalTemplate1234",
      "experienceType":"PortalTemplate"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
      "primaryColor":[
         255,
         255,
         255
      ],
      "secondaryColor":[
         92,
         92,
         92
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"game",
         "pageParamsName":"experience"
      }
   ],
   "experience":{
      "input":{
         "spherePortals":[
            {
               "textureImage":{
                  "fileName":"Seychelles360.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FCustomers%2FxNt5512m87WfRBJj5RIemHtjoU83%2FImages%2Fb081.jpg?alt=media&token=c8d0bcf4-43a3-40a0-8ae8-b764acfe5952"
               },
               "image":{
                  "fileName":"SeychellesThumbnail.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2FindoorDesignImages%2FsquareTile.png?alt=media&token=593061de-933b-4bd3-8360-b31eb53e8b76"
               },
               "radius":1,
               "distFromUser":2,
               "description":"A tropical beach in Seychelles",
               "name":"Seychelles"
            }
         ],
         "rectanglePortals":[
            {
               "sceneImage":{
                  "fileName":"img1.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FTemplateCardImages%2FBaselBurgerShooters.png?alt=media&token=39674422-aefb-4228-a473-f3f8d904a432"
               },
               "scene":{
                  "fileName":"chair.usdz",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fchair.usdz?alt=media&token=a8333f8b-3d24-4336-8373-f766b7f87b0b",
                  "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FModels%2Fcover_chair.glb?alt=media&token=a87ab12d-4be7-4fb0-8ab5-205c2b6ccf42",
                  "settings":{
                     "allowUserGestures":true,
                     "size":0.35,
                     "scaleAxis":0
                  }
               },
               "height":1,
               "width":1,
               "depth":1,
               "distFromUser":2,
               "description":"A tropical beach in Seychelles",
               "name":"Seychelles"
            }
         ],
         "portalGate":{
            "fileName":"Stargate.usdz",
            "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FModels%2FPortalTemplate%2FStargate.usdz?alt=media&token=04223117-667a-4837-be70-2e75e36a7ef6",
            "fileUrlAndroid": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FModels%2FPortalTemplate%2Fstargate.glb?alt=media&token=1b2102f1-f140-4d93-90dd-a64e23b2918b",
            "settings":{
               "allowUserGestures":false,
               "size":0.5,
               "scaleAxis":0
            }
         }
      },
      "auxData":{
         "backgroundExperienceJSON":{
            "objects":[],
            "generalRules":[],
            "UIObjects":[
               {
                  "tag":"menuButton",
                  "type":"button",
                  "locX":0.5,
                  "locY":0.9,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FImages%2FORBImages%2FBlackAquaOrbLogo.png?alt=media&token=10cc96b3-affd-4663-86b7-9bb3755dbc72"
               }
            ]
         }
      }
   }
}
`