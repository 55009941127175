import * as THREE from 'three';
import { ARManager } from "../../Managers/ARManager"
import { UIManager } from '../../Managers/UIManager';
import { TriggerManager, pubsub } from '../../TriggerAction/TriggerManager';
import { BrandThemeManager } from '../../Managers/BrandThemeManager';
import { ITemplatesBasics } from '../TemplatesInterface'
import { ExperienceAnalyticsManager } from '../../Managers/ExperienceAnalyticsManager';
import { SoundManager } from '../../Managers/SoundManager';
import { LottieNames, SessionStatus } from '../../components/pages';
import { GridCellIdx, GridCellShape, GridFullExperience, GridMetaTemplateProps } from './GridExperienceObject';
import { ARObjectSettings } from '../../ExperienceObjects/ExperienceObject';
import { Trigger, TriggerType } from '../../TriggerAction/Triggers';
import { rgbaToHex } from '../../utils';

THREE.Cache.enabled = true



interface IGridMetaTemplate extends ITemplatesBasics {
    triggerManager: TriggerManager;
    ARManager: ARManager;
    UIManager: UIManager;
    brandThemeManager: BrandThemeManager | null;
    soundManager?: SoundManager;
    experienceAnalyticsManager: ExperienceAnalyticsManager;

    fullExperience: GridFullExperience

    // showInstructionsLottie(lottieName: LottieNames | null): void
    ARSessionStatusChange: ((status: SessionStatus) => void)
}

export class GridMetaTemplate implements IGridMetaTemplate {
    triggerManager: TriggerManager;
    ARManager: ARManager;
    UIManager: UIManager;
    brandThemeManager: BrandThemeManager | null;
    soundManager?: SoundManager;
    experienceAnalyticsManager: ExperienceAnalyticsManager;

    fullExperience: GridFullExperience

    // showInstructionsLottie: ((lottieName: LottieNames | null) => void) | null = null
    ARSessionStatusChange: ((status: SessionStatus) => void) | null = null

    grid: THREE.Object3D | null = null
    intervalId: any = null

    lastCellHit: GridCellIdx | null = null

    constructor(fullExperience: GridFullExperience | null,
        triggerManager: TriggerManager,
        ARManager: ARManager,
        UIManager: UIManager,
        soundManager: SoundManager,
        brandThemeManager: BrandThemeManager,
        experienceAnalyticsManager: ExperienceAnalyticsManager,
        ARSessionStatusChange: ((status: SessionStatus) => void),
        // showInstructionsLottie: ((lottieName: LottieNames | null) => void) | null = null
        ) {

        this.fullExperience = fullExperience
        this.triggerManager = triggerManager
        this.ARManager = ARManager
        this.UIManager = UIManager
        this.soundManager = soundManager
        this.brandThemeManager = brandThemeManager
        this.experienceAnalyticsManager = experienceAnalyticsManager
        
        // this.showInstructionsLottie = showInstructionsLottie
        this.ARSessionStatusChange = ARSessionStatusChange

        // this.grid = this.buildARGrid(fullExperience.gridProps)
        this.grid = this.buildCurvedARGrid(fullExperience.gridProps)
        // this.subscribeToTriggers()
    }

    async launchAR() {
        await this.ARManager.initXR(["local-floor", "hit-test"])
        this.ARManager.startAR()
        this.experienceAnalyticsManager.setExperienceStartTime()
        this.intervalId = setInterval(this.checkIntersection.bind(this), 100);
    }

    cleanup() {
        console.log("GridMetaTemplate - Cleaning up...")
        this.ARManager.cleanup()
        this.triggerManager.cleanup()
        this.UIManager.cleanup()
        this.brandThemeManager?.cleanup()
        this.soundManager?.cleanup()
        clearInterval(this.intervalId)
    }

    getARAnalytics() {
        return this.ARManager.getARAnalytics()
    }

    prepareFullAnalytics(sessionStatus: SessionStatus | null = null): Record<string, any> {
        if (sessionStatus !== null) {
            this.experienceAnalyticsManager.changeSessionStatus(sessionStatus)
        }

        // TODO: Consider returning this or summarizing it as it is too big
        let arAnalytics = this.ARManager.getARAnalytics()
        if (arAnalytics !== null) {
            let arAnalyticsDict = arAnalytics.toDictionary()
            this.experienceAnalyticsManager.integrateAdditionalAnalytics("ARAnalytics", arAnalyticsDict)
        }

        let customAnalytics = this.getCustomExperienceAnalytics()
        if (customAnalytics !== null) {
            let [customExperienceAnalytics, key] = customAnalytics
            if (customExperienceAnalytics !== null && key !== null) {
                this.experienceAnalyticsManager.integrateAdditionalAnalytics(key, customExperienceAnalytics)
            }
        }

        let analyticsDict = this.experienceAnalyticsManager.getAnalyticsDict()

        return analyticsDict
    }

    getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
        return null
    }

    ///////////// GRID ACTIONS PARSING ////////////
    startExperience() {
        if (this.fullExperience && this.grid !== null) {
            this.ARManager.addARObjectToScene(this.grid)

            for (const uiObject of this.fullExperience.UIObjects) {
                this.UIManager.placeUIElement(uiObject)
            }
        }
    }

    // Build a grid "on a sphere" of radius gridProps.radius. The grid spans an angle of gridProps.FOV (FOV/2 to each side)
    buildCurvedARGrid(gridProps: GridMetaTemplateProps): THREE.Object3D {
        const grid = new THREE.Object3D();
        const gridARSettings = new ARObjectSettings()
        grid.userData.settings = gridARSettings

        if (gridProps.curved) {
            const deltaPhi = gridProps.FOV_Y / (gridProps.numRows - 1);
            const startPhi = -gridProps.FOV_Y / 2;

            const deltaTheta = gridProps.FOV_X / (gridProps.numCols - 1);
            const startTheta = -gridProps.FOV_X / 2;

            const radius = gridProps.radius;

            for (let j = 0; j < gridProps.numRows; j++) {
                const phi = (startPhi + deltaPhi * j) * Math.PI / 180;
                const y = radius * Math.sin(phi);

                for (let i = 0; i < gridProps.numCols; i++) {
                    const cellWidth = (2*Math.PI*gridProps.radius*Math.cos(phi)*gridProps.FOV_X/360) / gridProps.numCols - gridProps.cellSpacing;
                    const cellHeight = cellWidth
                    const cellDepth = Math.min(0.02, cellWidth)
                    const cellMesh = this.buildARGridCell(gridProps.cellShape, cellWidth, cellHeight, cellDepth);

                    const theta = (startTheta + deltaTheta * i) * Math.PI / 180;
                    const x = radius * Math.cos(phi) * Math.sin(theta);
                    const z = -radius * Math.cos(phi) * Math.cos(theta);

                    const direction = new THREE.Vector3(0, 0, 0).sub(new THREE.Vector3(x, y, z)).normalize();
                    const up = new THREE.Vector3(0, 0, 1);
                    const rotation = new THREE.Quaternion().setFromUnitVectors(up, direction);
                    
                    cellMesh.position.set(x, y, z);
                    cellMesh.applyQuaternion(rotation);
                    cellMesh.name = `GridCell_${j}_${i}`

                    grid.add(cellMesh);
                }
            }
        } else {
            for (let layer = 0; layer < gridProps.numLayers; layer++) {
                for (let row = 0; row < gridProps.numRows; row++) {
                    for (let col = 0; col < gridProps.numCols; col++) {
                        const cellWidth = gridProps.width / gridProps.numCols;
                        const cellHeight = gridProps.height / gridProps.numRows;
                        const cellDepth = gridProps.depth / gridProps.numLayers;
                        const cellMesh = this.buildARGridCell(gridProps.cellShape, 
                            cellWidth - gridProps.cellSpacing, 
                            cellHeight - gridProps.cellSpacing, 
                            cellDepth - gridProps.cellSpacing)

                        const x = col * cellWidth - gridProps.width / 2 + cellWidth / 2
                        const y = row * cellHeight - gridProps.height / 2 + cellHeight / 2
                        const z = layer * cellDepth - gridProps.depth / 2 + cellDepth / 2

                        cellMesh.position.set(x,y,z)
                        cellMesh.name = `GridCell_${layer}_${row}_${col}`
            
                        grid.add(cellMesh);
                    }
                }
            }
        }

        grid.position.z = -gridProps.distToCenter
        return grid
    }

    buildARGridCell(cellShape: GridCellShape, cellWidth: number, cellHeight: number, cellDepth: number): THREE.Object3D {
        let geom: THREE.BufferGeometry
        switch (cellShape) {
            case GridCellShape.cube: {
                geom = new THREE.BoxGeometry(cellWidth, cellHeight, cellDepth);
                break;
            }
            case GridCellShape.sphere: {
                geom = new THREE.SphereGeometry(cellWidth/2)
                break;
            }
        }

        const mat = new THREE.MeshBasicMaterial({ 
            color: 0xff0000, 
            opacity: 0.5,
            transparent: true
        });
        const cellMesh = new THREE.Mesh(geom, mat);

        return cellMesh
    }

    ///////////// TRIGGER ACTION PARSING ////////////
    subscribeToTriggers() {
        const buttonTapTriggerDict = { "trigger": TriggerType.onUpdateARFrame }
        const buttonTapTrigger = new Trigger(buttonTapTriggerDict)

        pubsub.subscribe(buttonTapTrigger.description, buttonTapTrigger.description, this.onNewFrame.bind(this))
    }

    onNewFrame(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        // const intersections = this.ARManager.getScreenCenterIntersections(this.grid.children)

        // for (const obj of intersections) {
        //     if (obj.name.startsWith("GridCell_")) {
        //         console.log("INTERSECTION", obj.name)
        //     }
        // }
    }

    lightUpGridCell(obj: THREE.Mesh) {
        const litupMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide });
        obj.material = litupMaterial
    }

    turnOffGridCell(cellIdx: GridCellIdx) {
        const cellName = `GridCell_${cellIdx.layer}_${cellIdx.row}_${cellIdx.col}`
        const cell = this.grid.getObjectByName(cellName) as THREE.Mesh

        if (cell) {
            const litupMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, transparent: true });
            cell.material = litupMaterial
        }
    }

    checkIntersection() {
        const intersections = this.ARManager.getScreenCenterIntersections(this.grid.children)
        
        for (const obj of intersections) {
            // if (obj.name.startsWith("GridCell_")) {
                if (obj instanceof THREE.Mesh) {
                    const parts = obj.name.split('_');

                    if (!this.fullExperience.gridProps.curved && (parts.length === 4 && parts[0].startsWith("GridCell"))) {
                        const [, firstNumber, secondNumber, thirdNumber] = parts;
                        const gridCellIdx = new GridCellIdx(parseInt(firstNumber), parseInt(secondNumber), parseInt(thirdNumber))

                        if (this.lastCellHit !== null) {
                            this.turnOffGridCell(this.lastCellHit)
                        }
                        this.lastCellHit = gridCellIdx
                        this.lightUpGridCell(obj)
                    } else if (parts.length === 3 && parts[0].startsWith("GridCell")) {
                        // const [, firstNumber, secondNumber] = parts;
                        // const gridCellIdx = new GridCellIdx(parseInt(firstNumber), parseInt(secondNumber))

                        // if (this.lastCellHit !== null) {
                        //     this.turnOffGridCell(this.lastCellHit)
                        // }
                        // this.lastCellHit = gridCellIdx
                        this.lightUpGridCell(obj)
                    }
                }
            // }
        }
    }
}