import * as THREE from 'three';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';

import { ARManager } from "../../Managers/ARManager"
import { UIManager } from '../../Managers/UIManager';
import { TriggerManager, pubsub } from '../../TriggerAction/TriggerManager';
import { BrandThemeManager } from '../../Managers/BrandThemeManager';
import { ARObjectSettings } from '../../ExperienceObjects/ExperienceObject';
import { ITemplatesBasics } from '../TemplatesInterface';
import { Trigger } from '../../TriggerAction/Triggers';
import { ARSurveyTemplateConfig, ReviewQuestion, SurveyAnswer } from './ARSurveyTemplateInput';
import { UIObjectLoader } from '../../ExperienceObjects/ExperienceObject';
import { ARSurveyInput, Survey, Review, EmojiReview, SurveyQuestion, QuestionType } from './ARSurveyTemplateInput';
import { rgbaToHex } from '../../utils';
import { ObjectAnimation } from '../../Managers/ObjectActionManager';
import { ExperienceAnalyticsManager } from '../../Managers/ExperienceAnalyticsManager';
import { SessionStatus } from '../../components/pages';

enum QuestionOrder {
    next = "next",
    prev = "prev",
    current = "current",
}

interface IARSurveyTemplate extends ITemplatesBasics {
    // parseInput(json: Record<string, any>): Promise<ARSurveyInput>;
}

export class ARSurveyTemplate implements IARSurveyTemplate {
    surveyInput: ARSurveyInput
    triggerManager: TriggerManager;
    ARManager: ARManager;
    UIManager: UIManager;
    brandThemeManager: BrandThemeManager | null;
    soundManager?: any;
    experienceAnalyticsManager: ExperienceAnalyticsManager

    currQuestionIdx: number = 0
    questionIndices: number[] = []          // The current question's index in its respective list (either in self.survey or in self.review)

    ratingsPerReview: number[] = []         // The number of ratings given for every review question
    answersPerSurvey: number[][] = null     // A list of selected answer indices, for every survey question

    allNodes: THREE.Object3D[] = []

    ARSessionStatusChange: ((status: SessionStatus) => void) | null = null

    constructor(surveyInput: ARSurveyInput,
        triggerManager: TriggerManager,
        ARManager: ARManager,
        UIManager: UIManager,
        soundManager: any,
        brandThemeManager: BrandThemeManager,
        experienceAnalyticsManager: ExperienceAnalyticsManager,
        ARSessionStatusChange: ((status: SessionStatus) => void)) {

        this.surveyInput = surveyInput
        this.triggerManager = triggerManager
        this.ARManager = ARManager
        // this.ARManager.sessionEndedCallback = this.cleanup.bind(this)
        this.UIManager = UIManager
        this.soundManager = soundManager
        this.brandThemeManager = brandThemeManager
        // this.brandThemeManager = null;
        this.experienceAnalyticsManager = experienceAnalyticsManager
        this.ARSessionStatusChange = ARSessionStatusChange
        if (this.surveyInput.config.hitSoundFileUrl) {
            // this.soundManager?.createSound({
            //     id: "hitSoundFileUrl",
            //     url: this.surveyInput.config.hitSoundFileUrl
            // });
            this.soundManager?.createSound("hitSoundFileUrl", this.surveyInput.config.hitSoundFileUrl)
        }

        let surveyCounter = 0
        let reviewCounter = 0
        let emojiReviewCounter = 0
        for (const questionType of this.surveyInput.questionTypes) {
            switch (questionType) {
            case QuestionType.Survey:
                this.questionIndices.push(surveyCounter)
                if (this.answersPerSurvey === null) {
                    this.answersPerSurvey = [[]]
                } else {
                    this.answersPerSurvey.push([])
                }
                surveyCounter += 1
                break;
            case QuestionType.Review:
                this.questionIndices.push(reviewCounter)
                reviewCounter += 1
                this.ratingsPerReview.push(-1)
                break;
            case QuestionType.EmojiReview:
                this.questionIndices.push(emojiReviewCounter)
                emojiReviewCounter += 1
                break;
            }
        }
    }

    ///////////// Parse input json //////////////////
    static async parseInput(json: Record<string, any>): Promise<ARSurveyInput> {
        const surveyInputConfig = new ARSurveyTemplateConfig(json["staticConfig"])
        await surveyInputConfig.loadData()

        const uiObjectsLoader = new UIObjectLoader(json["UIObjects"])
        const UIObjects = await uiObjectsLoader.loadData()

        const survey: Survey = Survey.fromJSON(json["survey"]);
        const review: Review = Review.fromJSON(json["review"]);
        const emojiReview: EmojiReview = EmojiReview.fromJSON(json["emojiReview"]);

        const surveyInput = new ARSurveyInput(survey, review, emojiReview, surveyInputConfig, UIObjects)

        return surveyInput
    }

    async launchAR() {
        await this.ARManager.initXR(["local-floor", "hit-test"])
        this.ARManager.startAR()
        this.changeQuestion(QuestionOrder.current)
        // this.displaySurveyQuestion(this.surveyInput.survey.questions[0])
        // this.displayReviewQuestion(this.surveyInput.review.questions[0])
    }

    surveyDone() {
        this.ARSessionStatusChange(SessionStatus.ARSessionEnded)
    }

    cleanup() {
        this.ARManager.cleanup()
        this.triggerManager.cleanup()
        this.UIManager.cleanup()
        this.brandThemeManager?.cleanup()

        this.soundManager?.cleanup()
    }

    getARAnalytics() {
        return this.ARManager.getARAnalytics()
    }

    prepareFullAnalytics(sessionStatus: SessionStatus | null = null): Record<string, any> {
        if (sessionStatus !== null) {
            this.experienceAnalyticsManager.changeSessionStatus(sessionStatus)
        }
        
        // let arAnalytics = this.ARManager.getARAnalytics()
        // if (arAnalytics !== null) {
        //     let arAnalyticsDict = arAnalytics.toDictionary()
        //     this.experienceAnalyticsManager.integrateAdditionalAnalytics("ARAnalytics", arAnalyticsDict)
        // }

        let [customExperienceAnalytics, key] = this.getCustomExperienceAnalytics()
        if (customExperienceAnalytics !== null && key !== null) {
            this.experienceAnalyticsManager.integrateAdditionalAnalytics(key, customExperienceAnalytics)
        }
        
        let analyticsDict = this.experienceAnalyticsManager.getAnalyticsDict()
        return analyticsDict
    }

    getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
        const key: string = "experienceAnalytics";
        const analyticsDict: Record<string, any> = {};

        analyticsDict["survey"] = this.answersPerSurvey
        analyticsDict["review"] = this.ratingsPerReview

        return [analyticsDict, key];
    }

    startExperience() {
        for (const uiObject of this.surveyInput.uiObjects) {
            this.UIManager.placeUIElement(uiObject)
        }
        // this.displaySurveyQuestion(this.surveyInput.survey.questions[0])
        this.subscribeToTriggers()
    }

    ///////////// TRIGGER ACTION PARSING ////////////
    subscribeToTriggers() {
        const buttonTapTriggerDict = { "trigger": "onButtonTap", "id": "shootButton" }
        const buttonTapTrigger = new Trigger(buttonTapTriggerDict)

        const collisionTriggerDict = { "trigger": "onCollideWith", "id1": "bulletObject", "id2": "answerObject" }
        const collisionTrigger = new Trigger(collisionTriggerDict)

        pubsub.subscribe(buttonTapTrigger.description, buttonTapTrigger.description, this.onButtonTapFunc.bind(this))
        pubsub.subscribe(collisionTrigger.description, collisionTrigger.description, this.onBulletAnswerCollision.bind(this))
    }

    onButtonTapFunc(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        if (!("buttonID" in data)) {
            return
        }
        const buttonID = data.buttonID
        switch (buttonID) {
            case "shootButton":
                this.shootBullet()
                break;
            case "leftArrowButton":
                this.changeQuestion(QuestionOrder.prev)
                break;
            case "rightArrowButton":
                this.changeQuestion(QuestionOrder.next)
                break;
            case "repeatButton":
                this.clearCurrentQuestion()
                this.changeQuestion(QuestionOrder.current)
                break;
            case "doneButton":
                this.surveyDone()
                break;
        }
    }

    clearCurrentQuestion() {
        const questionType = this.surveyInput.questionTypes[this.currQuestionIdx]
        const questionRelativeIdx = this.questionIndices[this.currQuestionIdx]

        switch (questionType) {
            case QuestionType.Survey:
                if (questionRelativeIdx < this.surveyInput.survey.questions.length) {
                    this.answersPerSurvey[questionRelativeIdx] = []
                }
                break;
            case QuestionType.Review:
                if (questionRelativeIdx < this.surveyInput.review.questions.length) {
                    this.ratingsPerReview[questionRelativeIdx] = -1
                }
                break;
            case QuestionType.EmojiReview:
                break;
        }
    }

    changeQuestion(order: QuestionOrder) {
        let idx = this.currQuestionIdx

        switch (order) {
            case QuestionOrder.next:
                idx += 1
                break;
            case QuestionOrder.prev:
                idx -= 1
                break;
            case QuestionOrder.current:
                break
        }

        if (idx < 0 || idx >= this.surveyInput.questionTypes.length) {
            return
        }

        this.removeAllObjects()
        this.currQuestionIdx = idx

        let questionRelativeIdx = this.questionIndices[idx]
        switch (this.surveyInput.questionTypes[idx]) {
            case QuestionType.Survey:
                let surveyQuestion = this.surveyInput.survey.questions[questionRelativeIdx]
                this.displaySurveyQuestion(surveyQuestion)
                break;
            case QuestionType.Review:
                let reviewQuestion = this.surveyInput.review.questions[questionRelativeIdx]
                this.displayReviewQuestion(reviewQuestion)
                break;
            case QuestionType.EmojiReview:
                // let emojiReviewQuestion = this.surveyInput.emojiReview.questions[questionRelativeIdx]
                break;
        }
    }

    shootBullet() {
        var positionInFront = this.ARManager.getUserPosition()
        var object: THREE.Object3D
        switch (this.surveyInput.questionTypes[this.currQuestionIdx]) {
            case QuestionType.Survey:
                object = this.surveyInput.config.surveyBulletObject.clone(true)
                break;
            case QuestionType.Review:
                object = this.surveyInput.config.reviewBulletObject.clone(true)
                break;
            case QuestionType.EmojiReview:
                object = this.surveyInput.config.reviewBulletObject.clone(true)
                break;
        }
        object.name = "bulletObject"
        this.ARManager.changeARObjectPosition(object, positionInFront)
        this.addARObjectToScene(object)
        this.ARManager.shootObjectForward(object, 5, 3)
    }

    addARObjectToScene(object: THREE.Object3D) {
        this.ARManager.addARObjectToScene(object)
        this.allNodes.push(object)
    }

    removeAllObjects() {
        for (const object of this.allNodes) {
            this.ARManager.removeARObjectFromScene(object)
        }
        this.allNodes = []
    }

    onBulletAnswerCollision(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        // For every collision there are 2 triggers (same objects, diff order), so we only consider one of those collisions.
        let bulletObject: THREE.Object3D
        let targetObject: THREE.Object3D
        if (this.isBulletObject(data.object1)) {
            bulletObject = data.object1
            targetObject = data.object2
        } 
        // else if (this.isBulletObject(data.object2)) {
        //     bulletObject = data.object2
        //     targetObject = data.object1
        // } 
        else {
            return
        }

        if (this.currQuestionIdx < 0 || this.currQuestionIdx >= this.surveyInput.questionTypes.length) {
            return
        }

        switch (this.surveyInput.questionTypes[this.currQuestionIdx]) {
            case QuestionType.Survey:
                const answerNode = this.isAnswerObject(targetObject)
                if (answerNode) {
                    this.handleAnswerHit(answerNode, bulletObject)
                }
                break;
            case QuestionType.Review:
                const reviewNode = this.isReviewObject(targetObject)
                if (reviewNode) {
                    this.handleReviewHit(reviewNode, bulletObject)
                }
                break;
            case QuestionType.EmojiReview:
                break;
        }
    }

    isBulletObject(object: THREE.Object3D) {
        return this.getAncestorWithName(object, "bulletObject")
    }

    isQuestionObject(object: THREE.Object3D) {
        return this.getAncestorWithName(object, "questionMesh")
    }

    isAnswerObject(object: THREE.Object3D) {
        return this.getAncestorWithName(object, "answerMesh")
    }

    isReviewObject(object: THREE.Object3D) {
        return this.getAncestorWithName(object, "reviewMesh")
    }

    getAncestorWithName(object: THREE.Object3D | null, targetName: string): THREE.Object3D {
        if (object === null) {
            return null; // Base case: reached the root of the object hierarchy
        }

        if (object.name === targetName) {
            return object; // Found an ancestor with the target name
        }

        // Recursively check the parent of the current object
        return this.getAncestorWithName(object.parent, targetName);
    }

    // Get the index (0...3) of the answer which is encapsulated in the given object
    getAnswerNodeIdx(object: THREE.Object3D) {
        return object.userData.answerIdx ?? -1
    }

    handleAnswerHit(targetObject: THREE.Object3D, bulletObject: THREE.Object3D) {
        // Remove the bullet object
        this.ARManager.removeARObjectFromScene(bulletObject)

        // Check if the object is already selected, then dont do anything
        if (targetObject.userData.isSelected) {
            return
        } 

        targetObject.userData.isSelected = true

        const answerIdx = this.getAnswerNodeIdx(targetObject)
        this.answersPerSurvey[this.questionIndices[this.currQuestionIdx]].push(answerIdx)

        // Play sound
        // this.soundManager?.play("hitSoundFileUrl", { multiShotEvents: true })
        this.soundManager?.playSound("hitSoundFileUrl", { multiShotEvents: true })

        // Add spin animation to object
        this.spinObject(targetObject)

        // Light up answer
        this.lightupAnswer(targetObject)
    }

    handleReviewHit(targetObject: THREE.Object3D, bulletObject: THREE.Object3D) {
        this.ARManager.removeARObjectFromScene(bulletObject)
        // Update the number of ratings + light up the relevant number of review nodes
        const questionRelativeIdx = this.questionIndices[this.currQuestionIdx]
        const currReviewQuestion = this.surveyInput.review.questions[questionRelativeIdx]
        if (currReviewQuestion.numRatings > this.ratingsPerReview[questionRelativeIdx]) {
            if (this.ratingsPerReview[questionRelativeIdx] == -1) {
                this.ratingsPerReview[questionRelativeIdx] = 1
            } else {
                this.ratingsPerReview[questionRelativeIdx] += 1
            }
        }
        // Light up the review nodes
        this.turnOnRelevantReviewNodes(targetObject)
    }

    spinObject(object: THREE.Object3D) {
        const spinParams = {
            "action": "animate",
            "keyPath": "rotation",
            "duration": 0.5,
            "repeatCount": 1,
            "fromValue": [0, 1, 0, 0],
            "toValue": [0, 1, 0, 6.2831]
        }
        const animation = new ObjectAnimation(spinParams);
        if (animation) {
            this.ARManager.addAnimationToObject(object, animation)
        }
    }

    lightupAnswer(object: THREE.Object3D) {
        const backgroundMesh = this.getBackgroundMeshFromObject(object)
        if (backgroundMesh && backgroundMesh instanceof THREE.Mesh) {
            const litupMaterial = new THREE.MeshBasicMaterial({ color: rgbaToHex(this.surveyInput.config.surveyAnswerOnBackgroundColor), side: THREE.DoubleSide });
            backgroundMesh.material = litupMaterial
        }
    }

    turnOnRelevantReviewNodes(object: THREE.Object3D) {
        const questionRelativeIdx = this.questionIndices[this.currQuestionIdx]
        var currRatingsOn = this.ratingsPerReview[questionRelativeIdx]

        let ratingNodes = object.children
        const numNodesToTurnOn = Math.min(ratingNodes.length, currRatingsOn)
        for (let i=0; i<numNodesToTurnOn; i++) {
            this.turnOnReviewNode(ratingNodes[i])
        }
    }

    turnOnReviewNode(object: THREE.Object3D) {
        object.visible = true
        // if (object instanceof THREE.Mesh && object.material instanceof THREE.MeshBasicMaterial) {
        //     object.material.opacity = this.surveyInput.config.ratingOnOpacity;
        //     object.material.transparent = true;
        // }
    }

    turnOffReviewNode(object: THREE.Object3D) {
        object.visible = false
        // if (object instanceof THREE.Mesh && object.material instanceof THREE.MeshBasicMaterial) {
        //     object.material.opacity = this.surveyInput.config.ratingOffOpacity;
        //     object.material.transparent = true;
        // }
    }

    //////////// Survey functionality //////////////
    async displaySurveyQuestion(question: SurveyQuestion, fontSize: number = 0.05, height: number = 0.01) {
        this.removeAllObjects()
        const questionObject = await this.createQuestionObject(question.question, fontSize, height)
        const answerObjects = await this.createSurveyAnswers(question.answers, fontSize, height)
        this.placeSurveyQuestionAndAnswers(questionObject, answerObjects, 0.2)
    }

    async displayReviewQuestion(question: ReviewQuestion, fontSize: number = 0.05, height: number = 0.01) {
        this.removeAllObjects()
        const questionObject = await this.createQuestionObject(question.question, fontSize, height)
        const answerObject = await this.createReviewAnswerNode(question.numRatings)
        this.placeReviewQuestionAndRatings(questionObject, answerObject, 0.2)
    }

    async createTextGeometry(text: string, fontSize: number, height: number): Promise<TextGeometry> {
        return new Promise((resolve, reject) => {
            const loader = new FontLoader();

            loader.load(`https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2FSurveyDemo1234%2FOther%2Fhelvetiker_regular.typeface.json?alt=media&token=eea7ac43-be42-45c6-ba20-20bce19998cd`, font => {
                const textGeometry = new TextGeometry(text, {
                    font: font,
                    size: fontSize,
                    height: height,
                });
                textGeometry.center();
                resolve(textGeometry);
            }, undefined, reject);
        });
    }

    createBackgroundPlaneWithRoundedCorners(foregroundWidth: number, foregroundHeight: number) {
        const roundedRectShape = new THREE.Shape();
        const additionalSize = 0.05
        // const foregroundWidth = foregroundMesh.geometry.boundingBox.max.x - foregroundMesh.geometry.boundingBox.min.x 
        // const foregroundHeight = foregroundMesh.geometry.boundingBox.max.y - foregroundMesh.geometry.boundingBox.min.y
        const x = -foregroundWidth / 2 - additionalSize / 2;
        const y = -foregroundHeight / 2 - additionalSize / 2;
        const width = foregroundWidth + additionalSize;
        const height = foregroundHeight + additionalSize;
        const radius = 0.05;

        roundedRectShape.moveTo(x + radius, y);
        roundedRectShape.lineTo(x + width - radius, y);
        roundedRectShape.quadraticCurveTo(x + width, y, x + width, y + radius);
        roundedRectShape.lineTo(x + width, y + height - radius);
        roundedRectShape.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        roundedRectShape.lineTo(x + radius, y + height);
        roundedRectShape.quadraticCurveTo(x, y + height, x, y + height - radius);
        roundedRectShape.lineTo(x, y + radius);
        roundedRectShape.quadraticCurveTo(x, y, x + radius, y);

        const backgroundShape = new THREE.ShapeGeometry(roundedRectShape)
        return backgroundShape
    }

    async createQuestionObject(question: string, fontSize: number = 0.2, height: number = 0.05) {
        const questionGeometry = await this.createTextGeometry(question, fontSize, height);
        const questionMaterial = new THREE.MeshBasicMaterial({ color: rgbaToHex(this.surveyInput.config.questionTextColor) });
        const questionMesh = new THREE.Mesh(questionGeometry, questionMaterial);
        questionMesh.name = "questionMesh"

        this.addKinematicSettingsToObject(questionMesh)
        this.addBackgroundMeshToObject(questionMesh, rgbaToHex(this.surveyInput.config.questionBackgroundColor), this.surveyInput.config.questionBackgroundOpacity)

        return questionMesh;
    }

    async createSurveyAnswers(answers: SurveyAnswer[], fontSize: number = 0.2, height: number = 0.05) {
        const answerObjects = [];

        for (let i = 0; i < answers.length; i++) {
            const answer = answers[i];
            const textGeometry = await this.createTextGeometry(answer.answer, fontSize, height);

            const material = new THREE.MeshBasicMaterial({ color: rgbaToHex(this.surveyInput.config.answerTextColor) });
            const answerMesh = new THREE.Mesh(textGeometry, material);
            answerMesh.name = "answerMesh"
            answerMesh.userData.answerIdx = i
            this.addKinematicSettingsToObject(answerMesh)
            this.addBackgroundMeshToObject(answerMesh,
                rgbaToHex(this.surveyInput.config.surveyAnswerBackgroundColor),
                this.surveyInput.config.surveyAnswerBackgroundOpacity)

            answerObjects.push(answerMesh);
        }

        return answerObjects;
    }

    async createReviewAnswerNode(numRatings: number) {
        const ratingsXSpan = numRatings * this.surveyInput.config.reviewColDelta
        const backgroundGeometry = this.createBackgroundPlaneWithRoundedCorners(ratingsXSpan, this.surveyInput.config.reviewRatingBackgroundHeight)
        const backgroundMaterial = new THREE.MeshBasicMaterial({ color: rgbaToHex(this.surveyInput.config.reviewAnswerBackgroundColor), side: THREE.DoubleSide }); // Set the background color
        const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
        backgroundMesh.name = "reviewMesh"
        this.addKinematicSettingsToObject(backgroundMesh)

        for (let i = 0; i < numRatings; i++) {
            const reviewObject = this.getReviewObject()
            reviewObject.position.set(i * this.surveyInput.config.reviewColDelta - ratingsXSpan*0.5 + this.surveyInput.config.reviewColDelta*0.5, 0, 0)
            backgroundMesh.add(reviewObject)
        }

        return backgroundMesh
    }

    getReviewObject() {
        if (this.surveyInput.config.ratingObject) {
            const object = this.surveyInput.config.ratingObject.clone(true)
            // this.scaleARObject(object, this.surveyInput.config.ratingObjectScale)
            this.turnOffReviewNode(object)

            return object
        }
        return null
    }

    scaleARObject(object: THREE.Object3D, scale: number) {
        const currentHeight = object.scale.y; // Current height (consider the object's initial scale)
        const scalingFactor = scale / currentHeight;
        object.scale.set(object.scale.x * scalingFactor, object.scale.y * scalingFactor, object.scale.z * scalingFactor);
    }

    addKinematicSettingsToObject(object: THREE.Object3D) {
        const settings = new ARObjectSettings()
        settings.addPhysicsBody = true
        settings.physicsShapeType = 2
        object.userData.settings = settings
    }

    addBackgroundMeshToObject(object: THREE.Mesh, color: string, opacity: number, backgroundWidth: number | null = null, backgroundHeight: number | null = null) {
        const backgroundMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide }); // Set the background color
        backgroundMaterial.opacity = opacity
        backgroundMaterial.transparent = true
        const backgroundGeometry = this.createBackgroundPlaneWithRoundedCorners(
            backgroundWidth ?? object.geometry.boundingBox.max.x - object.geometry.boundingBox.min.x,
            backgroundHeight ?? object.geometry.boundingBox.max.y - object.geometry.boundingBox.min.y)
        const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
        backgroundMesh.position.set(0, 0, -0.02); // Slightly behind the text
        backgroundMesh.name = "backgroundMesh"

        object.add(backgroundMesh);
    }

    getBackgroundMeshFromObject(object: THREE.Object3D) {
        for (const child of object.children) {
            if (child.name === "backgroundMesh") {
                return child; // Found a child with the target name
            }
        }
        return null; // No child with the target name was found
    }

    placeSurveyQuestionAndAnswers(questionObject: THREE.Mesh, answerNodes: THREE.Mesh[], questionYOffset: number = 0.2, rowDelta: number = -0.15) {
        const transform = this.ARManager.getTransformInfronOfUser(new THREE.Vector3(0, 0, -1.0))

        questionObject.position.setY(questionYOffset + (questionObject.geometry.boundingBox.max.y - questionObject.geometry.boundingBox.min.y))
        questionObject.applyMatrix4(transform)
        this.addARObjectToScene(questionObject)
        
        for (let i = 0; i < answerNodes.length; i++) {
            const mesh = answerNodes[i].clone(true);
            mesh.position.setY(rowDelta * i);
            mesh.applyMatrix4(transform)
            this.addARObjectToScene(mesh)

            // Light up node if needed
            if (this.answersPerSurvey[this.questionIndices[this.currQuestionIdx]].includes(i)) {
                this.lightupAnswer(mesh)
            }
        }
    }

    placeReviewQuestionAndRatings(questionObject: THREE.Mesh, ratings: THREE.Mesh, questionYOffset: number = 0.2) {
        const transform = this.ARManager.getTransformInfronOfUser(new THREE.Vector3(0, 0, -1.0))

        questionObject.position.setY(questionYOffset + (questionObject.geometry.boundingBox.max.y - questionObject.geometry.boundingBox.min.y))
        questionObject.applyMatrix4(transform)
        this.addARObjectToScene(questionObject)

        ratings.applyMatrix4(transform)
        this.addARObjectToScene(ratings)
        this.turnOnRelevantReviewNodes(ratings)
    }
}