import { ModalButtonsContainer, ModalContentBody, ModalTitle, ModalWrapper } from "./styled";
import { Button } from "antd";

export const CloseButtonModal: React.FC<{ closeModal: () => void, handleCloseExperience: () => void }> = ({ closeModal, handleCloseExperience }) => {
    return (
        <ModalWrapper>
            <ModalContentBody>
                <ModalTitle>Are you sure you want to exit experience ?</ModalTitle>
                <ModalButtonsContainer>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button onClick={handleCloseExperience}>Ok</Button>
                </ModalButtonsContainer>
            </ModalContentBody>
        </ModalWrapper>
    )
}