import { Trigger } from "./Triggers";

// Define the interface for ScheduleManagerProtocol
interface ScheduleManagerProtocol {
    createNewRandDeltaTimer(onRandDeltaTrigger: Trigger, maxExecutions: number): void;
    invalidateTimer(timerID: string): void;
    invalidateAllTimers(): void;
    cleanup(): void;
}

// Define the interface for ScheduleManagerDelegate
export interface ScheduleManagerDelegate {
    randDeltaTimerTick(trigger: Trigger): void;
}

// Define the TimerProperties interface
interface TimerProperties {
    minDelta: number;
    maxDelta: number;
    currentExecutions: number;
    maxExecutions: number;
    isCancelled: boolean;
}

export class ScheduleManager implements ScheduleManagerProtocol {
    private delegate?: ScheduleManagerDelegate;
    private timers: Map<string, TimerProperties> = new Map();

    constructor(delegate: ScheduleManagerDelegate) {
        this.delegate = delegate;
    }

    // Public function to be called by TriggerManager
    createNewRandDeltaTimer(onRandDeltaTrigger: Trigger, maxExecutions: number) {
        const { timerID, minDelta, maxDelta } = onRandDeltaTrigger.params;

        if (this.timers.has(timerID)) {
            return; // Timer already exists, do nothing
        }

        this.invalidateTimer(timerID); // Invalidate any existing timer with the same ID

        // Store timer properties with maxExecutions and currentExecutions
        const properties: TimerProperties = {
            minDelta,
            maxDelta,
            currentExecutions: 0,
            maxExecutions,
            isCancelled: false,
        };

        this.timers.set(timerID, properties);

        this.scheduleNextTick(onRandDeltaTrigger); // Start the first timer
    }

    // Private/internal function to schedule the next tick
    private scheduleNextTick(onRandDeltaTrigger: Trigger) {
        const { timerID } = onRandDeltaTrigger.params;
        const properties = this.timers.get(timerID);

        if (!properties || properties.isCancelled || properties.currentExecutions >= properties.maxExecutions) {
            this.invalidateTimer(timerID);
            return; // Stop scheduling if the timer is cancelled or max executions reached
        }
        properties.currentExecutions += 1;

        const interval = Math.random() * (properties.maxDelta - properties.minDelta) + properties.minDelta;

        const timer = setTimeout(() => {
            if (this.timers.has(timerID) && !this.timers.get(timerID).isCancelled) {
                this.delegate?.randDeltaTimerTick(onRandDeltaTrigger); // Notify the delegate
                this.scheduleNextTick(onRandDeltaTrigger); // Schedule the next tick
            }
        }, interval * 1000);
    }

    invalidateTimer(timerID: string) {
        const properties = this.timers.get(timerID);
        if (properties) {
            properties.isCancelled = true;
            // this.timers.delete(timerID);
        }
    }

    invalidateAllTimers() {
        this.timers.forEach((properties, timerID) => {
            this.invalidateTimer(timerID)
        });
    }

    cleanup() {
        this.invalidateAllTimers()
    }
}
