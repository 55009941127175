export enum ActionType {
    bringToLife = 'bringToLife',
    bringToLifeViaFS = 'bringToLifeViaFS',
    kill = 'kill',
    move = 'move',
    moveRandom = 'moveRandom',
    moveRandomDirection = 'moveRandomDirection',
    rotate = 'rotate',
    scale = 'scale',
    animate = 'animate',
    shoot = 'shoot',
    playSound = 'playSound',
    updateCounter = 'updateCounter',
    updateTimer = 'updateTimer',
    showUIElement = "showUIElement",
    hideUIElement = "hideUIElement",
    experienceComplete = 'experienceComplete',
    experienceFailed = 'experienceFailed',
    customAction = 'customAction',
    pausePublishing = 'pausePublishing',
    resumePublishing = 'resumePublishing',
    vibrate = 'vibrate',
    replaceObject = 'replaceObject',
    unknown = 'unknown',
};

const ActionDescription: Record<ActionType, string> = {
    bringToLife: 'bringToLife',
    bringToLifeViaFS: 'bringToLifeViaFS',
    kill: 'kill',
    move: 'move',
    moveRandom: 'moveRandom',
    moveRandomDirection: 'moveRandomDirection',
    rotate: 'rotate',
    scale: 'scale',
    animate: 'animate',
    shoot: 'shoot',
    playSound: 'playSound',
    updateCounter: 'updateCounter',
    updateTimer: 'updateTimer',
    showUIElement: "showUIElement",
    hideUIElement: "hideUIElement",
    experienceComplete: 'experienceComplete',
    experienceFailed: 'experienceFailed',
    customAction: 'customAction',
    pausePublishing: 'pausePublishing',
    resumePublishing: 'resumePublishing',
    vibrate: 'vibrate',
    replaceObject: 'replaceObject',
    unknown: 'unknown',
};

// const ActionParameterList = {
//     bringToLife: { "loc": "string" },
//     bringToLifeViaFS: {},
//     kill: { "delay": "number" },
//     move: { "x": "number", "y": "number", "z": "number" },
//     rotate: { "angleY": "number" },
//     scale: { "scale": "number" },
//     // animate: { },
//     shoot: {},
//     playSound: { "soundtrackName": "string", "soundtrackUrl": "string" },
//     updateCounter: { "counterID": "string", "delta": "number" },
//     updateTimer: { "timerID": "string", "delta": "number" },
//     experienceComplete: {},
//     experienceFailed: {},
//     unknown: {},
// };

export const ActionParameterNamesDict: Record<string, Record<string, string>> = {
    bringToLife: { loc: "loc" },
    bringToLifeViaFS: {},
    kill: { delay: "delay" },
    move: { x: "x", y: "y", z: "z" },
    moveRandom: { duration: "duration", durationPerMove: "durationPerMove", speedFactor: "speedFactor"},
    moveRandomDirection: { duration: "duration" },
    rotate: { angleY: "angleY" },
    scale: { scale: "scale" },
    animate: { keyPath: "keyPath", duration: "duration", repeatCount: "repeatCount", fromValue: "fromValue", toValue: "toValue"},
    shoot: {},
    playSound: { soundtrackName: "soundtrackName", soundtrackUrl: "soundtrackUrl" },
    updateCounter: { counterID: "counterID", delta: "delta" },
    updateTimer: { timerID: "timerID", delta: "delta" },
    showUIElement: { elementID: "elementID" },
    hideUIElement: { elementID: "elementID" },
    experienceComplete: {},
    experienceFailed: {},
    customAction: { params: "params" },
    pausePublishing: {},
    resumePublishing: {},
    vibrate: {},
    replaceObject: { replacementObjectId: "replacementObjectId" },
    unknown: {},
};

const ActionDictKeys = { actionType: "action" }
export class Action {
    actionType: ActionType
    description: string
    params: Record<string, any>

    constructor(actionDataDict: any) {
        const actionType = actionDataDict[ActionDictKeys.actionType]

        if (!(Object.values(ActionType).includes(actionType))) {
            throw new Error(`Bad action type ${actionType}!`);
        }

        this.actionType = actionType
        this.description = ActionDescription[actionType];
        this.params = {}

        for (const [paramNameKey, paramName] of Object.entries(ActionParameterNamesDict[actionType])) {
            if (paramName in actionDataDict) {
                this.params[paramName] = actionDataDict[paramName]
            } else {
                throw new Error(`Parameter ${paramName} is missing for action ${actionType}`);
            }
        }
    }

    isBringToLifeAction() {
        if ([ActionType.bringToLife, ActionType.bringToLifeViaFS].includes(this.actionType)) {
            return true
        }
        return false
    }
}