import React, { useContext, useEffect, useState } from 'react';
import { IPostExperiencePage } from "../interfaces"
import { LeaderboardPage, LeaderboardEntry } from "../leaderboard-page/LeaderboardPage"
import { IRegisterEndUser, ServerManager } from '../../../Managers/ServerManager';
import { Button, Drawer } from 'antd'
import { RegistrationPage, IRegistrationResponse } from '../registration-page/RegistrationPage';
import {
    StyledButton,
    Divider,
    MainWrapper,
    InnerContainer,
    LeaderboardWrapper,
    RegisterButtonContainer,
    Paragraph,
    ButtonsContainer
} from './styled';
import { CloseOutlined } from '@ant-design/icons';
import { AppStore } from '../../../app-store/store';
import { Mixpanel } from '../../../Managers/AnalyticsClient/AnalyticsClient';
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from '../../../Managers/AnalyticsClient/AnalyticsKeys';

export enum PostGameSelection {
    playAgain = "playAgain",
    exit = "exit"
}

// includeLeaderboard == true and includeRegistration == false -> just get and present leaderboard
// includeLeaderboard == true and includeRegistration == true -> gets leaderboard, but it is presented only after the user registers (or registration data is found in local storage)
export const PostExperiencePage: React.FC<IPostExperiencePage> = ({ metadata, includeLeaderboard, includeRegistration, experienceEndResponse, onPageComplete }) => {

    const [leaderboardInput, setLeaderboardInput] = useState<LeaderboardEntry[]>(null)
    const [showRegistrationDrawer, setShowRegistrationDrawer] = useState<boolean>(false);
    const [registrationResponse, setRegistrationResponse] = useState<IRegistrationResponse | null>(JSON.parse(localStorage.getItem(`user-registration`)));
    const [showRegistrationButton, setShowRegistrationButton] = useState<boolean>(false)
    const { experienceScore, userSession } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.PostExperienceScreen, {
            [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType
        })
        if (registrationResponse === null) {
            setShowRegistrationButton(true)
        } 
        // else {
        //     registedEndUserToCurrentExperience()
        // }
    }, [])

    useEffect(() => {
        if (registrationResponse !== null) {
            handleRegistrationComplete()
        }
    }, [registrationResponse])

    const registedEndUserToCurrentExperience = async () => {
        ServerManager.registerEndUser(registrationResponse, userSession, metadata.experienceID)
    }

    const handleRegistrationComplete = async () => {
        localStorage.setItem(`user-registration`, JSON.stringify(registrationResponse))
        registedEndUserToCurrentExperience()
        await writeScoreToLeaderboardTable()
        setShowRegistrationDrawer(false)
        setShowRegistrationButton(false)
    }

    const writeScoreToLeaderboardTable = async (): Promise<void> => {
        // const userSession = localStorage.getItem('user-session') ?? "NoUserID"
        const scoreDict: LeaderboardEntry = { userID: userSession, nickname: registrationResponse.nickname, score: experienceScore, fullName: registrationResponse.fullName, phoneNumber: registrationResponse.phoneNumber, email: registrationResponse.email }
        const leaderboardData = await ServerManager.writeLeaderboardScore(metadata.experienceID, scoreDict)
        setLeaderboardInput(leaderboardData)
        return
    }

    const exitButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        onPageComplete(PostGameSelection.exit)
    }

    const tryAgaintButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Play Again"
        })
        onPageComplete(PostGameSelection.playAgain)
    }

    return (
        <MainWrapper>
            <Button
                onClick={() => exitButtonTapped()}
                style={{
                    position: 'absolute',
                    top: "20px",
                    zIndex: 9999,
                    left: "20px",
                    color: 'black',
                    backgroundColor: "#C0C0C0",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} shape="circle" icon={<CloseOutlined />} />
            <Drawer
                title="Register"
                placement={'bottom'}
                closable={false}
                onClose={() => setShowRegistrationDrawer(false)}
                open={showRegistrationDrawer}
                size='large'
                height={'500px'}
            >
                <RegistrationPage params={{skipEnabled: false}} onPageComplete={(response: IRegistrationResponse, selectedImage?: File) => setRegistrationResponse(response)} />
            </Drawer>
            <InnerContainer>
                <LeaderboardWrapper>
                    {
                        includeRegistration && showRegistrationButton &&
                        <RegisterButtonContainer>
                            <StyledButton onClick={() => setShowRegistrationDrawer(true)}>
                                Register
                            </StyledButton>
                            <Paragraph>*Register in order to see the leaderboard!</Paragraph>
                        </RegisterButtonContainer>
                    }
                    <div style={{ filter: `${registrationResponse === null ? 'blur(10px)' : ''}` }}>
                        <LeaderboardPage leaderboardEntries={leaderboardInput || []} />
                    </div>
                </LeaderboardWrapper>
                <Divider />
                <ButtonsContainer>
                    <StyledButton onClick={() => tryAgaintButtonTapped()}>
                        Try again
                    </StyledButton>
                </ButtonsContainer>
            </InnerContainer>
        </MainWrapper>
    );
}