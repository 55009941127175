import { useContext } from "react"
import { AppStore } from "../../app-store/store"
import { ExperienceStoreProvider } from "../../app-store/experienceStore"
import { ExperienceRouter } from "./ExperienceRouter"
import { ErrorPage } from "../pages"
import { Alert, Space } from "antd"

export const AppRouter = () => {
    const { startExperience, showErrorPage, appComponentToRender, showAlert, setShowAlert } = useContext(AppStore)

    if (showErrorPage) {
        return (
            <ErrorPage />
        )
    }

    return (
        <>
            {
                showAlert &&
                <Space style={{ position: 'absolute', top: 20, transform: 'translate(-50%)' }}>
                    <Alert onClose={() => setShowAlert(false)} message="Link copied!" style={{}} closable type="success" showIcon />
                </Space>
            }

            {
                startExperience ?
                    <ExperienceStoreProvider>
                        < ExperienceRouter />
                    </ExperienceStoreProvider > : appComponentToRender
            }
        </>
    )

}