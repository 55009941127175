export enum AnalyticsKeys {
    AppLaunch = "App launch",
    ButtonTapped = "Button Tapped",
    
    UserIDAssigned = "User Assigned an ID",
    QRCodeIDExtracted = "QR Code Extracted",
    JSONDownloaded = "JSON downloaded",
    MetadataExtraction = "JSON Metadata Extracted",
    ThemeExtraction = "JSON Theme Extracted",
    RoutingConfigExtraction = "JSON Routing Config Extracted",
    TemplateInitialized = "AR Template Initialized",
    ARStabilized = "AR Session Stabilized",
    ExperienceDone = "Experience Done",    
    PageLaunch = "Page Launch",
    PageComplete = "Page Complete",
    PageInterrupted = "Page Interrupted",

    // Pages mounted
    DefaultParametersAssigned = "Default Parameters Assigned",
    WelcomeScreen = "Welcome Screen",
    HomeScreen = "Home Screen",
    GotAllExperiences = "Got all experiences",
    ExperienceTapped = "Experience Tapped",
    IntroScreen = "Intro Screen",
    BrowserCompatibility = "Browser Compatibility",
    ExperienceScreen = "Game Screen",
    PostExperienceScreen = "Post Game Screen",
    RegistrationScreen = "Registration Screen",
    LeaderboardScreen = "Leaderboard Screen",
    ErrorScreen = "Error Screen",
    SummaryScreen = "Summary Screen"
}

export enum AnalyticsEventPropertyKeys {
    buttonID = "Button ID",
    alertTitle = "Alert Title",
    alertMessage = "Alert Message",

    invocationID = "Invocation ID",
    invocationType = "Invocation Type",
    invocationSource = "Invocation Source",
    invocationQrNum = "Invocation QR Code Number",
    userID = "UserID",
    customerID = "Customer ID",
    isNewUserID = "New user",
    QRCodeID = "QR Code Experience ID",
    IDType = "URL ID Type",
    LaunchURL = "Launch URL",
    numberOFJsonFiles = "Number of JSON Files",
    JsonType = "Type of JSON request",
    ExperienceID = "Experience ID",
    ExperienceType = "Experience Type",
    ExperienceDoneStatus = "Experience Done Status",
    PageName = "Page Name",
    ErrorMessage = "Error Message",

    PolicyAccepted = "Policy Accepted",
    BrowserName = "Browser Name",
    XRCompatibility = "XR Compatible",

    GameScore = "Game Score",
    NumberOfCustomerExperiences = "Number of customer experiences"
}