import { ILoadingPage } from "../interfaces"
import Lottie from 'lottie-react';
import { useEffect, useState } from "react";

// import animationData from '../../../assets/Lottie/failure.json';

export const LoadingPage: React.FC<ILoadingPage> = ({ lottieFileName }) => {
    const [lottieFileData, setLottieFileData] = useState<any | null>(null);

    useEffect(() => {
        const fetchLottieData = async () => {
            try {
                const response = await fetch(`/assets/Lottie/${lottieFileName}.json`);
                if (!response.ok) {
                    throw new Error('Loading page - Failed to fetch data');
                }
                const data = await response.json();
                setLottieFileData(data);
            } catch (error) {
                console.error('Loading page - Error fetching data:', error);
            }
        };

        fetchLottieData();
    }, [lottieFileName]);

    return (
        lottieFileData && <div>
            <Lottie animationData={lottieFileData} />
        </div>
    )
}