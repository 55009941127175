import { Button } from "antd";
import { IWelcomePage } from "../interfaces";
import { CustomizedButton, MainWrapper, Note, VideoElement, Image } from "./styled";
import { CloseOutlined } from "@ant-design/icons";
import { useContext, useEffect } from "react";
import { ExperienceStore } from "../../../app-store/experienceStore";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";

export interface WelcomeScreenInput {
    imageUrl: string;
    title: string;
    description: string;
    notes: string[];
    videoUrl?: string
}

export const WelcomeScreen: React.FC<IWelcomePage> = ({ metadata, theme, input, onPageComplete }) => {
    const { imageUrl, description, notes, videoUrl, title } = input;
    const { handleExitExperience } = useContext(ExperienceStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.WelcomeScreen, {})
    }, []);

    const continueButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Continue"
        })
        onPageComplete()
    }

    const closeButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        handleExitExperience()
    }

    return (
        <MainWrapper>
            <Button
                onClick={closeButtonTapped}
                style={{
                    position: 'absolute',
                    top: "20px",
                    zIndex: 99,
                    left: "20px",
                    color: 'black',
                    backgroundColor: "#C0C0C0",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} shape="circle" icon={<CloseOutlined />} />
            <Image src={imageUrl} alt="Welcome" />
            <h1>{title}</h1>
            {
                videoUrl &&
                <VideoElement controls>
                    <source src={videoUrl} type="video/mp4"></source>
                </VideoElement>
            }
            <p>{description}</p>
            <CustomizedButton primaryColor={theme.primaryColor} secondaryColor={theme.secondaryColor} onClick={continueButtonTapped}>
                Continue
            </CustomizedButton>
            <div>
                {notes.map((note, index) => (
                    <div key={index}>
                        <Note>{note}</Note>
                    </div>
                ))}
            </div>
        </MainWrapper>
    );
}