import { useContext, useEffect } from "react"
import { AppStore } from "../../app-store/store"
import { ExperienceStore } from "../../app-store/experienceStore"

export const ExperienceRouter = () => {
    const { startExperience } = useContext(AppStore)
    const { handleExperienceStarted, componentToRender } = useContext(ExperienceStore)

    useEffect(() => {
        handleExperienceStarted()
    }, [startExperience])

    return (
        <>
            {componentToRender}
        </>
    )
}