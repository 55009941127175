import {
  ARObjectLoader,
  DelayedARObject,
  NetworkARObjectDict,
} from "../../ExperienceObjects/ExperienceObject";
import { ITemplatesBasics } from "../TemplatesInterface";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import {
  BaselBurgerShootersConfig,
  BaselBurgerShootersNetworkConfig,
  ShootersItem,
  ShootersListViewTheme,
} from "./BaselBurgerShootersInput";
import { BaselBurgerListItem } from "./basel-burger-shooters-list-component/BaselBurgerShootersListView";
import { ComponentOptions } from "../MenuTemplate/menu-list-component/MenuListView";

interface IBaselBurgerShootersTemplate extends ITemplatesBasics { }

export class BaselBurgerShootersTemplate
  extends PlatformManager
  implements IBaselBurgerShootersTemplate {
  config: BaselBurgerShootersConfig;

  nonTargetNames: string[] = ["bulletObject"];
  listItems: BaselBurgerListItem[] = [];
  selectedIndex: number | null = null;

  itemSelectionListComponentHandler: (
    option: ComponentOptions,
    list: BaselBurgerListItem[],
    theme: ShootersListViewTheme,
    callback?: (id: string | number, index: number) => void
  ) => void;

  ///////////// Parse input json //////////////////
  static async parseInput(
    json: Record<string, any>
  ): Promise<BaselBurgerShootersConfig> {
    const shootersNetworkConfig = new BaselBurgerShootersNetworkConfig(json);
    // console.log("shootersNetworkConfig(json)", json, shootersNetworkConfig);

    const shootersItems: ShootersItem[] = [];

    for (const networkItem of shootersNetworkConfig.shootersItems) {
      const delayedObject = new DelayedARObject(networkItem.objectImage);
      const item = new ShootersItem(delayedObject, networkItem);
      shootersItems.push(item);
    }

    const config = new BaselBurgerShootersConfig(
      shootersItems,
      shootersNetworkConfig
    );
    return config;
  }

  getCustomExperienceAnalytics(): [Record<string, any>, string] | null {
    const key: string = "experienceAnalytics";
    const analyticsDict: Record<string, any> = {};

    if (this.selectedIndex !== null) {
      let selectedItemName = this.listItems[this.selectedIndex].name;
      analyticsDict["selectedItemName"] = selectedItemName;
    }

    return [analyticsDict, key];
  }

  startExperience(): void {
    // super.startExperience()
    this.prepareItemsList();
    this.displaySelectionScreen();
  }

  prepareItemsList() {
    if (this.config === null) {
      return;
    }

    for (const item of this.config.shootersItems) {
      this.listItems.push(
        new BaselBurgerListItem(item.objectName, item.objectImage.fileThumbnail ?? item.objectImage.fileUrl)
      );
    }

    this.itemSelectionListComponentHandler(
      ComponentOptions.add,
      this.listItems,
      this.config.shootersListViewTheme,
      this.doneButtonClicked.bind(this)
    );
  }

  displaySelectionScreen() {
    // this.doneButtonClicked(1)
    this.itemSelectionListComponentHandler(
      ComponentOptions.present,
      [],
      this.config.shootersListViewTheme
    );
  }

  hideSelectionScreen() {
    this.itemSelectionListComponentHandler(
      ComponentOptions.hide,
      [],
      this.config.shootersListViewTheme
    );
  }

  async doneButtonClicked(selectedID: string, selectedIndex: number) {
    this.selectedIndex = selectedIndex;
    this.hideSelectionScreen();
    await this.loadRelevantItemAndLaunch(selectedIndex);
  }

  async loadRelevantItemAndLaunch(index: number) {
    if (this.fullExperience === null) {
      return;
    }

    const item = this.config.shootersItems[index];
    const selectedObjectExpIdx = this.fullExperience.experiences.findIndex(
      (experienceObject) => {
        if (experienceObject.id == "SelectedObject") {
          return true;
        } else {
          return false;
        }
      }
    );

    item.objectImage.settings = this.fullExperience.experiences[selectedObjectExpIdx].object.userData.settings
    const objectDict: NetworkARObjectDict = item.objectImage;
    const objectLoader = new ARObjectLoader(objectDict);
    if (objectLoader) {
      this.fullExperience.experiences[selectedObjectExpIdx].object = await objectLoader.loadARObject();
      super.startExperience();
    }
  }
}
