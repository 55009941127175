import styled from "styled-components";

export const HomePageContainer = styled.div`
  height: 90vh;
  width: 90vw;
`;

export const ExperienceList = styled.div`
  overflow-y: auto;
  max-height: 70vh;
`;

export const Divider = styled.div`
  width: 70%;
  border-bottom: 1px solid #ccc;
  margin: auto;
  margin-bottom: 20px;
`;

export const LogoContainer = styled.div<{ image: string }>`
  width: 150px;
  height: 150px;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: auto;
`;
