import mixpanel from 'mixpanel-browser';
import { AnalyticsKeys, AnalyticsEventPropertyKeys } from './AnalyticsKeys';
import { ENV } from '../../Configs/ServerConfig';
let did_init = false

// let ENV = "dev";
let env_token = "0bf11a38d149aefbc89894b376a4f41f"
if (ENV === "prod") {
    env_token = "b57ceb26cd27b010b44a6c932531fcda"
}
// if (
//     import.meta.env.MODE === "production" &&
//     window.location.hostname.indexOf("dev.") === -1
// ) {
//     ENV = "prod";
//     env_token = "b57ceb26cd27b010b44a6c932531fcda"
// }

let actions = {
    init: () => {
        console.log(`Initializing mixpanel - Env: ${ENV}`)
        mixpanel.init(env_token);
        did_init = true
    },
    identify: (id: string) => {
        if (did_init) mixpanel.identify(id);
    },
    alias: (id: string) => {
        if (did_init) mixpanel.alias(id);
    },
    track: (name: AnalyticsKeys, props?: Record<string, string>) => {
        if (did_init) mixpanel.track(name, props);
    },
    people: {
        set: (props: { [key: string]: any }) => {
            if (did_init) mixpanel.people.set(props);
        }
    },
};

export let Mixpanel = actions;