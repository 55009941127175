import React, { useState } from 'react';
import { Carousel, Tabs, List, Typography, Image, Row, Col, Button } from 'antd';
import { NetworkARObjectDict } from '../../ExperienceObjects/ExperienceObject';
import { LeftOutlined, RightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { DoneButton } from "./styled";
import { PortalCategory, PortalTemplateConfig } from './PortalTemplateInput';

// Define your types for IndoorDesignCategory, IndoorDesignConfig, and SelectedObjectPros
export enum IndoorDesignCategory {
  Furniture = 'Furniture',
  Tiles = 'Tiles',
}

interface SelectedObjectPros {
  name: string;
  description: string;
  image: NetworkARObjectDict;
}

export interface IPortalSelectionMenuProps {
  config: PortalTemplateConfig;
  selectionCallback: (category: PortalCategory, index: number) => void;
  onClose: () => void; // Add a callback for closing the menu
}

const PortalSelectionMenu: React.FC<IPortalSelectionMenuProps> = ({ config, selectionCallback, onClose }) => {
  const [selectedCategory, setSelectedCategory] = useState<PortalCategory>(PortalCategory.Spherical);

  const selectedItems = (category: PortalCategory): SelectedObjectPros[] => {
    switch (category) {
      case PortalCategory.Spherical:
        return config.spherePortals.map(item => ({
          image: item.image,
          description: item.description,
          name: item.name,
        } as SelectedObjectPros));
      case PortalCategory.Rectangular:
        return config.rectanglePortals.map(item => ({
          image: item.sceneImage,
          description: item.description,
          name: item.name,
        } as SelectedObjectPros));
      default:
        return [];
    }
  };

  const categories = Object.values(PortalCategory);
  const currentIndex = categories.indexOf(selectedCategory);

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: 'white',
        height: '80vh', // Cover only 80% of the viewport height
        position: 'absolute', // Allows it to be positioned relative to the parent
        bottom: 0, // Position at the bottom of the screen
        left: 0,
        right: 0,
        overflowY: 'auto', // Add scroll if the content overflows
      }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Typography.Title level={2} style={{ color: 'black', fontWeight: 'bold', margin: 0 }}>
          Portals Catalogue
        </Typography.Title>
      </Row>

      <Carousel afterChange={current => setSelectedCategory(categories[current])}>
        {categories.map((category, index) => (
          <div key={index}>
            <Row align="middle" justify="space-between" style={{ padding: '10px 0' }}>
              <Col style={{ width: '30px', textAlign: 'center' }}> {/* Fixed width for left icon */}
                {currentIndex > 0 && (
                  <LeftOutlined
                    style={{ fontSize: '30px', opacity: 0.5, cursor: 'pointer' }}
                    onClick={() => setSelectedCategory(categories[currentIndex - 1])}
                  />
                )}
              </Col>
              <Col style={{ flex: 1, textAlign: 'center' }}> {/* Centering title */}
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {category}
                </Typography.Title>
              </Col>
              <Col style={{ width: '30px', textAlign: 'center' }}> {/* Fixed width for right icon */}
                {currentIndex < categories.length - 1 && (
                  <RightOutlined
                    style={{ fontSize: '30px', opacity: 0.5, cursor: 'pointer' }}
                    onClick={() => setSelectedCategory(categories[currentIndex + 1])}
                  />
                )}
              </Col>
            </Row>

            <List
              dataSource={selectedItems(category)}
              renderItem={(item, index) => (
                <List.Item onClick={() => selectionCallback(category, index)} style={{ cursor: 'pointer' }}>
                  <List.Item.Meta
                    avatar={<Image src={item.image.fileUrl} width={50} height={50} style={{ objectFit: 'cover' }} />}
                    title={<Typography.Text strong>{item.name}</Typography.Text>}
                    description={<Typography.Text type="secondary">{item.description}</Typography.Text>}
                  />
                </List.Item>
              )}
            />
          </div>
        ))}
      </Carousel>

      <DoneButton onClick={onClose}>Close</DoneButton>
    </div>
  );
};

export default PortalSelectionMenu;
