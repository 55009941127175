import styled from "styled-components";

export const DoneButton = styled.button`
  margin-top: 20px;
  padding: 8px 16px;
  background-color: aqua;
  color: black;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  font-size: 1.3rem;
  width: 50%;
  cursor: pointer;
`;